import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import LocationList from './LocationList';
import { Container, ListWrapper } from './Styles';

const { Search } = Input;

const LocationSearchInput = (props) => {
  const { onSelect } = props;
  const [searchAddress, setSearchAddress] = useState('');

  const handleChange = (address) => {
    setSearchAddress(address);
  };

  const handleSelect = (address) => {
    setSearchAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        onSelect({
          address,
          lat: latLng.lat,
          lng: latLng.lng,
        });
      })
      .catch((error) => console.error('Error', error));
  };

  const onError = (status, clearSuggestions) => {
    console.log(`Google Maps API returned error with status: ${status}`);
    clearSuggestions();
  };

  return (
    <>
      <PlacesAutocomplete
        debounce={1000}
        value={searchAddress || ''}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={onError}
        searchOptions={{
          componentRestrictions: {
            country: ['th'],
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <Container>
              <Search
                allowClear
                loading={loading}
                placeholder="ค้นหาสถานที่..."
                onChange={handleChange}
                {...getInputProps()}
              />

              <ListWrapper>
                {suggestions.map((suggestion) => (
                  <LocationList
                    {...getSuggestionItemProps(suggestion)}
                    key={uuidv4()}
                    mainText={suggestion.formattedSuggestion.mainText}
                    secondaryText={suggestion.formattedSuggestion.secondaryText}
                    active={suggestion.active}
                  />
                ))}
              </ListWrapper>
            </Container>
          );
        }}
      </PlacesAutocomplete>
    </>
  );
};

export default LocationSearchInput;
