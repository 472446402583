import styled from 'styled-components';
import { Layout } from 'antd';
import { font, color } from 'shared/utils/styles';

const { Sider, Content } = Layout;
export const StyledSider = styled(Sider)`
  overflow: hidden;
  // height: 100vh;
  position: fixed;
  left: 0;
  background-color: ${color.white};
  border-right: 1px solid ${color.border};
  overflow-x: hidden;
`;

export const LogoContainer = styled.div`
  height: 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ${(props) =>
    props.theme === 'red' &&
    `
  background-color: ${color.primary};
  `}

  ${(props) =>
    props.theme === 'dark' &&
    `
  background-color: #000000;
  `}
`;

export const TextLogo = styled.div`
  font-size: 12px;
  color: ${(props) => (props.color === 'white' ? '#ffffff' : '#000000')};
`;

export const VersionContainer = styled.div`
  bottom: 5px;
  ${font.size(12)}
  text-align: center;
  width: 100%;

  ${(props) =>
    !props.collapsed &&
    `
    text-align: right;
    padding-right: 10px;
  `}
`;

export const StyledContent = styled(Content)`
  background-color: #f2f2f2;
  min-height: calc(100vh - 60px);
  padding: 10px;
`;
