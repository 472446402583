import gql from 'graphql-tag';

export interface WarehouseList {
  _id: string;
  pick_up_contact_name: string;
  pick_up_point_name: string;
  pick_up_contact_tel: string;
  stock_site: string;
  last_update: string;
  lat: string;
  lng: string;
  pick_up_address: string;
}

export interface WarehouseListData {
  warehouseList: {
    warehouseList: WarehouseList[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface WarehouseListVars {
  order_by?: string;
  order_type?: '1' | '-1';
  page?: number;
  showData?: number;
  stock_site?: string;
  pick_up_point_name?: string;
  last_update?: string;
}

export default gql`
  query WarehouseList(
    $stock_site: String
    $pick_up_point_name: String
    $order_by: String
    $order_type: String
    $last_update: String
    $showData: Int
    $page: Int
  ) {
    warehouseList(
      params: {
        stock_site: $stock_site
        pick_up_point_name: $pick_up_point_name
        last_update: $last_update
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      warehouseList {
        _id
        pick_up_contact_name
        pick_up_point_name
        pick_up_contact_tel
        stock_site
        last_update
        lat
        lng
        pick_up_address
      }
      totalPage
      totalDocument
    }
  }
`;
