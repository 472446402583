import React from 'react';
import PropTypes from 'prop-types';
import IconLogo from 'shared/assets/images/logo.svg';
import IconLogoWhite from 'shared/assets/images/logo-white.svg';
import IconLogoSmall from 'shared/assets/images/logo-small.svg';
import IconLogoSmallWhite from 'shared/assets/images/logo-small-white.svg';
import StyledLogo from './Styles';

const propTypes = {
  size: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

const defaultProps = {
  size: undefined,
  theme: 'light',
};

const logo = (props) => {
  const { size, theme } = props;
  let icon = theme === 'dark' ? <IconLogoWhite /> : <IconLogo />;
  if (size === 'small') {
    icon = theme === 'dark' ? <IconLogoSmallWhite /> : <IconLogoSmall />;
  }

  return (
    <StyledLogo size={size} {...props}>
      {icon}
    </StyledLogo>
  );
};

logo.propTypes = propTypes;
logo.defaultProps = defaultProps;

export default logo;
