import gql from 'graphql-tag';

export interface OrderDoListPdfList {
  deliveryDate: string[];
  queueType: string[];
  routeCode: string;
  stockSite: string;
  hub: string;
  lastMile: string;
  soNo: string;
  docNo: string;
  arText: string;
  arName: string;
  saleName: string;
}
export interface OrderDoListPdfData {
  orderDoListPdf: {
    orderDoListPdf: OrderDoListPdfList[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface OrderDoListPdfVars {
  params?: {
    deliveryDate?: string;
    queueType?: string;
    routeCode?: string;
    stockSite?: string;
    hub?: string;
    lastMile?: string;
    soNo?: string;
    docNo?: string;
    arText?: string;
    arName?: string;
    saleName?: string;
  };
  order_by?: string;
  order_type?: '1' | '-1';
  page?: number;
  showData?: number;
}

export default gql`
  query orderDoListPdf(
    $params: paramsorderDoPdf
    $page: Int
    $showData: Int
    $order_by: String
    $order_type: String
  ) {
    orderDoListPdf(
      params: $params
      page: $page
      showData: $showData
      sort: { order_by: $order_by, order_type: $order_type }
    ) {
      orderDoListPdf {
        deliveryDate
        queueType
        routeCode
        stockSite
        hub
        lastMile
        soNo
        docNo
        arText
        arName
        saleName
      }
      totalPage
      totalDocument
    }
  }
`;
