import gql from 'graphql-tag';

export interface Truck {
  _id: string;
  license_plate: string;
  province: string;
  active_status: boolean;
}

export interface DriverTrucksData {
  driverTrucks: {
    trucks: Truck[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface DriverTrucksVars {
  params?: {
    _id?: string[];
    truck_type?: string;
    license_plate?: string;
    active_status?: boolean;
    create_date?: string;
    last_update?: string;
  };
  sort?: {
    order_by?: string;
    order_type?: string;
  };
  page?: number;
  showData?: number;
}

export default gql`
  query DriverTrucks($params: paramsDriverTrucks, $sort: sort, $page: Int, $showData: Int) {
    driverTrucks(params: $params, sort: $sort, page: $page, showData: $showData) {
      trucks {
        _id
        license_plate
        province
        active_status
      }
      totalPage
      totalDocument
    }
  }
`;
