/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Row, Col, Form, Input, Alert } from 'antd';
import { Button } from 'shared/components';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Logo from 'shared/assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { storeAuthToken } from 'shared/utils/authToken';
import { StyledLogo, TextHeader, StyledCard, TextVersion } from './Styles';
import LOGIN_MUTATION from 'shared/graphql/mutation/login';

const onFinish = (signIn, username, password) => {
  signIn({
    variables: {
      username,
      password,
    },
  });
};

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [messageError, setMessageError] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const [signIn, { loading }] = useMutation(LOGIN_MUTATION, {
    update: (_cache, { data }) => {
      storeAuthToken(data.login.token);
      window.location.href = '/';
    },
    onError: (error) => {
      let textError =
        error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message;
      if (textError === 'User not found.') {
        textError = 'ไม่พบชื่อผู้ใช้งาน';
      } else if (textError === 'Password is incorrect.') {
        textError = 'ชื่อผู้ใช้หรือรหัสผ่านของคุณไม่ถูกต้อง';
      }

      setMessageError(textError);
    },
  });

  return (
    <Row>
      <Col
        xs={{ span: 24, offset: 0 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 14, offset: 5 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 8, offset: 8 }}
      >
        <StyledCard>
          <StyledLogo>
            <Logo />
          </StyledLogo>
          <TextHeader>{process.env.REACT_APP_NAME}</TextHeader>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={() => {
              setMessageError('');
              onFinish(signIn, username, password);
            }}
          >
            {messageError && (
              <Alert message={messageError} type="error" style={{ marginBottom: 20 }} />
            )}
            <Form.Item name="username">
              <Input
                ref={inputRef}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="ชื่อผู้ใช้งาน"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="รหัสผ่าน"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Item>
            <Row justify="center">
              <Col span={24}>
                <Button
                  type="success"
                  htmlType="submit"
                  block
                  style={{ marginTop: 20 }}
                  loading={loading}
                  disabled={!username || !password}
                >
                  เข้าสู่ระบบ
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: 'center', marginTop: 40 }}>
                <Link to="/forgot-password">ลืมรหัสผ่าน?</Link>
              </Col>
            </Row>
          </Form>
          <TextVersion>v.{process.env.REACT_APP_VERSION}</TextVersion>
        </StyledCard>
      </Col>
    </Row>
  );
};

export default Login;
