import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import HUB_GROUP_QUERY, { HubGroupData } from 'shared/graphql/query/hubGroup';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import _ from 'lodash';

type SelectHubProps = {
  value?: string;
  disabled?: boolean;
  checkData?: (string | undefined)[] | undefined;
  onChange?: (values?: { hubId: string; hub: string }) => void;
};

const { Option } = Select;

const SelectHub: React.FC<SelectHubProps> = (props) => {
  const { value, disabled, onChange, checkData } = props;
  const [hubGroup, setHubGroup] = useState<
    {
      _id: string;
      hub: string;
    }[]
  >([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<HubGroupData>(HUB_GROUP_QUERY, {
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && !error && data?.hubGroup) {
      const sortData = _.sortBy(data.hubGroup, ['hub'], ['asc']);

      setHubGroup([...sortData]);
    }
  }, [data?.hubGroup, loading, error]);

  const filterHub = hubGroup.filter((item) => {
    return !checkData?.includes(item.hub);
  });

  const options: ReactNode[] = filterHub.map((item, index) => (
    <Option key={index} value={item._id}>{`${item.hub}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findHub = hubGroup.find((item) => item._id === valueInput);
    if (onChange && findHub) {
      const { _id, hub } = findHub;
      onChange({
        hubId: _id,
        hub,
      });
    }
  };

  return (
    <>
      <Select
        style={{ width: '100%' }}
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือก Hub"
        onChange={(valueChange) => onSelected(valueChange)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default SelectHub;
