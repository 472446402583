import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Booking from 'views/Booking';
import Job from 'views/Job';
import Report from 'views/Report';
import Setting from 'views/Setting';
import Login from 'views/Auth/Login';
import CreatePassword from 'views/Auth/CreatePassword';
import ForgotPassword from 'views/Auth/ForgotPassword';
import Tracking from 'views/Tracking';
import Agent from 'views/Agent';
import Branch from 'views/Branch';
import Product from 'views/Product';
import Renewal from 'views/Renewal';
import Invoice from 'views/Invoice';
import TruckTracking from 'views/TrackingTransporter';
import { PageError, PageLoader } from 'shared/components';
import { getStoredAuthToken, removeStoredAuthToken } from 'shared/utils/authToken';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { AuthContext } from 'shared/context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getStoredAuthToken();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  const token = getStoredAuthToken();
  return (
    <Route
      {...rest}
      render={(props) => (!token ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

const Routes = () => {
  const { isCheckPermission, isHasPermission } = useContext(AuthContext);
  const { data, networkStatus, typeUser } = useCurrentUser();

  if (networkStatus === 1) {
    return <PageLoader />;
  }

  if (!data) {
    removeStoredAuthToken();
  }

  const routes = [
    {
      path: '/product',
      component: Product,
      typeUser: ['admin'],
      isHasPermission:
        isCheckPermission('orderList', 'CREATE_BOOKING') ||
        isCheckPermission('orderList', 'STOCKSITE') ||
        isCheckPermission('orderList', 'HUB') ||
        isCheckPermission('orderList', 'LASTMILE'),
    },
    {
      path: '/booking',
      component: Booking,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
    {
      path: '/job',
      component: Job,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
    {
      path: '/branch',
      component: Branch,
      typeUser: ['superAdmin'],
    },
    {
      path: '/report',
      component: Report,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
    {
      path: '/agent',
      component: Agent,
      typeUser: ['admin', 'superAdmin'],
    },
    {
      path: '/setting',
      component: Setting,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
    {
      path: '/invoice',
      component: Invoice,
      typeUser: ['admin', 'agent'],
    },
    {
      path: '/renewal',
      component: Renewal,
      typeUser: ['admin', 'superAdmin'],
    },
    {
      path: '/tracking-transporter',
      component: TruckTracking,
      typeUser: ['agent'],
    },
  ].filter(
    (route) =>
      route.typeUser.includes(typeUser) &&
      (route.isHasPermission || route.isHasPermission === undefined),
  );

  const renderRoute = routes.map((route) => (
    <PrivateRoute key={route.path} path={route.path} component={route.component} />
  ));

  const redirectUrl = routes.length > 0 ? routes[0].path : undefined;

  return (
    <>
      <Switch>
        {redirectUrl && <Redirect exact from="/" to={redirectUrl} />}
        {renderRoute}
        <LoginRoute path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={CreatePassword} />
        <Route path="/tracking" component={Tracking} />
        <Route component={PageError} />
      </Switch>
    </>
  );
};

export default Routes;
