import React, { ReactNode } from 'react';
import MapMarkerAlt from 'shared/assets/icon/map-marker-alt.svg';
import Icon from '@ant-design/icons';
import { Container, Pin, DetailContainer } from './Styles';

type DropPointItem = {
  type: 'pickup' | 'delivery' | 'return' | 'pickupAndDelivery';
  borderBottom: boolean;
  children: ReactNode;
};

const DropPointItem: React.FC<DropPointItem> = (props) => {
  const { children, type, borderBottom, ...otherProps } = props;

  return (
    <Container {...otherProps}>
      <Pin type={type}>
        <Icon component={MapMarkerAlt} />
      </Pin>
      <DetailContainer>{children}</DetailContainer>
    </Container>
  );
};

export default DropPointItem;
