import styled from 'styled-components';

export const Container = styled.div`
  height: 400px;
  position: relative;
`;

export const LocationSearchWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 1000;
  width: 100%;
  max-width: 600px;
  padding-left: 60px;
  padding-right: 60px;
  transform: translateX(-50%);
`;

export default Container;
