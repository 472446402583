import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import Button from '../Button';
import MapSearchLocation from '../MapSearchLocation';
import { StyledModal } from './Styles';

type Location = {
  address: string;
  latitude: string;
  longitude: string;
};

type ModalMapSearchLocationProps = {
  title?: string;
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: Location) => void;
  value?: Location;
};

const { Text } = Typography;

const defaultProps = {
  title: 'ระบุที่ตั้ง',
};

const ModalMapSearchLocation: React.FC<ModalMapSearchLocationProps> = (props) => {
  const { title, visible, onCancel, onSubmit, value } = props;
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    if (visible) {
      setLocation(value);
    } else {
      setLocation(value);
    }
  }, [visible]);

  return (
    <StyledModal
      title={title}
      className="custom-modal"
      destroyOnClose
      onCancel={onCancel}
      style={{ top: 20 }}
      visible={visible}
      footer={[
        <Button key="close" onClick={onCancel}>
          ปิด
        </Button>,
        <Button
          key="submit"
          type="success"
          onClick={() => {
            if (onSubmit && location) {
              onSubmit(location);
            }
          }}
          disabled={!(location?.address && location?.latitude && location?.longitude)}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Text strong>ตำแหน่งบนแผนที่:</Text>
      {visible && (
        <MapSearchLocation
          lat={location?.latitude ? +location.latitude : undefined}
          lng={location?.longitude ? +location.longitude : undefined}
          address={location?.address}
          onSelect={(values: { address: string; lat: string; lng: string }) => {
            setLocation({
              address: values.address,
              latitude: values.lat,
              longitude: values.lng,
            });
          }}
        />
      )}
    </StyledModal>
  );
};

ModalMapSearchLocation.defaultProps = defaultProps;

export default ModalMapSearchLocation;
