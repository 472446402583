import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import { Result } from 'antd';
import { Container } from './Styles';

const PageResult = (props) => {
  const { message, style, ...otherProps } = props;
  return (
    <Container style={{ style }}>
      <Result
        icon={<WarningFilled />}
        status="warning"
        title="Error"
        subTitle={message}
        {...otherProps}
      />
    </Container>
  );
};

export default PageResult;
