import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { PageError } from 'shared/components';
import Layout from 'shared/container/Layout';
import LoadableComponent from 'shared/components/LoadableComponent';

const RenewalList = LoadableComponent(() => import('./Routes/RenewalList'));

const Renewal: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  return (
    <Layout textHeader="รายการเก็บงาน">
      <Switch>
        <Route exact path={`${match.path}`} component={RenewalList} />
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Renewal;
