import gql from 'graphql-tag';

export type STypeList = string[];

export interface QueryFiltersTypeData {
  queryFiltersType: {
    sTypeList: STypeList;
  };
}

export default gql`
  query QueryFiltersType {
    queryFiltersType {
      sTypeList
    }
  }
`;
