import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import WAREHOUSE_LIST_QUERY, {
  WarehouseListData,
  WarehouseListVars,
  WarehouseList,
} from 'shared/graphql/query/warehouseList';
import { PlusOutlined } from '@ant-design/icons';
import { Select, message, Divider } from 'antd';
import { Button } from 'shared/components';
import useMessageError from 'shared/hooks/useMessageError';
import ModalCreateWarehouse from 'views/Setting/Routes/Warehouse/Routes/WarehouseDetail/ModalCreateWarehouse';

type SelectStockSiteFieldProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: {
    warehouseId: string;
    pickUpPointName: string;
    pickUpContactName: string;
    pickUpContactTel: string;
    stockSite: string;
    pickUpAddress: string;
    lat: string;
    lng: string;
  }) => void;
  showButtonCreate?: boolean;
};

const { Option } = Select;

const SelectStockSiteField: React.FC<SelectStockSiteFieldProps> = (props) => {
  const { value, disabled, onChange, showButtonCreate } = props;
  const [search, setSearch] = useState<string>('');
  const [isOpenModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [warehouseList, setWarehouseList] = useState<WarehouseList[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<WarehouseListData, WarehouseListVars>(
    WAREHOUSE_LIST_QUERY,
    {
      variables: {
        stock_site: search,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.warehouseList) {
      setWarehouseList([...data.warehouseList.warehouseList]);
    }
  }, [data?.warehouseList, loading, error]);

  const options: ReactNode[] = warehouseList.map((item) => (
    <Option key={item._id} value={item._id}>{`${item.stock_site}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findWarehouse = warehouseList.find((item) => item._id === valueInput);
    if (onChange && findWarehouse) {
      const {
        _id,
        pick_up_contact_name,
        pick_up_contact_tel,
        pick_up_address,
        stock_site,
        lat,
        lng,
        pick_up_point_name,
      } = findWarehouse;

      onChange({
        warehouseId: _id,
        pickUpPointName: pick_up_point_name,
        pickUpContactName: pick_up_contact_name,
        pickUpContactTel: pick_up_contact_tel,
        stockSite: stock_site,
        pickUpAddress: pick_up_address,
        lat: lat,
        lng: lng,
      });
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือก Stock site"
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        dropdownRender={
          !showButtonCreate
            ? undefined
            : (menu) => (
                <div>
                  <Button type="link" onClick={() => setOpenModalCreate(true)}>
                    <PlusOutlined /> เพิ่มข้อมูลคลัง
                  </Button>
                  <Divider style={{ margin: '4px 0' }} />
                  <div>{menu}</div>
                </div>
              )
        }
      >
        {options}
      </Select>

      {isOpenModalCreate && (
        <ModalCreateWarehouse
          visible={isOpenModalCreate}
          onCancel={() => setOpenModalCreate(false)}
        />
      )}
    </>
  );
};

export default SelectStockSiteField;
