/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, memo } from 'react';
import { useLeaflet } from 'react-leaflet';
import Geocode from 'react-geocode';
import googleMapKey from 'shared/config/googleMapKey';
import useDeepCompareEffect from 'use-deep-compare-effect';
import LocationSearchInput from './LocationSearchInput';
import Marker from './Marker';
import { Container, LocationSearchWrapper } from './Styles';
import LeafletMap from '../LeafletMap';
import LeafletControlCurrentLocation from '../LeafletControlCurrentLocation';

Geocode.setApiKey(googleMapKey);

const MapWrapper = (props) => {
  const { onSelect, lat, lng, address, pinMode } = props;
  const [isInitial, setIsInitial] = useState(true);
  const [location, setLocation] = useState();

  useEffect(() => {
    if (lat && lng) {
      setIsInitial(true);
    }
  }, [lat, lng]);

  useEffect(() => {
    if (isInitial) {
      setLocation({
        address,
        lat,
        lng,
      });

      setIsInitial(false);
    }
  }, [address, isInitial, lat, lng]);

  return (
    <>
      <Container>
        <LocationSearchWrapper>
          <LocationSearchInput
            onSelect={(values) => {
              if (onSelect) {
                onSelect({
                  address: values.address,
                  lat: values.lat,
                  lng: values.lng,
                });
              }

              setLocation({ address: values.address, lat: values.lat, lng: values.lng });
            }}
          />
        </LocationSearchWrapper>
        <LeafletMap
          maxZoom={18}
          onClick={(event) => {
            const { latlng } = event;
            Geocode.fromLatLng(latlng.lat, latlng.lng).then(
              (response) => {
                const addressData = response.results[0].formatted_address;
                if (onSelect) {
                  onSelect({
                    address: addressData,
                    lat: latlng.lat,
                    lng: latlng.lng,
                  });
                }

                setLocation({
                  address: addressData,
                  lat: latlng.lat,
                  lng: latlng.lng,
                });
              },
              (error) => {
                console.error(error);
              },
            );
          }}
        >
          <MapSearchLocation
            pinMode={pinMode}
            location={location}
            onDragend={(values) => {
              if (onSelect) {
                onSelect({
                  address: values.address,
                  lat: values.lat,
                  lng: values.lng,
                });
              }

              setLocation({ address: values.address, lat: values.lat, lng: values.lng });
            }}
          />
          <LeafletControlCurrentLocation
            position="bottomright"
            onGetLocation={(location) => {
              Geocode.fromLatLng(location.latitude, location.longitude).then(
                (response) => {
                  const addressData = response.results[0].formatted_address;
                  if (onSelect) {
                    onSelect({
                      address: addressData,
                      lat: location.latitude,
                      lng: location.longitude,
                    });
                  }

                  setLocation({
                    address: addressData,
                    lat: location.latitude,
                    lng: location.longitude,
                  });
                },
                (error) => {
                  console.error(error);
                },
              );
            }}
          />
        </LeafletMap>
      </Container>
    </>
  );
};

const MapSearchLocation = memo((props) => {
  const { onDragend, location, pinMode } = props;
  const { lat, lng, address } = location;
  const { map } = useLeaflet();

  useDeepCompareEffect(() => {
    if (lat && lng) {
      map.fitBounds([[lat, lng]]);
    }
  }, [lat, lng, map]);

  return (
    <>
      {lat && lng && (
        <Marker
          draggable
          position={[lat, lng]}
          type={pinMode || 'delivery'}
          popup={address}
          isOpenPopup
          onDragend={(event) => {
            const latLng = event.target._latlng;
            Geocode.fromLatLng(latLng.lat, latLng.lng).then(
              (response) => {
                const addressData = response.results[0].formatted_address;
                onDragend({
                  address: addressData,
                  lat: +latLng.lat,
                  lng: +latLng.lng,
                });
              },
              (error) => {
                console.error(error);
              },
            );
          }}
        />
      )}
    </>
  );
});

export default memo(MapWrapper);
