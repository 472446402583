/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Row, Col, Avatar } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useBranchSelected from 'shared/hooks/useBranchSelected';
import { AuthContext } from 'shared/context/AuthContext';
import { removeStoredAuthToken } from 'shared/utils/authToken';
import {
  StyledHeader,
  LeftContainer,
  TextHeader,
  StyledTrigger,
  RightContainer,
  StyledButton,
} from './Styles';
import Notification from './Notification';

const propTypes = {
  collapsed: PropTypes.bool,
  toggle: PropTypes.func,
  textHeader: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark', 'red']),
};

const defaultProps = {
  toggle: undefined,
  collapsed: false,
  theme: 'light',
};

const Header = withRouter((props) => {
  const { state, isCheckPermission } = useContext(AuthContext);
  const { collapsed, toggle, textHeader, staticContext, theme, ...except } = props;
  const { removeSelectedBranch } = useBranchSelected();

  const menu = () => {
    if (window.innerWidth < 451) {
      return (
        <Menu>
          <Menu.Item>{state.user.username}</Menu.Item>
          <Menu.Item
            onClick={() => {
              removeSelectedBranch();
              removeStoredAuthToken();
              return props.history.push('/');
            }}
          >
            ออกจากระบบ
          </Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            removeSelectedBranch();
            removeStoredAuthToken();
            return props.history.push('/');
          }}
        >
          ออกจากระบบ
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <StyledHeader theme={theme} {...except}>
      <LeftContainer>
        <StyledTrigger theme={theme} onClick={toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </StyledTrigger>
        <TextHeader>{textHeader}</TextHeader>
      </LeftContainer>
      <RightContainer>
        <Row align="middle" gutter={[24, 0]}>
          {state.user.typeUser === 'admin' && state.user.companyFullName && (
            <Col>
              <strong>{state.user.companyFullName}</strong>
            </Col>
          )}
          {state.user.typeUser === 'agent' && state.user.agentFullName && (
            <Col>
              <strong>{state.user.agentFullName}</strong>
            </Col>
          )}
          {state.user.typeUser === 'agent' ||
          (state.user.typeUser === 'admin' && isCheckPermission('setting', 'POP_UP_NOTI')) ? (
            <Col>
              <Notification
                active={state.user.notificationCount > 0}
                color={theme !== 'light' ? 'white' : undefined}
                count={state.user.notificationCount}
              />
            </Col>
          ) : null}
          <Col>
            <Dropdown trigger={['click']} overlay={menu()}>
              <div>
                <Avatar
                  icon={!state.user.picture ? <UserOutlined /> : null}
                  src={state.user.picture}
                  style={{ marginRight: -5 }}
                />
                <StyledButton type="link" theme={theme}>
                  {state.user.username}
                </StyledButton>
                <DownOutlined />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </RightContainer>
    </StyledHeader>
  );
});

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
