import React, { useState } from 'react';
import { Row, Col, Typography, Form, Input } from 'antd';
import Measure from 'react-measure';
import { ModalMapSearchLocation, Button } from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import MapMarkerAltSolid from 'shared/assets/icon/map-marker-alt-solid.svg';
import Icon from '@ant-design/icons';
import { FormValue } from '../formValue.model';

const { Text } = Typography;

const Forms: React.FC = () => {
  const [isOpenModalMapSearchLocation, setOpenModalMapSearchLocation] = useState<boolean>(false);
  const [dimensionWidth, setDimensionWidth] = useState<number>(-1);
  const { errors, control, watch, setValue, trigger } = useFormContext<FormValue>();
  const watchLocation = watch(['pickUpAddress', 'lat', 'lng']);
  const watchIsReadOnly = watch('isReadOnly');
  const isWidthMd = dimensionWidth < 768;

  return (
    <div>
      <Measure
        bounds
        onResize={(contentRect) => {
          setDimensionWidth(contentRect.bounds?.width || 0);
        }}
      >
        {({ measureRef }) => <div ref={measureRef}></div>}
      </Measure>

      <Controller type="hidden" as={Input} name="warehouseId" />
      <Controller type="hidden" as={Input} name="isEdit" />
      <Controller type="hidden" as={Input} name="lat" />
      <Controller type="hidden" as={Input} name="lng" />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Text strong>
            Stock Site<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.stockSite && 'error'}
            help={errors.stockSite ? errors.stockSite.message : undefined}
          >
            <Controller
              as={Input}
              control={control}
              name="stockSite"
              placeholder="ระบุ"
              readOnly={watchIsReadOnly}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text strong>
            Hub<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.hub && 'error'}
            help={errors.hub ? errors.hub.message : undefined}
          >
            <Controller
              readOnly={watchIsReadOnly}
              as={Input}
              control={control}
              name="hub"
              placeholder="ระบุ"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text strong>
            Last Mile<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.lastMile && 'error'}
            help={errors.lastMile ? errors.lastMile.message : undefined}
          >
            <Controller
              readOnly={watchIsReadOnly}
              as={Input}
              control={control}
              name="lastMile"
              placeholder="ระบุ"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Text strong>
            ชื่อจุดรับสินค้า<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.pickUpPointName && 'error'}
            help={errors.pickUpPointName ? errors.pickUpPointName.message : undefined}
          >
            <Controller
              readOnly={watchIsReadOnly}
              as={Input}
              control={control}
              name="pickUpPointName"
              placeholder="ระบุ"
            />
          </Form.Item>
        </Col>
        <Col span={isWidthMd ? 16 : 8}>
          <Text strong>
            ตำแหน่งบนแผนที่<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.pickUpAddress && 'error'}
            help={errors.pickUpAddress ? errors.pickUpAddress.message : undefined}
          >
            <Controller
              control={control}
              name="pickUpAddress"
              disabled
              render={({ value }) => (
                <Input.Group compact>
                  <Input
                    placeholder="ระบุ"
                    readOnly
                    style={{ width: 'calc(100% - 102px)' }}
                    value={value}
                    onClick={() => !watchIsReadOnly && setOpenModalMapSearchLocation(true)}
                  />
                  <Button
                    disabled={watchIsReadOnly}
                    onClick={() => setOpenModalMapSearchLocation(true)}
                  >
                    <Icon component={MapMarkerAltSolid} /> ระบุที่ตั้ง
                  </Button>
                </Input.Group>
              )}
            />
          </Form.Item>
          <ModalMapSearchLocation
            value={{
              address: watchLocation.pickUpAddress,
              latitude: watchLocation.lat,
              longitude: watchLocation.lng,
            }}
            visible={isOpenModalMapSearchLocation}
            onCancel={() => setOpenModalMapSearchLocation(false)}
            onSubmit={(values) => {
              setValue('pickUpAddress', values.address);
              setValue('lat', values.latitude);
              setValue('lng', values.longitude);
              trigger(['pickUpAddress', 'lat', 'lng']);
              setOpenModalMapSearchLocation(false);
            }}
          />
        </Col>
        {!isWidthMd && <Col span={8} />}
        <Col span={isWidthMd ? 12 : 8}>
          <Text strong>
            ชื่อผู้ติดต่อ<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.pickUpContactName && 'error'}
            help={errors.pickUpContactName ? errors.pickUpContactName.message : undefined}
          >
            <Controller
              readOnly={watchIsReadOnly}
              as={Input}
              control={control}
              name="pickUpContactName"
              placeholder="ระบุ"
            />
          </Form.Item>
        </Col>
        <Col span={isWidthMd ? 12 : 8}>
          <Text strong>
            เบอร์โทรศัพท์<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.pickUpContactTel && 'error'}
            help={errors.pickUpContactTel ? errors.pickUpContactTel.message : undefined}
          >
            <Controller
              as={Input}
              control={control}
              name="pickUpContactTel"
              placeholder="08XXXXXXXX"
              readOnly={watchIsReadOnly}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Forms;
