import gql from 'graphql-tag';

export interface UpdateChangePasswordData {
  updateChangePassword: {
    _id: string;
  };
}

export interface UpdateChangePasswordVars {
  token: string;
  password: string;
}

export default gql`
  mutation UpdateChangePassword($token: String!, $password: String!) {
    updateChangePassword(token: $token, password: $password) {
      _id
    }
  }
`;
