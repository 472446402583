import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CUSTOMER_LIST_QUERY, {
  CustomerListData,
  CustomerListVars,
  CustomerList,
} from 'shared/graphql/query/customerList';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'shared/components';
import { Select, message, Divider } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import ModalCreateCustomer from 'views/Setting/Routes/Customer/Routes/CustomerDetailForm/ModalCreateCustomer';

type SelectCustomerFieldProps = {
  value?: string;
  disabled?: boolean;
  displayText: 'arCode' | 'arName';
  onChange?: (values?: CustomerList) => void;
  showButtonCreate?: boolean;
};

const { Option } = Select;

const SelectCustomerField: React.FC<SelectCustomerFieldProps> = (props) => {
  const { displayText, value, disabled, onChange, showButtonCreate } = props;
  const [search, setSearch] = useState<string>('');
  const [isOpenModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<CustomerList[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<CustomerListData, CustomerListVars>(
    CUSTOMER_LIST_QUERY,
    {
      variables: {
        ar_code: displayText === 'arCode' ? search : undefined,
        ar_name: displayText === 'arName' ? search : undefined,
        showData: 30,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.customerList) {
      setCustomerList([...data.customerList.customerList]);
    }
  }, [data?.customerList, loading, error]);

  const options: ReactNode[] = customerList.map((item) => {
    const textOption = displayText === 'arCode' ? item.ar_code : item.ar_name;
    return <Option key={item._id} value={item._id}>{`${textOption}`}</Option>;
  });

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = customerList.find((item) => item._id === valueInput);
    if (onChange && findValue) {
      onChange(findValue);
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือก"
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        dropdownRender={
          !showButtonCreate
            ? undefined
            : (menu) => (
                <div>
                  <Button type="link" onClick={() => setOpenModalCreate(true)}>
                    <PlusOutlined /> เพิ่มข้อมูลลูกค้า
                  </Button>
                  <Divider style={{ margin: '4px 0' }} />
                  <div>{menu}</div>
                </div>
              )
        }
      >
        {options}
      </Select>

      {isOpenModalCreate && (
        <ModalCreateCustomer
          visible={isOpenModalCreate}
          onCancel={() => setOpenModalCreate(false)}
        />
      )}
    </>
  );
};

export default SelectCustomerField;
