import React from 'react';
import Cookies from 'js-cookie';
import pdpaStyle from './PDPA.module.css';

class PDPA extends React.Component {
  constructor() {
    super();

    this.state = {
      isShow: Cookies.get('pdpa') === undefined,
    };
  }

  onAgree() {
    this.setState({ isShow: false });
    Cookies.set('pdpa', 'agree', { path: '/' });
  }

  render() {
    if (!this.state.isShow) {
      return null;
    }

    return (
      <div className={pdpaStyle.wrapper}>
        <div className={pdpaStyle.content}>
          เราใช้คุกกี้เพื่อเสนอบริการและปรับปรุงอย่างต่อเนื่องตลอดจนแสดงเนื้อหาและการโฆษณาที่ปรับเปลี่ยนในแบบของคุณตามความสนใจส่วนบุคคลของคุณบนโซเชียลมีเดียและเว็บไซต์พันธมิตร
          <span>
            <a href="https://giztix.com/policy" target="_blank" className={pdpaStyle.link}>
              เรียนรู้เพิ่มเติม
            </a>
          </span>
        </div>
        <div>
          <button className={pdpaStyle.button} onClick={() => this.onAgree()}>
            ยอมรับ
          </button>
        </div>
      </div>
    );
  }
}

export default PDPA;
