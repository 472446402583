import React from 'react';
import { Tag } from 'shared/components';
import { OrderStatus } from 'shared/model/orderStatus.model';

type TagStatusProps = {
  status: OrderStatus;
};

const getTextColorStatus = (status: OrderStatus) => {
  switch (status) {
    case 1:
      return { color: '#FB9219', textColor: '#ffffff', text: 'รอจัดเที่ยว', outline: false };
    case 2:
      return { color: '#DFF5E5', textColor: '#068828', text: 'จัดเที่ยวครบแล้ว', outline: false };
    case 3:
      return { color: '#D0E7F9', textColor: '#1487E2', text: 'อยู่ระหว่างจัดส่ง', outline: false };
    case 4:
      return { color: '#068828', textColor: '#ffffff', text: 'ขนส่งสำเร็จ', outline: false };
    case 5:
      return {
        color: '#BFBFBF',
        textColor: '#212429',
        text: 'Site กำลังจัดเที่ยว',
        outline: false,
      };
    case 6:
      return { color: '#D0E7F9', textColor: '#1487E2', text: 'Site กำลังจัดส่ง', outline: false };
    case 7:
      return { color: '#BFBFBF', textColor: '#212429', text: 'Hub กำลังจัดเที่ยว', outline: false };
    case 8:
      return { color: '#D0E7F9', textColor: '#1487E2', text: 'Hub กำลังจัดส่ง', outline: false };
    case 9:
      return { color: '#FFE8E3', textColor: '#CF1322', text: 'สินค้ายกเลิก', outline: false };
    case 10:
      return { color: '#FFE8E3', textColor: '#CF1322', text: 'เปลี่ยนที่จัดส่ง', outline: false };
    case 11:
      return { color: '#068828', textColor: '#ffffff', text: 'ปิดงานเอง', outline: false };
    default:
      return { text: status };
  }
};

const TagStatus: React.FC<TagStatusProps> = (props) => {
  const { status } = props;
  const data = getTextColorStatus(status);

  return (
    <Tag color={data.color} outline={data.outline} textColor={data.textColor}>
      {data.text}
    </Tag>
  );
};

export default TagStatus;
