import React from 'react';
import Icon from '@ant-design/icons';
import MapMarkerAltSolid from 'shared/assets/icon/map-marker-alt-solid.svg';
import { Container, IconContainer, TextWrapper, SecondaryText } from './Styles';

const LocationList = (props) => {
  const { mainText, secondaryText, active, ...otherProps } = props;

  return (
    <Container {...otherProps} active={active}>
      <IconContainer>
        <Icon component={MapMarkerAltSolid} />
      </IconContainer>
      <TextWrapper>
        {mainText} <SecondaryText> {secondaryText}</SecondaryText>
      </TextWrapper>
    </Container>
  );
};

export default LocationList;
