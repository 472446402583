import styled from 'styled-components';
import { Card } from 'antd';
import { font } from 'shared/utils/styles';

export const StyledCard = styled(Card)`
  margin: 100px;

  @media (max-width: 767px) {
    margin: 20px;
  }
`;

export const StyledLogo = styled.div`
  text-align: center;
  max-width: 200px;
  width: 100%;
  margin: 10px auto 20px;
`;

export const TextHeader = styled.h3`
  text-align: center;
  ${font.size(16)}
`;

export const TextVersion = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
`;
