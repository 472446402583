import gql from 'graphql-tag';

export interface Companies {
  _id: string;
  company_name: string;
  branch_code: string;
  branch_name: string;
  last_update: string;
  stock_site: string;
  hub: string;
  last_mile: string;
}

export interface CompaniesData {
  companies: {
    companies: Companies[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface CompaniesVars {
  company_name?: string;
  branch_code?: string;
  branch_name?: string;
  order_by?: string;
  order_type?: string;
  page?: number;
  showData?: number;
  stock_site?: string;
  hub?: string;
  last_mile?: string;
}

export default gql`
  query Companies(
    $company_name: String
    $branch_code: String
    $branch_name: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
    $stock_site: String
    $hub: String
    $last_mile: String
  ) {
    companies(
      params: {
        company_name: $company_name
        branch_code: $branch_code
        branch_name: $branch_name
        stock_site: $stock_site
        hub: $hub
        last_mile: $last_mile
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      companies {
        _id
        company_name
        branch_code
        branch_name
        last_update
        stock_site
        hub
        last_mile
      }
      totalPage
      totalDocument
    }
  }
`;
