import gql from 'graphql-tag';

export interface SettingSubDistrictList {
  _id: string;
  sub_district_name: string;
  postcode: string;
  district_code: string;
  province_code: string;
}

export interface SettingSubDistrictListData {
  settingSubDistrictList: {
    settingSubDistrictList: SettingSubDistrictList[];
  };
}

export interface SettingSubDistrictListVars {
  order_by?: string;
  order_type?: '1' | '-1';
  page?: number;
  showData?: number;
  province_code?: string;
  district_code?: string;
  district_name?: String;
  province_name?: String;
}

export default gql`
  query SettingSubDistrictList(
    $province_code: String
    $district_code: String
    $district_name: String
    $province_name: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    settingSubDistrictList(
      params: {
        district_code: $district_code
        province_code: $province_code
        district_name: $district_name
        province_name: $province_name
      }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      settingSubDistrictList {
        _id
        sub_district_name
        postcode
        district_code
        province_code
      }
    }
  }
`;
