import gql from 'graphql-tag';

export type PermissionOrderList =
  | 'ORDERS'
  | 'CREATE_BOOKING'
  | 'STOCKSITE'
  | 'HUB'
  | 'LASTMILE'
  | 'UPDATE_DELIVERY';

export type PermissionBookingList =
  | 'CONTACT_BOOKING'
  | 'DETAIL_CONTACT'
  | 'CONFIRM_CONTACT'
  | 'BOOKINGS'
  | 'TOOLS_BOOKINGS'
  | 'ASSIGN_VENDOR'
  | 'CHANGE_VENDOR'
  | 'UPDATE_BOOKING'
  | 'CANCEL_BOOKING';

export type PermissionJobList = 'JOBS' | 'WAYBILL' | 'DETAIL_JOB' | 'COSTING';

export type PermissionRenewalList =
  | 'RENEWALS'
  | 'DETAIL_RENEWAL'
  | 'TOOLS_RENEWALS'
  | 'KEEP_WORK'
  | 'CREATE_TRANSFER'
  | 'REJECT_RENEWAL'
  | 'CANCEL_RENEWAL';

export type PermissionVendorList = 'VENDORS' | 'DETAIL_VENDOR';

export type PermissionReportList = 'REPORTS' | 'CREATE_REPORT';

export type PermissionSettingList =
  | 'WAREHOUSE'
  | 'DETAIL_WAREHOUSE'
  | 'CREATE_WAREHOUSE'
  | 'UPDATE_WAREHOUSE'
  | 'CUSTOMER'
  | 'DETAIL_CUSTOMER'
  | 'CREATE_CUSTOMER'
  | 'UPDATE_CUSTOMER'
  | 'COMPANY'
  | 'NOTIFICATIONS'
  | 'DETAIL_NOTI'
  | 'POP_UP_NOTI'
  | 'DETAIL_GAS'
  | 'CREATE_GAS'
  | 'UPDATE_GAS'
  | 'DETAIL_CATEGORY'
  | 'CREATE_CATEGORY';

export interface PermissionAccount {
  orderList: PermissionOrderList[];
  bookingList: PermissionBookingList[];
  jobList: PermissionJobList[];
  renewalList: PermissionRenewalList[];
  vendorList: PermissionVendorList[];
  reportList: PermissionReportList[];
  setting: PermissionSettingList[];
}

export interface CurrentUserData {
  currentUser: {
    _id: string;
    username: string;
    user_account_type: number;
    vendor: {
      _id: string;
      full_name: string;
      truck_type_id: string;
      number: string;
    } | null;
    company: {
      _id: string;
      company_name: string;
      branch_code: string;
      branch_name: string;
    } | null;
    permission: string[];
    picture: string | null;
    create_date: string;
    last_update: string;
    wait_to_read_noti: number;
    permission_account: PermissionAccount;
  };
}

export default gql`
  query CurrentUser {
    currentUser {
      _id
      username
      user_account_type
      vendor {
        _id
        full_name
        truck_type_id
        number
      }
      company {
        _id
        company_name
        branch_code
        branch_name
      }
      permission
      picture
      create_date
      last_update
      wait_to_read_noti
      permission_account {
        orderList
        bookingList
        jobList
        renewalList
        vendorList
        reportList
        setting
      }
    }
  }
`;
