import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import SETTING_PROVINCE_LIST_QUERY, {
  SettingProvinceListData,
  SettingProvinceListVars,
  SettingProvinceList,
} from 'shared/graphql/query/settingProvinceList';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

type SelectSettingProvinceProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: { provinceId: string; provinceCode: string; provinceName: string }) => void;
};

const { Option } = Select;

const SelectSettingProvince: React.FC<SelectSettingProvinceProps> = (props) => {
  const { value, disabled, onChange } = props;
  const [settingProvinceList, setSettingProvinceList] = useState<SettingProvinceList[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<SettingProvinceListData, SettingProvinceListVars>(
    SETTING_PROVINCE_LIST_QUERY,
    {
      variables: {
        showData: 1000,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.settingProvinceList) {
      const sortProvince = _.sortBy(
        data.settingProvinceList.settingProvinceList,
        ['province_name'],
        ['asc'],
      );
      setSettingProvinceList([...sortProvince]);
    }
  }, [data?.settingProvinceList, loading, error]);

  const options: ReactNode[] = settingProvinceList.map((item) => (
    <Option key={uuidv4()} value={item.province_name}>{`${item.province_name}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findProvince = settingProvinceList.find((item) => item.province_name === valueInput);
    if (onChange && findProvince) {
      const { _id, province_code, province_name } = findProvince;
      onChange({
        provinceId: _id,
        provinceCode: province_code,
        provinceName: province_name,
      });
    }
  };

  return (
    <>
      <Select
        style={{ width: '100%' }}
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือกจังหวัด"
        onChange={(valueChange) => onSelected(valueChange)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default SelectSettingProvince;
