import styled from 'styled-components';
import { Table } from 'antd';
import { mixin, font } from 'shared/utils/styles';

interface StyledHightTableProps {
  tableHeight?: number;
}

export const StyledHightTable = styled.div<StyledHightTableProps>`
  & .ant-table-content {
    ${(props) => props.tableHeight && `max-height: calc(100vh - ${props.tableHeight}px);`}
    overflow-y: auto !important;
  }

  & .ant-table-thead {
    position: sticky;
    z-index: 3;
    top: 0;
  }
`;

export const StyledTable = styled(Table)`
  ${mixin.backgroundTableEven}

  & .ant-table-thead > tr > th {
    ${font.black}
    vertical-align: top;
    background-color: white;

    & .table-header {
      &__text {
        padding-bottom: 4px;
      }
    }
  }

  & .ant-table-column-sorters {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;

    & > span:first-child {
      flex: 1;
    }
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
