import React from 'react';
import { message } from 'antd';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import AuthContextProvider from 'shared/context/AuthContext';
import 'simplebar/dist/simplebar.min.css';
import 'filepond/dist/filepond.min.css';
import PDPA from 'shared/components/PDPA';
import Routes from './Routes';
import BaseStyles from './BaseStyles';
import './style.less';

library.add(fas, fal);
message.config({
  duration: 3,
  maxCount: 5,
});

const App = () => (
  <>
    <AuthContextProvider>
      <BaseStyles />
      <Routes />
      <PDPA />
    </AuthContextProvider>
  </>
);

export default App;
