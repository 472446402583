import React, { ReactNode, useState, useEffect } from 'react';
import { Select, message, Empty } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectVendorsProps = {
  value?: string[];
  disabled?: boolean;
  onChange?: (values: string[]) => void;
  placeholder?: string;
};

const { Option } = Select;

const SelectTagsMulti: React.FC<SelectVendorsProps> = (props) => {
  const { value, disabled, onChange, placeholder } = props;
  const options: ReactNode[] = (value?.length ? value : []).map((item) => (
    <Option key={item} value={item}>
      {item}
    </Option>
  ));
  return (
    <>
      <Select
        allowClear
        mode="tags"
        maxTagCount="responsive"
        value={value}
        disabled={disabled}
        placeholder="ค้นหา"
        onChange={(valueChange) => {
          onChange && onChange(valueChange);
        }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%' }}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectTagsMulti;
