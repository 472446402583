import React from 'react';
import loadable from '@loadable/component';
import { PageLoader } from 'shared/components';

const LoadableComponent = (loadFn: () => Promise<any>) => {
  return loadable(loadFn, {
    fallback: <PageLoader />,
  });
};

export default LoadableComponent;
