import React, { useContext } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { PageError } from 'shared/components';
import Layout from 'shared/container/Layout';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { AuthContext } from 'shared/context/AuthContext';
import LoadableComponent from 'shared/components/LoadableComponent';

const AgentList = LoadableComponent(() => import('./Routes/AgentList'));
const AgentCreate = LoadableComponent(() => import('./Routes/AgentDetail2/CreateAgent'));
const AgentDetail = LoadableComponent(() => import('./Routes/AgentDetail'));

const Agent: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const { isCheckPermission } = useContext(AuthContext);
  const { typeUser } = useCurrentUser();

  const routes = [
    {
      path: `${match.path}/detail/:id`,
      component: AgentDetail,
      typeUser: ['admin', 'superAdmin'],
      isHasPermission: isCheckPermission('vendorList', 'DETAIL_VENDOR'),
    },
    {
      path: `${match.path}/create`,
      component: AgentCreate,
      typeUser: ['superAdmin'],
      exact: true,
    },
    {
      path: `${match.path}`,
      component: AgentList,
      typeUser: ['admin', 'superAdmin'],
      exact: true,
    },
  ]
    .filter((item) => item.typeUser.includes(typeUser))
    .filter((item) => item.isHasPermission || item.isHasPermission === undefined)
    .map((route, index) => (
      <Route exact={route.exact} key={index} path={route.path} component={route.component} />
    ));

  return (
    <Layout textHeader="ตั้งค่าบริษัทขนส่ง">
      <Switch>
        {routes}
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Agent;
