import { TrackingTransport } from 'shared/graphql/query/trackingTransport';
import { ActivityType } from 'shared/functions/getStatusDriverActivity';

type GpsType = {
  activity: ActivityType;
  latitude: string;
  longitude: string;
  timestamp: string;
  speed: number;
} | null;

export const convertDataToTrackingList = (trackingTransport: TrackingTransport[]) => {
  return trackingTransport.reduce<
    {
      jobId: string | null;
      type: string;
      licensePlate: string;
      gps: GpsType;
    }[]
  >((total, current) => {
    const trucks = current.truck_type.reduce<
      {
        gps: GpsType;
        licensePlate: string;
        jobId: string | null;
      }[]
    >((totalTruckType, currentTruckType) => {
      const tracking = currentTruckType.tracking.map((trackingItem) => ({
        gps: trackingItem.gps,
        licensePlate: trackingItem.license_plate,
        jobId: trackingItem.job_id,
      }));
      return [...totalTruckType, ...tracking];
    }, []);

    const addType = trucks.map((truck) => ({
      ...truck,
      type: current.headers,
    }));
    return [...total, ...addType];
  }, []);
};
