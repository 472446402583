import gql from 'graphql-tag';

export interface CreateCustomerData {
  createCustomer: {
    _id: string;
  };
}

export interface CreateCustomerVars {
  ar_code: string;
  ar_name: string;
  lat: string;
  lng: string;
  transport_address: string;
  pin_map: string;
  shipto_tel: string;
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
  province_code: string;
  district_code: string;
}

export default gql`
  mutation CreateCustomer(
    $ar_code: String
    $ar_name: String
    $lat: String
    $lng: String
    $transport_address: String
    $pin_map: String
    $shipto_tel: String
    $sub_district: String
    $district: String
    $province: String
    $postcode: String
    $province_code: String
    $district_code: String
  ) {
    createCustomer(
      createCustomer: {
        ar_code: $ar_code
        ar_name: $ar_name
        lat: $lat
        lng: $lng
        transport_address: $transport_address
        pin_map: $pin_map
        shipto_tel: $shipto_tel
        sub_district: $sub_district
        district: $district
        province: $province
        postcode: $postcode
        province_code: $province_code
        district_code: $district_code
      }
    ) {
      _id
    }
  }
`;
