import gql from 'graphql-tag';

export type LastMileList = string[];

export interface QueryFilterLastMileData {
  queryFilterLastMile: {
    lastMileList: LastMileList;
  };
}

export default gql`
  query QueryFilterLastMile {
    queryFilterLastMile {
      lastMileList
    }
  }
`;
