import styled from 'styled-components';

type ContainerProps = {
  isActive?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  & .datetime {
    font-size: 12px;
  }

  ${(props) =>
    props.isActive &&
    `
    background-color: #f9f9f9;
  `}

  &:hover {
    background-color: #f5f5f5;
  }

  & > div:first-child {
    margin-right: 20px;
    margin-top: 10px;
  }
`;
