import styled from 'styled-components';

const StyledIcon = styled.i`
  display: inline-block;
  font-size: ${(props) => (props.size ? `${props.size}px` : 'inherit')};
  ${(props) =>
    props.left || props.top ? `transform: translate(${props.left}px, ${props.top}px);` : ''}
`;

export default StyledIcon;
