import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_FILTER_ROUTE_CODE_QUERY, {
  QueryFilterRouteCodeData,
  RouteCodeList,
} from 'shared/graphql/query/queryFilterRouteCode';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectMultiRouteCodeProps = {
  value?: string[];
  disabled?: boolean;
  onChange?: (values: string[]) => void;
};

const { Option } = Select;

const SelectMultiRouteCode: React.FC<SelectMultiRouteCodeProps> = (props) => {
  const { value, disabled, onChange } = props;
  const [routeCodeList, setRouteCodeList] = useState<RouteCodeList>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<QueryFilterRouteCodeData>(
    QUERY_FILTER_ROUTE_CODE_QUERY,
    {
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.queryFilterRouteCode) {
      setRouteCodeList([...data.queryFilterRouteCode.routeCodeList]);
    }
  }, [data?.queryFilterRouteCode, loading, error]);

  const options: ReactNode[] = routeCodeList.map((item) => {
    return <Option key={item} value={item}>{`${item}`}</Option>;
  });

  return (
    <Select
      allowClear
      mode="multiple"
      maxTagCount="responsive"
      value={value}
      disabled={disabled}
      loading={loading}
      placeholder="ค้นหา"
      onChange={(valueChange) => {
        onChange && onChange(valueChange);
      }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: '100%' }}
    >
      {options}
    </Select>
  );
};

export default SelectMultiRouteCode;
