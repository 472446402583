import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { StyledHeaderTextModal, StyledSubTitleTextModal } from './Styles';

const { Text } = Typography;

const propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

const defaultProps = {
  subTitle: '',
};

const TextModalHeader = (props) => {
  const { title, subTitle } = props;

  return (
    <>
      <StyledHeaderTextModal level={4}>{title}</StyledHeaderTextModal>
      {subTitle !== '' && (
        <StyledSubTitleTextModal type="secondary">{subTitle}</StyledSubTitleTextModal>
      )}
    </>
  );
};

TextModalHeader.propTypes = propTypes;
TextModalHeader.defaultProps = defaultProps;

export default TextModalHeader;
