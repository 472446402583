import React, { createContext, ReactNode, useReducer, Dispatch } from 'react';

interface SelectTruckAction {
  type: 'SELECT_TRUCK';
  payload: {
    truck: {
      type: string;
      jobId: string;
      gps: {
        lat: string;
        lng: string;
      } | null;
    };
  };
}

interface FitBoundAction {
  type: 'FIT_BOUND';
  payload: {
    isFitBound: boolean;
  };
}

interface CloseModalAction {
  type: 'CLOSE_MODAL';
}

type Action = SelectTruckAction | CloseModalAction | FitBoundAction;

type State = {
  truck: {
    type: string;
    jobId: string;
    gps: {
      lat: string;
      lng: string;
    } | null;
  } | null;
  isOpenModalJob: boolean;
  isFitBound: boolean;
};

const initialState: State = {
  truck: null,
  isOpenModalJob: false,
  isFitBound: false,
};

function selectTruck(state: State, action: Action) {
  if (action.type === 'SELECT_TRUCK') {
    const { jobId } = action.payload.truck;

    return {
      ...state,
      truck: action.payload.truck,
      isOpenModalJob: !!jobId,
    };
  }

  return state;
}

function closeModal(state: State, action: Action) {
  if (action.type === 'CLOSE_MODAL') {
    return {
      ...state,
      isOpenModalJob: false,
    };
  }

  return state;
}

function fitBound(state: State, action: Action) {
  if (action.type === 'FIT_BOUND') {
    return {
      ...state,
      isFitBound: action.payload.isFitBound,
    };
  }

  return state;
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'SELECT_TRUCK':
      return selectTruck(state, action);
    case 'CLOSE_MODAL':
      return closeModal(state, action);
    case 'FIT_BOUND':
      return fitBound(state, action);
    default:
      return { ...state };
  }
};

type DoProviderProps = {
  children: ReactNode;
};

type Store = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const TrackingTransporterContext = createContext<Store>({
  state: initialState,
  dispatch: () => null,
});

const TrackingTransporterProvider: React.FC<DoProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TrackingTransporterContext.Provider value={{ state, dispatch }}>
      {children}
    </TrackingTransporterContext.Provider>
  );
};

export default TrackingTransporterProvider;
