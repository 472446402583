/* eslint-disable camelcase */
import React, { useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import Logo from 'shared/components/Logo';
import version from 'shared/config/version';
import { buildDateFormat, buildDateFromNow } from 'shared/utils/buildDate';
import Header from './Header';
import LinkMenu from './LinkMenu';
import { StyledSider, VersionContainer, LogoContainer, StyledContent, TextLogo } from './Styles';

const LayoutContainer = (props) => {
  const { history, children, textHeader } = props;
  const [collapsed, setCollapsed] = useState(!!localStorage.getItem('collapsed'));
  const { typeUser } = useCurrentUser();

  const toggle = useCallback(() => {
    setCollapsed((prevState) => {
      if (prevState) {
        localStorage.removeItem('collapsed');
      } else {
        localStorage.setItem('collapsed', true);
      }
      return !prevState;
    });
  }, []);

  const getTheme = (type) => {
    switch (type) {
      case 'agent':
        return 'red';
      case 'superAdmin':
        return 'dark';
      default:
        return undefined;
    }
  };

  const theme = getTheme(typeUser);
  // sheet 17
  return (
    <Layout>
      <StyledSider trigger={null} collapsible collapsed={collapsed} collapsedWidth={60}>
        <LogoContainer theme={theme}>
          <Link to="/">
            <Logo
              theme={typeUser === 'admin' ? 'light' : 'dark'}
              clicked={() => history.push('/')}
              size={collapsed ? 'small' : undefined}
            />
          </Link>
          {typeUser === 'superAdmin' && !collapsed && (
            <TextLogo color="white">SUPER ADMIN</TextLogo>
          )}
          {typeUser === 'admin' && !collapsed && <TextLogo>ADMIN</TextLogo>}
          {typeUser === 'agent' && !collapsed && <TextLogo color="white">AGENT</TextLogo>}
        </LogoContainer>
        <LinkMenu />
        <VersionContainer collapsed={collapsed}>
          v{version}
          {process.env.REACT_APP_STAGE === 'development' && (
            <>
              <div>{`(${collapsed ? 'DEV' : 'Mode: Development'})`}</div>
              {!collapsed && <div title={buildDateFormat}>{`Build Date: ${buildDateFromNow}`}</div>}
            </>
          )}
        </VersionContainer>
      </StyledSider>
      <Layout style={{ marginLeft: collapsed ? 60 : 200 }}>
        <Header theme={theme} textHeader={textHeader} collapsed={collapsed} toggle={toggle} />
        <StyledContent>{children}</StyledContent>
      </Layout>
    </Layout>
  );
};

export default withRouter(LayoutContainer);
