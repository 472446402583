import * as yup from 'yup';

const validateSchema = yup.object().shape({
  token: yup.string().required('กรุณากรอกข้อมูล'),
  password: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .min(6, 'รหัสผ่านต้องมีอย่างน้อย 6 - 20 ตัวอักษร')
    .max(20, 'รหัสผ่านต้องมีอย่างน้อย 6 - 20 ตัวอักษร'),
  passwordConfirmation: yup
    .string()
    .required('กรุณากรอกข้อมูล')
    .test('passwords-match', 'รหัสผ่านไม่ตรงกัน', function (value) {
      return this.parent.password === value;
    }),
});

export default validateSchema;
