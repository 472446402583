import gql from 'graphql-tag';

export interface CreateWarehouseData {
  createWarehouse: {
    _id: string;
  };
}

export interface CreateWarehouseVars {
  stock_site: string;
  pick_up_contact_name: string;
  pick_up_contact_tel: string;
  pick_up_point_name: string;
  pick_up_address: string;
  lat: string;
  lng: string;
  hub: string;
  lastMile: string;
}

export default gql`
  mutation CreateWarehouse(
    $stock_site: String
    $pick_up_contact_name: String
    $pick_up_contact_tel: String
    $pick_up_point_name: String
    $pick_up_address: String
    $lat: String
    $lng: String
    $hub: String
    $lastMile: String
  ) {
    createWarehouse(
      createWarehouse: {
        stock_site: $stock_site
        pick_up_contact_name: $pick_up_contact_name
        pick_up_contact_tel: $pick_up_contact_tel
        pick_up_point_name: $pick_up_point_name
        pick_up_address: $pick_up_address
        lat: $lat
        lng: $lng
        hub: $hub
        lastMile: $lastMile
      }
    ) {
      _id
    }
  }
`;
