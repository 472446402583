import gql from 'graphql-tag';

export interface SettingDistrictList {
  _id: string;
  district_code: string;
  district_name: string;
  province_code: string;
}

export interface SettingDistrictListData {
  settingDistrictList: {
    settingDistrictList: SettingDistrictList[];
  };
}

export interface SettingDistrictListVars {
  order_by?: string;
  order_type?: '1' | '-1';
  page?: number;
  showData?: number;
  province_code?: string;
  province_name?: string;
}

export default gql`
  query SettingDistrictList(
    $province_code: String
    $province_name: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    settingDistrictList(
      params: { province_code: $province_code, province_name: $province_name }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      settingDistrictList {
        _id
        district_code
        district_name
        province_code
      }
    }
  }
`;
