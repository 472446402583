import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageError } from 'shared/components';
import LoadableComponent from 'shared/components/LoadableComponent';

const BranchList = LoadableComponent(() => import('./Routes/BranchList'));
const BranchCreate = LoadableComponent(() => import('./Routes/BranchDetail2/CreateBranch'));
const BranchDetail = LoadableComponent(() => import('./Routes/BranchDetail'));

const Branch = (props) => {
  const { match } = props;

  return (
    <Layout textHeader="ตั้งค่าสาขา">
      <Switch>
        <Route path={`${match.path}/detail/:id`} component={BranchDetail} />
        <Route exact path={`${match.path}/create`} component={BranchCreate} />
        <Route exact path={`${match.path}`} component={BranchList} />
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Branch;
