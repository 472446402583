import styled from 'styled-components';
import { Typography } from 'antd';
import { font } from 'shared/utils/styles';

const { Title } = Typography;
export const StyledHeaderTextCard = styled(Title)`
  ${font.bold}
  margin-bottom: 0px !important;
`;

export default StyledHeaderTextCard;
