import React from 'react';
import { message } from 'antd';
import { Button, TextModalHeader } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/react-hooks';
import CREATE_WAREHOUSE_MUTATION, {
  CreateWarehouseData,
  CreateWarehouseVars,
} from 'shared/graphql/mutation/createWarehouse';
import useMessageError from 'shared/hooks/useMessageError';
import validateSchema from '../validateSchema';
import { FormValue } from '../formValue.model';
import Forms from '../Forms';
import initialValue from '../initialValue';
import { StyledModal } from './Styles';

type ModalCreateWarehouseProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalCreateWarehouse: React.FC<ModalCreateWarehouseProps> = ({ visible, onCancel }) => {
  const { getMessageError } = useMessageError();
  const [createWarehouse, { loading }] = useMutation<CreateWarehouseData, CreateWarehouseVars>(
    CREATE_WAREHOUSE_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createWarehouse._id) {
          message.success('เพิ่มจุดรับแล้ว');
          onCancel();
        }
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      refetchQueries: ['WarehouseList'],
    },
  );
  const methods = useForm<FormValue>({
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((values) => {
    createWarehouse({
      variables: {
        stock_site: values.stockSite,
        pick_up_contact_name: values.pickUpContactName,
        pick_up_contact_tel: values.pickUpContactTel,
        pick_up_point_name: values.pickUpPointName,
        pick_up_address: values.pickUpAddress,
        lat: values.lat,
        lng: values.lng,
        hub: values.hub,
        lastMile: values.lastMile,
      },
    });
  });

  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      title={
        <TextModalHeader
          title="เพิ่มข้อมูลคลัง"
          subTitle="กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนการกดบันทึก"
        />
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          ปิด
        </Button>,
        <Button
          key="ok"
          disabled={loading}
          loading={loading}
          type="success"
          onClick={() => onSubmit()}
        >
          บันทึก
        </Button>,
      ]}
      destroyOnClose
    >
      <FormProvider {...methods}>
        <Forms />
      </FormProvider>
    </StyledModal>
  );
};

export default ModalCreateWarehouse;
