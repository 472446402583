import styled from 'styled-components';
import { Button } from 'antd';
import { color, mixin } from 'shared/utils/styles';

const StyledButton = styled(Button)`
  &.ant-btn .anticon {
    vertical-align: -3px;
  }

  &.ant-btn:not(:disabled).ant-btn {
    &-success {
      background-color: ${color.backgroundSuccess};
      border-color: ${color.backgroundSuccess};
      color: ${color.white};

      &:active,
      &:focus,
      &:hover {
        background-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
        border-color: ${mixin.rgba(color.backgroundSuccess, 0.8)};
        color: ${color.white};
      }

      &-outline {
        color: ${color.success};
        border-color: ${color.backgroundSuccess};

        &:active,
        &:focus,
        &:hover {
          background-color: ${color.white};
          border-color: ${mixin.rgba(color.success, 0.8)};
          color: ${color.success};
        }
      }
    }

    &-danger {
      background-color: ${color.backgroundDanger};
      border-color: ${color.backgroundDanger};
      color: ${color.white};

      &:active,
      &:focus,
      &:hover {
        background-color: ${mixin.rgba(color.backgroundDanger, 0.8)};
        border-color: ${mixin.rgba(color.backgroundDanger, 0.8)};
        color: ${color.white};
      }

      &-outline {
        color: ${color.danger};
        border-color: ${color.backgroundDanger};

        &:active,
        &:focus,
        &:hover {
          background-color: ${color.white};
          border-color: ${mixin.rgba(color.danger, 0.8)};
          color: ${color.danger};
        }
      }
    }
  }
`;
export default StyledButton;
