import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { StyledHeaderTextCard } from './Styles';

const { Text } = Typography;

const propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

const defaultProps = {
  subTitle: '',
};

const TextCardHeader = (props) => {
  const { title, subTitle } = props;

  return (
    <>
      <StyledHeaderTextCard level={3}>{title}</StyledHeaderTextCard>
      {subTitle !== '' && <Text type="secondary">{subTitle}</Text>}
    </>
  );
};

TextCardHeader.propTypes = propTypes;
TextCardHeader.defaultProps = defaultProps;

export default TextCardHeader;
