import styled from 'styled-components';
import { Table } from 'antd';
import { mixin, font } from 'shared/utils/styles';

export const StyledTable = styled(Table)`
  ${mixin.backgroundTableEven}

  & .ant-table-thead > tr > th {
    ${font.black}
    vertical-align: top;
    background-color: transparent;

    & .table-header {
      &__text {
        padding-bottom: 4px;
      }
    }
  }
`;
export default StyledTable;
