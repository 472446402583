import React, { ReactNode } from 'react';
import { Card, Avatar, Row, Col, Typography, Button, Popover } from 'antd';
import Icon from '@ant-design/icons';
import TruckSolid from 'shared/assets/icon/truck-solid.svg';
import QuestionCircleLight from 'shared/assets/icon/question-circle-light.svg';
import { colors } from '../../colors';

const { Text } = Typography;

type CardTruckProps = {
  color?: 'black' | 'green' | 'gray' | 'orange' | 'blue';
  title: string;
  content: ReactNode | string | number;
  textPopOver: string;
};

const CardTruck: React.FC<CardTruckProps> = ({ color, title, content, textPopOver }) => {
  return (
    <Card size="small" style={{ width: '100%' }}>
      <div style={{ position: 'absolute', top: 6, right: 6, zIndex: 1 }}>
        <Popover
          content={
            <div style={{ maxWidth: 160 }}>
              <Text type="secondary">{textPopOver}</Text>
            </div>
          }
        >
          <Button type="text" size="small" icon={<Icon component={QuestionCircleLight} />} />
        </Popover>
      </div>
      <Row align="middle" justify="start">
        <Col style={{ marginRight: 20 }}>
          <Avatar
            size={48}
            icon={<Icon component={TruckSolid} />}
            style={{
              ...colors[color || 'black'],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Col>
        <Col flex={1}>
          <Row>
            <Col span={24}>{title}</Col>
            <Col span={24}>
              <Text strong style={{ fontSize: 18 }}>
                {content}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default CardTruck;
