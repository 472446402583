import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import SETTING_SUB_DISTRICT_LIST_QUERY, {
  SettingSubDistrictListData,
  SettingSubDistrictList,
  SettingSubDistrictListVars,
} from 'shared/graphql/query/settingSubDistrictList';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

type OptionType = {
  value: string;
  postCode: string;
};
type OptionData = {
  value: string;
  postCode: string;
};
type SelectSettingSubDistrictListProps = {
  value?: string;
  disabled?: boolean;
  provinceCode?: string;
  districtCode?: string;
  provinceName?: string;
  districtName?: string;
  onChange?: (values?: {
    subDistrictId: string;
    subDistrictName: string;
    districtCode: string;
    provinceCode: string;
    postcode: string;
  }) => void;
};

const { Option } = Select;

const SelectSettingSubDistrictList: React.FC<SelectSettingSubDistrictListProps> = (props) => {
  const {
    value,
    disabled,
    onChange,
    provinceCode,
    districtCode,
    provinceName,
    districtName,
  } = props;
  const [settingSubDistrictList, setSettingSubDistrictList] = useState<SettingSubDistrictList[]>(
    [],
  );
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<SettingSubDistrictListData, SettingSubDistrictListVars>(
    SETTING_SUB_DISTRICT_LIST_QUERY,
    {
      variables: {
        province_code: provinceCode,
        district_code: districtCode,
        district_name: districtName,
        province_name: provinceName,
        showData: 1000,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.settingSubDistrictList) {
      const sortSubDistrictName = _.sortBy(
        data.settingSubDistrictList.settingSubDistrictList,
        ['sub_district_name'],
        ['asc'],
      );
      setSettingSubDistrictList([...sortSubDistrictName]);
    }
  }, [data?.settingSubDistrictList, loading, error]);

  const options: ReactNode[] = settingSubDistrictList.map((item) => (
    <Option
      key={uuidv4()}
      value={item.sub_district_name + item.postcode}
      dataDistrict={{
        subDistrict: item.sub_district_name,
        postcode: item.postcode,
      }}
    >{`${item.sub_district_name}(${item.postcode})`}</Option>
  ));
  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }
    const findDistrict = settingSubDistrictList.find(
      (item) => item.sub_district_name + item.postcode === valueInput,
    );
    if (onChange && findDistrict) {
      const { _id, district_code, sub_district_name, postcode, province_code } = findDistrict;
      onChange({
        subDistrictId: _id,
        subDistrictName: sub_district_name,
        provinceCode: province_code,
        districtCode: district_code,
        postcode: postcode,
      });
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือกตำบล"
        onChange={(valueChange) => onSelected(valueChange)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default SelectSettingSubDistrictList;
