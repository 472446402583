import styled from 'styled-components';
import { Drawer } from 'antd';
import { color } from 'shared/utils/styles';

export const StyledDrawer = styled(Drawer)`
  &.custom-drawer.ant-drawer {
    & .ant-drawer-content-wrapper {
      box-shadow: unset;
      border-left: 1px solid ${color.border};
    }
  }
`;

export default StyledDrawer;
