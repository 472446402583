import gql from 'graphql-tag';

export interface Driver {
  _id: string;
  picture: string;
  number: string;
  active_status: boolean;
  truck: string[];
  full_name: string;
  phone: {
    phone_type: string;
    country_code: string;
    number: string;
  };
  create_date: string;
  last_update: string;
  driver_type: boolean;
  worker_type: boolean;
}

export interface DriversData {
  drivers: {
    drivers: Driver[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface DriversVars {
  params?: {
    _id?: string;
    full_name?: string;
    phone?: {
      phone_type?: string;
      country_code?: string;
      number?: string;
    };
    active_status?: boolean;
    truck?: string;
    create_date?: string;
    last_update?: string;
    vendor?: string;
    number?: string;
  };
  sort?: {
    order_by?: string;
    order_type?: string;
  };
  page?: number;
  showData?: number;
}

export default gql`
  query Drivers($params: paramsDrivers, $sort: sort, $page: Int, $showData: Int) {
    drivers(params: $params, sort: $sort, page: $page, showData: $showData) {
      drivers {
        _id
        picture
        number
        active_status
        truck
        full_name
        phone {
          phone_type
          country_code
          number
        }
        create_date
        last_update
        driver_type
        worker_type
      }
      totalPage
      totalDocument
    }
  }
`;
