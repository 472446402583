/* eslint-disable no-underscore-dangle */
import React, { ReactNode, useState } from 'react';
import { Select, Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import COMPANIES_QUERY, { CompaniesData, CompaniesVars } from 'shared/graphql/query/companies';

const { Option } = Select;

type SelectCompaniesFieldProps = {
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
};

const SelectCompaniesField: React.FC<SelectCompaniesFieldProps> = (props) => {
  const { value, onChange, style, ...otherProps } = props;
  const [name, setName] = useState('');
  const { data, loading } = useQuery<CompaniesData, CompaniesVars>(COMPANIES_QUERY, {
    variables: {
      branch_name: name,
      showData: 1000,
    },
    fetchPolicy: 'cache-and-network',
  });

  let options: ReactNode;
  if (data && data.companies) {
    const { companies } = data.companies;
    options = companies.map((company) => (
      <Option
        key={company._id}
        value={company._id}
        title={`${company.branch_name} (${company.company_name})`}
      >
        {company.branch_name} ({company.company_name})
      </Option>
    ));
  }

  return (
    <Select
      style={{
        width: '100%',
        ...style,
      }}
      value={value || undefined}
      notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      loading={loading}
      showSearch
      placeholder="ค้นหา"
      onSearch={(valueSearch) => setName(valueSearch)}
      onChange={(valueChange) => onChange && onChange(valueChange)}
      optionFilterProp="children"
      filterOption={false}
      {...otherProps}
    >
      {options}
    </Select>
  );
};

export default SelectCompaniesField;
