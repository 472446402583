import gql from 'graphql-tag';

export interface PermissionList {
  orderList: string[];
  bookingList: string[];
  jobList: string[];
  renewalList: string[];
  vendorList: string[];
  reportList: string[];
  setting: string[];
}

export interface Permission {
  _id: string;
  permission_name: string;
  last_update: string;
  permission: PermissionList;
}

export interface ProfilePermissionListData {
  profilePermissionList: {
    permission: Permission[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface ProfilePermissionListVars {
  permission_name?: string;
  showData?: number;
  page?: number;
  order_by?: string;
  order_type?: string;
  company: string;
}

export default gql`
  query ProfilePermissionList(
    $permission_name: String
    $showData: Int
    $page: Int
    $order_by: String
    $order_type: String
    $company: ID
  ) {
    profilePermissionList(
      params: { permission_name: $permission_name, company: $company }
      showData: $showData
      page: $page
      sort: { order_by: $order_by, order_type: $order_type }
    ) {
      permission {
        _id
        permission_name
        last_update
        permission {
          orderList
          bookingList
          jobList
          renewalList
          vendorList
          reportList
          setting
        }
      }
      totalPage
      totalDocument
    }
  }
`;
