import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker: RangePickerAntd } = DatePicker;

type RangePickerProps = {
  onChange?: (date: string | null) => void;
  value?: string;
};

const RangePicker: React.FC<RangePickerProps> = ({ onChange, value }) => {
  const splitValue = value ? value.split(':') : undefined;

  return (
    <RangePickerAntd
      onChange={(values) => {
        if (!onChange) {
          return;
        }

        if (values === null) {
          onChange(null);
        } else {
          onChange(`${values[0]?.format('YYYY-MM-DD')}:${values[1]?.format('YYYY-MM-DD')}`);
        }
      }}
      ranges={{
        วันนี้: [moment(), moment()],
        สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
        เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
      }}
      format="DD/MM/YYYY"
      placeholder={['เริ่มต้น', 'สิ้นสุด']}
      value={splitValue && [moment(splitValue[0]), moment(splitValue[1])]}
    />
  );
};

export default RangePicker;
