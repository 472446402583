import preval from 'preval.macro';
import moment from 'moment';

const format = 'ddd, DD MMM YYYY - HH:mm:ss';
const dateTimeBuild = preval`module.exports = new Date().toISOString();`;
const dateTimeUTC = moment(dateTimeBuild).utc().format('YYYY/MM/DD HH:mm:ss');
const currentTime = moment(dateTimeUTC).add('7', 'hours');

export const buildDateFormat = currentTime.format(format);
export const buildDateFromNow = currentTime.fromNow();
