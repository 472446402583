import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PROFILE_PERMISSION_LIST_QUERY, {
  ProfilePermissionListData,
  ProfilePermissionListVars,
  Permission,
} from 'shared/graphql/query/profilePermissionList';
import { Select, message, Empty } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectProfilePermissionProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: Permission) => void;
  placeholder?: string;
  companyId: string;
};

const { Option } = Select;

const SelectProfilePermission: React.FC<SelectProfilePermissionProps> = (props) => {
  const { value, disabled, onChange, placeholder, companyId } = props;
  const [search, setSearch] = useState<string>('');
  const [permission, setPermission] = useState<Permission[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<ProfilePermissionListData, ProfilePermissionListVars>(
    PROFILE_PERMISSION_LIST_QUERY,
    {
      variables: {
        permission_name: search,
        company: companyId,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.profilePermissionList) {
      setPermission([...data.profilePermissionList.permission]);
    }
  }, [data?.profilePermissionList, loading, error]);

  const options: ReactNode[] = permission.map((item) => (
    <Option key={item._id} value={item._id}>{`${item.permission_name}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = permission.find((item) => item._id === valueInput);
    if (onChange && findValue) {
      onChange(findValue);
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder || 'เลือก'}
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        style={{ width: '100%' }}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectProfilePermission;
