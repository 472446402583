import gql from 'graphql-tag';

export interface SettingProvinceList {
  _id: string;
  province_code: string;
  province_name: string;
}

export interface SettingProvinceListData {
  settingProvinceList: {
    settingProvinceList: SettingProvinceList[];
  };
}

export interface SettingProvinceListVars {
  order_by?: string;
  order_type?: '1' | '-1';
  page?: number;
  showData?: number;
}

export default gql`
  query SettingProvinceList($order_by: String, $order_type: String, $page: Int, $showData: Int) {
    settingProvinceList(
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      settingProvinceList {
        _id
        province_code
        province_name
      }
    }
  }
`;
