import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageError } from 'shared/components';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import LoadableComponent from 'shared/components/LoadableComponent';

const BookingList = LoadableComponent(() => import('./Routes/Bookings'));
const ContactBookings = LoadableComponent(() => import('./Routes/ContactBookings'));

const Booking = (props) => {
  const { match } = props;
  const { typeUser } = useCurrentUser();

  const routes = [
    {
      path: `${match.path}/contact-bookings`,
      component: ContactBookings,
      typeUser: ['admin', 'superAdmin'],
    },
    {
      path: `${match.path}/list`,
      component: BookingList,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
  ].filter((route) => route.typeUser.includes(typeUser));

  const renderRoute = routes.map((route) => (
    <Route key={route.path} path={route.path} component={route.component} />
  ));

  const redirectUrl = routes.length > 0 ? routes[0].path : undefined;

  return (
    <Layout textHeader="รายการใบงาน">
      <Switch>
        {redirectUrl && <Redirect exact from="/booking" to={redirectUrl} />}
        {renderRoute}
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Booking;
