import React, { useState, ReactNode, useEffect } from 'react';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

type Tab = {
  title: string;
  component?: ReactNode;
  extra?: ReactNode;
  key?: number;
};
type TabSelectProps = {
  tab: Tab[];
  extra?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TabSelect: React.FC<TabSelectProps> = (props) => {
  const { tab, onChange } = props;

  const renderTab = tab.map((tabs) => (
    <TabPane tab={tabs.title} key={tabs.key}>
      {tabs.component}
    </TabPane>
  ));
  return (
    <Tabs defaultActiveKey="1" tabBarExtraContent={props.extra} onChange={(e) => onChange}>
      {renderTab}
    </Tabs>
  );
};

export default TabSelect;
