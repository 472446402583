import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageError } from 'shared/components';
import LoadableComponent from 'shared/components/LoadableComponent';

const InvoiceList = LoadableComponent(() => import('./Routes/InvoiceList'));
const InvoiceDetail = LoadableComponent(() => import('./Routes/InvoiceDetail'));

const Invoice: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  return (
    <Layout textHeader="รายการงานขนส่ง">
      <Switch>
        <Route exact path={`${match.path}/detail/:id`} component={InvoiceDetail} />
        <Route exact path={`${match.path}`} component={InvoiceList} />
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Invoice;
