import React, { ReactNode } from 'react';
import { Avatar, Col, Typography, Badge } from 'antd';
import Icon from '@ant-design/icons';
import BoxAltSolid from 'shared/assets/icon/box-alt-solid.svg';
import DollyFlatbedSolid from 'shared/assets/icon/dolly-flatbed-solid.svg';
import FileEditSolid from 'shared/assets/icon/file-edit-solid.svg';
import FileAltSolid from 'shared/assets/icon/file-alt-solid.svg';
import FileExclamationSolid from 'shared/assets/icon/file-exclamation-solid.svg';
import parse from 'html-react-parser';
import { Container } from './Styles';

const { Text } = Typography;

type NotificationItem = {
  iconType: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  title?: string;
  description?: string;
  dateTime?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const NotificationList: React.FC<NotificationItem> = ({
  isActive,
  title,
  description,
  dateTime,
  onClick,
  iconType,
}) => {
  const colors: {
    [key: string]: {
      backgroundColor: string;
      color: string;
    };
  } = {
    red: {
      backgroundColor: '#FFE8E3',
      color: '#CF1322',
    },
    green: {
      backgroundColor: '#DFF5E5',
      color: '#068828',
    },
    yellow: {
      backgroundColor: '#FFE4C6',
      color: '#FB9219',
    },
    blue: {
      backgroundColor: '#D0E7F9',
      color: '#1487E2',
    },
    black: {
      backgroundColor: '#DBDBDB',
      color: '#212429',
    },
  };

  const iconColors = {
    1: {
      icon: <Icon component={BoxAltSolid} />,
      color: colors.blue,
    },
    2: {
      icon: <Icon component={DollyFlatbedSolid} />,
      color: colors.yellow,
    },
    3: {
      icon: <Icon component={FileEditSolid} />,
      color: colors.yellow,
    },
    4: {
      icon: <Icon component={BoxAltSolid} />,
      color: colors.black,
    },
    5: {
      icon: <Icon component={BoxAltSolid} />,
      color: colors.blue,
    },
    6: {
      icon: <Icon component={FileAltSolid} />,
      color: colors.yellow,
    },
    7: {
      icon: <Icon component={FileExclamationSolid} />,
      color: colors.red,
    },
    8: {
      icon: <Icon component={DollyFlatbedSolid} />,
      color: colors.green,
    },
  };

  const getIconType = iconColors[iconType];

  return (
    <Container isActive={isActive} onClick={() => onClick && onClick()}>
      <div>
        <Badge dot={isActive} color={isActive ? '#CF1322' : undefined} offset={[-4, 4]}>
          <Avatar size={30} icon={getIconType.icon} style={getIconType.color} />
        </Badge>
      </div>
      <div>
        <div>
          <Text strong>{title}</Text>
        </div>
        <div style={{ whiteSpace: 'pre-wrap' }}>{parse(description || '')}</div>
        <div className="datetime">
          <Text type="secondary">{dateTime}</Text>
        </div>
      </div>
    </Container>
  );
};

export default NotificationList;
