import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import STOCK_SITE_GROUP_QUERY, { StockSiteGroupData } from 'shared/graphql/query/stockSiteGroup';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import _ from 'lodash';

type SelectStockSiteProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: { stockSiteId: string; stockSite: string }) => void;
};

const { Option } = Select;

const SelectStockSite: React.FC<SelectStockSiteProps> = (props) => {
  const { value, disabled, onChange } = props;
  const [stockSiteGroup, setStockSiteGroup] = useState<
    {
      _id: string;
      stockSite: string;
    }[]
  >([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<StockSiteGroupData>(STOCK_SITE_GROUP_QUERY, {
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && !error && data?.stockSiteGroup) {
      const sortData = _.sortBy(data.stockSiteGroup, ['stockSite'], ['asc']);

      setStockSiteGroup([...sortData]);
    }
  }, [data?.stockSiteGroup, loading, error]);

  const options: ReactNode[] = stockSiteGroup.map((item, index) => (
    <Option key={index} value={item._id}>{`${item.stockSite}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findStockSite = stockSiteGroup.find((item) => item._id === valueInput);
    if (onChange && findStockSite) {
      const { _id, stockSite } = findStockSite;
      onChange({
        stockSiteId: _id,
        stockSite,
      });
    }
  };

  return (
    <>
      <Select
        style={{ width: '100%' }}
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือก StockSite"
        onChange={(valueChange) => onSelected(valueChange)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default SelectStockSite;
