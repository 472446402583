import { css } from 'styled-components';
import Color from 'color';

export const color = {
  // text
  primary: '#D90101',
  success: '#258800',
  danger: '#D80000',
  white: '#FFFFFF',
  grey: 'rgba(0, 0, 0, 0.65)',
  black: '#000000',
  blue: '#3377b7',
  // bg
  backgroundPrimary: '#D90101',
  backgroundDanger: '#D80000',
  backgroundSuccess: '#258800',
  backgroundLightPrimary: '#FFEAE6',
  // border
  border: '#F0F0F0',
};

export const font = {
  regular: 'font-family: "Prompt"; font-weight: 200;',
  bold: 'font-family: "Prompt"; font-weight: 400;',
  black: 'font-family: "Prompt"; font-weight: 500;',
  size: (size) => `font-size: ${size}px;`,
};

export const mixin = {
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  rgba: (colorValue, opacity) => Color(colorValue).alpha(opacity).string(),
  backgroundTableOdd: css`
    table tr:nth-child(odd) td {
      background-color: #f9f9f9;
    }
  `,
  backgroundTableEven: css`
    table tr:nth-child(even) td {
      background-color: #f9f9f9;
    }
    table tr:nth-child(odd) td.ant-table-column-sort {
      background: unset !important;
    }
    table tr:nth-child(odd):hover td.ant-table-column-sort {
      background: rgb(250, 250, 250) !important;
    }
  `,
};
