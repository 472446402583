import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import UPDATE_NOTIFICATIONS_MUTATION, {
  UpdateNotificationsData,
  UpdateNotificationsVars,
} from 'shared/graphql/mutation/updateNotifications';
import useMessageError from 'shared/hooks/useMessageError';

export const useReadNotification = () => {
  const { getMessageError } = useMessageError();

  const [updateNotifications, { loading, error, data }] = useMutation<
    UpdateNotificationsData,
    UpdateNotificationsVars
  >(UPDATE_NOTIFICATIONS_MUTATION, {
    onError: (err) => {
      message.error(getMessageError(err));
    },
    refetchQueries: () => ['Notifications'],
  });

  const onReadAll = (readAll: true) => {
    updateNotifications({
      variables: {
        read_all: readAll,
      },
    });
  };

  const onRead = (value: { id: string; read: boolean }, cb?: () => void) => {
    updateNotifications({
      variables: {
        _id: value.id,
        read: value.read,
      },
    }).then(({ data: dataUpdateNotification }) => {
      if (dataUpdateNotification?.updateNotifications.succeed) {
        cb && cb();
      }
    });
  };

  return {
    loading,
    error,
    data,
    onReadAll,
    onRead,
  };
};
