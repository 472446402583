import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import BUILD_ROUTE_CODE_QUERY, {
  BuildRouteCodeData,
  BuildRouteCodeVars,
  RouteCode,
} from 'shared/graphql/query/buildRouteCode';
import { Select, message, Empty } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectBuildRouteCodeProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: RouteCode) => void;
  placeholder?: string;
  checkData?: (string | undefined)[] | undefined;
};

const { Option } = Select;

const SelectBuildRouteCode: React.FC<SelectBuildRouteCodeProps> = (props) => {
  const { value, disabled, onChange, placeholder, checkData } = props;
  const [search, setSearch] = useState<string>('');
  const [routeCodes, setRouteCodes] = useState<RouteCode[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<BuildRouteCodeData, BuildRouteCodeVars>(
    BUILD_ROUTE_CODE_QUERY,
    {
      variables: {
        routeCode: search,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.buildRouteCode) {
      setRouteCodes([...data.buildRouteCode.routeCode]);
    }
  }, [data?.buildRouteCode, loading, error]);

  const filterHub = routeCodes.filter((item) => {
    return !checkData?.includes(item);
  });
  const options: ReactNode[] = filterHub.map((item, index) => (
    <Option key={index} value={item}>{`${item}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = routeCodes.find((item) => item === valueInput);
    if (onChange && findValue) {
      onChange(findValue);
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder || 'เลือก'}
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectBuildRouteCode;
