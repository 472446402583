import gql from 'graphql-tag';

export interface HubGroupData {
  hubGroup: {
    _id: string;
    hub: string;
  }[];
}

export default gql`
  query HubGroup {
    hubGroup {
      _id
      hub
    }
  }
`;
