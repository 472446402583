import React from 'react';
import { Link } from 'react-router-dom';
import { StyledHeader } from './Styles';
import Logo from '../Logo';

const HeaderNav = () => {
  return (
    <StyledHeader>
      <Link to="/">
        <Logo />
      </Link>
    </StyledHeader>
  );
};

export default HeaderNav;
