const initialValue = {
  isEdit: false,
  isReadOnly: false,
  warehouseId: '',
  stockSite: '',
  hub: '',
  lastMile: '',
  pickUpPointName: '',
  pickUpContactName: '',
  pickUpContactTel: '',
  pickUpAddress: '',
  lat: '',
  lng: '',
};

export default initialValue;
