import React, { createContext, ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CURRENT_USER_QUERY, {
  CurrentUserData,
  PermissionAccount,
} from 'shared/graphql/query/currentUser';

type TypeUser = 'agent' | 'admin' | 'superAdmin';

type State = {
  user: {
    id: string;
    typeUser: TypeUser;
    permissionAccount: PermissionAccount;
    picture: string | null;
    username: string;
    agentFullName: string;
    companyFullName: string;
    notificationCount: number;
  } | null;
  isLoading: boolean;
};

type AuthContextProvider = {
  children: ReactNode;
};

type Store = {
  state: State;
  isCheckPermission: <T extends keyof PermissionAccount, K extends PermissionAccount[T]>(
    permissionType: T,
    permissionName: K extends Array<infer K> ? K : never,
  ) => boolean;
  isHasPermission: <T extends keyof PermissionAccount>(permissionType: T) => boolean;
};

const initialState: State = {
  user: null,
  isLoading: false,
};

export const AuthContext = createContext<Store>({
  state: initialState,
  isCheckPermission: () => false,
  isHasPermission: () => false,
});

const AuthContextProvider: React.FC<AuthContextProvider> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const { loading, error, data } = useQuery<CurrentUserData>(CURRENT_USER_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const getTypeUser = (userAccountType: number, permission: string[]): TypeUser => {
    let typeUser: TypeUser = 'agent';
    if (userAccountType === 1 && permission.includes('SUPER')) {
      typeUser = 'superAdmin';
    } else if (userAccountType === 1) {
      typeUser = 'admin';
    }

    return typeUser;
  };

  const isCheckPermission = <T extends keyof PermissionAccount, K extends PermissionAccount[T]>(
    permissionType: T,
    permissionName: K extends Array<infer K> ? K : never,
  ) => {
    if (state.user?.typeUser !== 'admin') {
      return true;
    }

    if (!state.user?.permissionAccount) {
      return false;
    }

    const permissionSelected = state.user.permissionAccount[permissionType] as string[];
    return permissionSelected.includes(permissionName);
  };

  const isHasPermission = <T extends keyof PermissionAccount>(permissionType: T) => {
    if (state.user?.typeUser !== 'admin') {
      return true;
    }

    if (!state.user?.permissionAccount) {
      return false;
    }

    const permissionSelected = state.user.permissionAccount[permissionType] as string[];
    return permissionSelected.length > 0;
  };

  useEffect(() => {
    if (!loading && !error && data?.currentUser) {
      const {
        user_account_type,
        permission,
        _id,
        permission_account,
        picture,
        username,
        company,
        vendor,
        wait_to_read_noti,
      } = data.currentUser;

      setState((prevState) => ({
        ...prevState,
        user: {
          id: _id,
          typeUser: getTypeUser(user_account_type, permission),
          permissionAccount: permission_account,
          picture,
          username: username || 'Username',
          notificationCount: wait_to_read_noti,
          agentFullName: vendor ? `${vendor.number} : ${vendor.full_name}` : '',
          companyFullName: company
            ? `${company.branch_code} : ${company.company_name} ${company.branch_name}`
            : '',
        },
      }));
    }
  }, [data?.currentUser, loading, error]);

  const store: Store = {
    state,
    isCheckPermission,
    isHasPermission,
  };

  return <AuthContext.Provider value={store}>{props.children}</AuthContext.Provider>;
};

export default AuthContextProvider;
