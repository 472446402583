import gql from 'graphql-tag';

export interface Vendors {
  _id: string;
  company: string[];
  vendor_picture: string;
  active_status: boolean;
  number: string;
  vendor_type: number;
  title: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: {
    email_type: string;
    email_account: string;
  };
  phone: {
    phone_type: string;
    country_code: string;
    number: string;
  }[];
  social_media: {
    social_media_type: string;
    social_media_account: string;
  };
  tax_id: string;
  authorized_capital: number;
  branch_code: string;
  branch_name: string;
  address: string;
  sub_district: string;
  district: string;
  province: string;
  postcode: string;
  full_address: string;
  address_remark: string;
  address_location: {
    lat: string;
    lng: string;
  };
  create_date: string;
  last_update: string;
}

export interface VendorsData {
  vendors: {
    vendors: Vendors[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface VendorsVars {
  full_name?: string;
  active_status?: boolean;
  phone?: {
    number?: string;
  };
  create_date?: string;
  last_update?: string;
  number?: string;
  page?: number;
  order_by?: string;
  order_type?: string;
  showData?: number;
}

export default gql`
  query Vendors(
    $full_name: String
    $active_status: Boolean
    $order_type: String
    $order_by: String
    $page: Int
    $showData: Int
    $phone: inputPhone
    $number: String
    $create_date: String
    $last_update: String
  ) {
    vendors(
      params: {
        full_name: $full_name
        active_status: $active_status
        phone: $phone
        number: $number
        create_date: $create_date
        last_update: $last_update
      }
      sort: { order_type: $order_type, order_by: $order_by }
      page: $page
      showData: $showData
    ) {
      vendors {
        _id
        company
        vendor_picture
        active_status
        number
        vendor_type
        title
        first_name
        last_name
        full_name
        email {
          email_type
          email_account
        }
        phone {
          phone_type
          country_code
          number
        }
        social_media {
          social_media_type
          social_media_account
        }
        tax_id
        authorized_capital
        branch_code
        branch_name
        address
        sub_district
        district
        province
        postcode
        full_address
        address_remark
        address_location {
          lat
          lng
        }
        create_date
        last_update
      }
      totalPage
      totalDocument
    }
  }
`;
