import gql from 'graphql-tag';
import { ActivityType } from 'shared/functions/getStatusDriverActivity';

export interface Tracking {
  job_id: string | null;
  job_status: number;
  driver_status: number;
  driver_id: string;
  driver_name: string;
  truck_type_name: string;
  truck_id: string;
  license_plate: string;
  gps: {
    activity: ActivityType;
    latitude: string;
    longitude: string;
    timestamp: string;
    speed: number;
  } | null;
}

export interface TruckType {
  type: string;
  tracking: Tracking[];
}

export interface TrackingTransport {
  headers: string;
  count: number;
  truck_type: TruckType[];
}

export interface TrackingTransportData {
  trackingTransport: {
    trackingTransport: TrackingTransport[];
  };
}

export interface TrackingTransportVars {
  truck_type_in?: string[];
  job_status_in?: string[];
  license_plate?: string;
}

export default gql`
  query TrackingTransport(
    $truck_type_in: [String]
    $job_status_in: [String]
    $license_plate: String
  ) {
    trackingTransport(
      params: {
        truck_type_in: $truck_type_in
        job_status_in: $job_status_in
        license_plate: $license_plate
      }
    ) {
      trackingTransport {
        headers
        count
        truck_type {
          type
          tracking {
            job_id
            job_status
            driver_status
            driver_id
            driver_name
            truck_type_name
            truck_id
            license_plate
            gps {
              activity
              latitude
              longitude
              timestamp
              speed
            }
          }
        }
      }
    }
  }
`;
