import gql from 'graphql-tag';

export interface UpdateNotificationsData {
  updateNotifications: {
    succeed: boolean;
  };
}
export interface UpdateNotificationsVars {
  _id?: string;
  read?: boolean;
  read_all?: boolean;
}

export default gql`
  mutation UpdateNotifications($_id: ID, $read_all: Boolean, $read: Boolean) {
    updateNotifications(params: { _id: $_id }, update: { read_all: $read_all, read: $read }) {
      succeed
    }
  }
`;
