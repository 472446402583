import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

type SelectMultiTextProps = {
  value: string[];
  onChange: (values: string[]) => void;
};

const SelectMultiText: React.FC<SelectMultiTextProps> = ({ onChange, value }) => {
  return (
    <Select
      allowClear
      placeholder="ค้นหา"
      filterOption={false}
      mode="tags"
      style={{ width: '100%' }}
      maxTagCount="responsive"
      value={value}
      tokenSeparators={[',']}
      onChange={(valueChange) => {
        onChange(valueChange);
      }}
      notFoundContent={<div>กด Enter เพื่อค้นหา</div>}
    />
  );
};

export default SelectMultiText;
