import React from 'react';
import { TileLayer, LayersControl } from 'react-leaflet';
import hereMapKey from 'shared/config/hereMapKey';
import PropTypes from 'prop-types';
import 'react-leaflet-fullscreen-control';
import { StyledMap } from './Styles';

const propTypes = {
  children: PropTypes.node,
  defaultPosition: PropTypes.arrayOf(PropTypes.number).isRequired,
  zoom: PropTypes.number,
};

const defaultProps = {
  children: undefined,
  defaultPosition: [13.664273, 100.653512],
  zoom: 15,
};

const { BaseLayer } = LayersControl;
const hereStyle = 'normal.day';
const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${hereStyle}/{z}/{x}/{y}/256/png8?apiKey=${hereMapKey}&lg=tha&ppi=72&pview=DEF`;
// const hereTileUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?apikey=${hereMapKey}&lang=th&ppi=100`;

const LeafletMap = (props) => {
  const { children, defaultPosition, zoom, ...otherProps } = props;

  const renderLayers = () => {
    const assignLayers = [
      {
        id: 1,
        name: 'Here Map',
        url: hereTileUrl,
        attribution: '&copy; HERE MAP',
        checked: true,
      },
      {
        id: 2,
        name: 'OpenStreet Map',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        checked: false,
      },
    ];

    return (
      <LayersControl position="topright">
        {assignLayers.map((item) => (
          <BaseLayer key={item.id} checked={item.checked} name={item.name}>
            <TileLayer url={item.url} attribution={item.attribution} />
          </BaseLayer>
        ))}
      </LayersControl>
    );
  };

  return (
    <StyledMap
      center={defaultPosition}
      zoom={zoom}
      maxZoom={20}
      fullscreenControl
      closePopupOnClick={false}
      doubleClickZoom={false}
      {...otherProps}
    >
      {renderLayers()}
      {children}
    </StyledMap>
  );
};

LeafletMap.propTypes = propTypes;
LeafletMap.defaultProps = defaultProps;

export default LeafletMap;
