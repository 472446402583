export const getMode = (mode: 'pickup' | 'delivery' | 'return' | 'pickupAndDelivery') => {
  if (mode === 'pickup') {
    return 'จุดรับสินค้า';
  }

  if (mode === 'delivery') {
    return 'จุดส่งสินค้า';
  }

  if (mode === 'pickupAndDelivery') {
    return 'จุดรับและส่งสินค้า';
  }

  if (mode === 'return') {
    return 'จุดกลับคลัง';
  }

  return mode;
};
