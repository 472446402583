export const colors: {
  [key: string]: {
    backgroundColor: string;
    color: string;
  };
} = {
  red: {
    backgroundColor: '#FFE8E3',
    color: '#CF1322',
  },
  green: {
    backgroundColor: '#DFF5E5',
    color: '#068828',
  },
  orange: {
    backgroundColor: '#FFE4C6',
    color: '#FB9219',
  },
  blue: {
    backgroundColor: '#D0E7F9',
    color: '#1487E2',
  },
  black: {
    backgroundColor: '#DBDBDB',
    color: '#212429',
  },
  gray: {
    backgroundColor: '#F9F9F9',
    color: '#BFBFBF',
  },
};
