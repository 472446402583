import React from 'react';
import PropTypes from 'prop-types';
import StyledTag from './Style';

const propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  textColor: PropTypes.string,
};

const defaultProps = {
  color: '#000000',
  outline: false,
  textColor: '#ffffff',
};

const Tag = (props) => {
  const { children, outline, textColor, color, ...otherProps } = props;
  return (
    <StyledTag outline={outline ? 1 : 0} textColor={textColor} color={color} {...otherProps}>
      {children}
    </StyledTag>
  );
};

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;

export default Tag;
