import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ListWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;

export default Container;
