import React from 'react';
import Control, { ControlProps } from 'react-leaflet-control';
import { AimOutlined } from '@ant-design/icons';
import { Container } from './Styles';

interface LeafletControlCurrentLocationProps {
  position: ControlProps['position'];
  onGetLocation?: (location: { latitude: number; longitude: number }) => void;
}

const LeafletControlCurrentLocation: React.FC<LeafletControlCurrentLocationProps> = (props) => {
  const { position, onGetLocation } = props;

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((location) => {
      if (onGetLocation) {
        onGetLocation({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        });
      }
    });
  };

  return (
    <Control position={position}>
      <Container className="leaflet-bar-current-location leaflet-bar">
        <a
          href="#"
          title="Current Location"
          onClick={(event) => {
            event.preventDefault();
            getCurrentLocation();
          }}
        >
          <AimOutlined />
        </a>
      </Container>
    </Control>
  );
};

export default LeafletControlCurrentLocation;
