import gql from 'graphql-tag';

export interface Notifications {
  _id: string;
  is_read: boolean;
  title: string;
  description: string;
  redirect_url: string | null;
  create_date: string;
  icon_type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
}

export interface NotificationsData {
  notifications: {
    notifications: Notifications[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface NotificationsVars {
  page?: number;
  showData?: number;
}

export default gql`
  query Notifications($showData: Int, $page: Int) {
    notifications(showData: $showData, page: $page) {
      notifications {
        _id
        is_read
        title
        description
        redirect_url
        create_date
        icon_type
      }
      totalPage
      totalDocument
    }
  }
`;
