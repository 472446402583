import React, { useState } from 'react';
import { Row, Col, Card, Typography, Form, Input } from 'antd';
import {
  ModalMapSearchLocation,
  Button,
  SelectSettingProvince,
  SelectSettingDistrictList,
  SelectSettingSubDistrictList,
} from 'shared/components';
import { useFormContext, Controller } from 'react-hook-form';
import MapMarkerAltSolid from 'shared/assets/icon/map-marker-alt-solid.svg';
import Icon from '@ant-design/icons';
import { FormValue } from '../formValue.model';

const { Text } = Typography;

const Forms: React.FC = () => {
  const [isOpenModalMapSearchLocation, setOpenModalMapSearchLocation] = useState<boolean>(false);
  const { errors, control, watch, setValue, trigger } = useFormContext<FormValue>();
  const watchLocation = watch(['pinMap', 'lat', 'lng']);
  const watchProvinceCode = watch('provinceCode');
  const watchDistrictCode = watch('districtCode');
  const watchRemark = watch('remark');
  const watchIsEdit = watch('isEdit');
  const watchIsReadOnly = watch('isReadOnly');
  const isDisabledForm = watchRemark === 'System' && watchIsEdit === true;

  return (
    <div>
      <Controller type="hidden" as={Input} name="customerId" />
      <Controller type="hidden" as={Input} name="isEdit" />
      <Controller type="hidden" as={Input} name="lat" />
      <Controller type="hidden" as={Input} name="lng" />
      <Controller type="hidden" as={Input} name="provinceCode" />
      <Controller type="hidden" as={Input} name="districtCode" />
      <Controller type="hidden" as={Input} name="remark" />

      <div style={{ marginBottom: 10 }}>
        <Text strong>รายละเอียดจุดส่งสินค้า</Text>
      </div>
      <Row gutter={[12, 0]}>
        <Col span={6}>
          <Text strong>
            รหัสลูกค้า<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.arCode && 'error'}
            help={errors.arCode ? errors.arCode.message : undefined}
          >
            <Controller
              disabled={isDisabledForm}
              as={Input}
              control={control}
              name="arCode"
              placeholder="ระบุ"
              readOnly={watchIsReadOnly}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text strong>
            ชื่อลูกค้า<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.arName && 'error'}
            help={errors.arName ? errors.arName.message : undefined}
          >
            <Controller
              disabled={isDisabledForm}
              as={Input}
              control={control}
              name="arName"
              placeholder="ระบุ"
              readOnly={watchIsReadOnly}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text strong>
            เบอร์โทรศัพท์<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.shiptoTel && 'error'}
            help={errors.shiptoTel ? errors.shiptoTel.message : undefined}
          >
            <Controller
              as={Input}
              control={control}
              name="shiptoTel"
              placeholder="08XXXXXXXX"
              disabled={isDisabledForm}
              readOnly={watchIsReadOnly}
            />
          </Form.Item>
        </Col>
        <Col span={6}></Col>
        <Col span={12}>
          <Text strong>
            ที่อยู่จุดส่งสินค้า<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.transportAddress && 'error'}
            help={errors.transportAddress ? errors.transportAddress.message : undefined}
          >
            <Controller
              as={Input}
              control={control}
              name="transportAddress"
              placeholder="ระบุ"
              disabled={isDisabledForm}
              readOnly={watchIsReadOnly}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text strong>
            ตำแหน่งบนแผนที่<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.pinMap && 'error'}
            help={errors.pinMap ? errors.pinMap.message : undefined}
          >
            <Controller
              control={control}
              name="pinMap"
              disabled
              render={({ value }) => (
                <Input.Group compact>
                  <Input
                    placeholder="ระบุ"
                    readOnly
                    style={{ width: 'calc(100% - 102px)' }}
                    value={value}
                    onClick={() => !watchIsReadOnly && setOpenModalMapSearchLocation(true)}
                  />
                  <Button
                    disabled={watchIsReadOnly}
                    onClick={() => setOpenModalMapSearchLocation(true)}
                  >
                    <Icon component={MapMarkerAltSolid} /> ระบุที่ตั้ง
                  </Button>
                </Input.Group>
              )}
            />
          </Form.Item>
          <ModalMapSearchLocation
            value={{
              address: watchLocation.pinMap,
              latitude: watchLocation.lat,
              longitude: watchLocation.lng,
            }}
            visible={isOpenModalMapSearchLocation}
            onCancel={() => setOpenModalMapSearchLocation(false)}
            onSubmit={(values) => {
              setValue('pinMap', values.address);
              setValue('lat', values.latitude);
              setValue('lng', values.longitude);
              trigger(['pinMap', 'lat', 'lng']);
              setOpenModalMapSearchLocation(false);
            }}
          />
        </Col>
        <Col span={6}>
          <Text strong>
            จังหวัด<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.province && 'error'}
            help={errors.province ? errors.province.message : undefined}
          >
            <Controller
              control={control}
              name="province"
              render={({ onChange, value }) => (
                <SelectSettingProvince
                  value={value}
                  disabled={isDisabledForm || watchIsReadOnly}
                  onChange={(values) => {
                    onChange(values?.provinceName);
                    setValue('provinceCode', values?.provinceCode);
                    setValue('district', '');
                    setValue('districtCode', '');
                    setValue('subDistrict', '');
                    setValue('postcode', '');
                  }}
                />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text strong>
            อำเภอ/เขต<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.district && 'error'}
            help={errors.district ? errors.district.message : undefined}
          >
            <Controller
              control={control}
              name="district"
              render={({ onChange, value }) => (
                <SelectSettingDistrictList
                  value={value}
                  disabled={isDisabledForm || watchIsReadOnly}
                  onChange={(values) => {
                    onChange(values?.districtName);
                    setValue('districtCode', values?.districtCode);
                    setValue('subDistrict', '');
                    setValue('postcode', '');
                  }}
                  provinceCode={watchProvinceCode}
                />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text strong>
            ตำบล/แขวง<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.subDistrict && 'error'}
            help={errors.subDistrict ? errors.subDistrict.message : undefined}
          >
            <Controller
              control={control}
              name="subDistrict"
              render={({ onChange, value }) => (
                <SelectSettingSubDistrictList
                  value={value}
                  disabled={isDisabledForm || watchIsReadOnly}
                  onChange={(values) => {
                    onChange(values?.subDistrictName);
                    setValue('postcode', values?.postcode);
                  }}
                  provinceCode={watchProvinceCode}
                  districtCode={watchDistrictCode}
                />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text strong>
            รหัสไปรษณีย์<Text type="danger">*</Text>
          </Text>
          <Form.Item
            validateStatus={errors.postcode && 'error'}
            help={errors.postcode ? errors.postcode.message : undefined}
          >
            <Controller
              readOnly={watchIsReadOnly}
              as={Input}
              control={control}
              name="postcode"
              placeholder="ระบุ"
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Forms;
