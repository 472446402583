import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { getStoredAuthToken } from 'shared/utils/authToken';
import baseUrl from './baseUrl';

const httpLink = createUploadLink({
  uri: `${baseUrl}`,
});

const authContextLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  if (context.authorization) {
    operation.setContext({
      headers: {
        authorization: context.authorization,
      },
    });
  }
  return forward(operation);
});

const authLink = setContext(async (_, { headers }) => {
  const token = getStoredAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const cache = new InMemoryCache({
  dataIdFromObject: () => null,
});

const link = ApolloLink.from([authLink, authContextLink, httpLink]);

export default new ApolloClient({
  cache,
  link,
});
