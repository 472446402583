import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string,
  labelCopy: PropTypes.string,
  labelCopied: PropTypes.string,
};

const defaultProps = {
  text: '',
  labelCopy: 'คัดลอก',
  labelCopied: 'คัดลอกแล้ว',
};

const ButtonCopy = (props) => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { text, labelCopy, labelCopied, ...otherProps } = props;

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  };

  return (
    <CopyToClipboard text={text}>
      <Tooltip title={isLinkCopied ? labelCopied : labelCopy}>
        <Button onClick={handleLinkCopy} icon={<CopyOutlined />} {...otherProps} />
      </Tooltip>
    </CopyToClipboard>
  );
};

ButtonCopy.propTypes = propTypes;
ButtonCopy.defaultProps = defaultProps;

export default ButtonCopy;
