import styled from 'styled-components';
import { Button } from 'antd';
import { font, color, mixin } from 'shared/utils/styles';

export const StyledHeader = styled.div`
  border-bottom: 1px solid ${color.border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: ${color.white};
  height: 60px;

  ${(props) =>
    props.theme === 'red' &&
    `
  background-color: ${color.primary};
  color: white;
  `}

  ${(props) =>
    props.theme === 'dark' &&
    `
  background-color: #000000;
  color: white;
  `}
`;

export const StyledTrigger = styled.div`
  transition: color 0.3s;
  ${font.size(20)}
  ${mixin.clickable}
    
  &:hover {
    color: ${color.primary};
  }

  ${(props) =>
    props.theme === 'red' &&
    `
    &:hover {
      color: ${color.white};
    }
  `}

  ${(props) =>
    props.theme === 'dark' &&
    `
    &:hover {
      color: ${color.white};
    }
  `}
`;

export const TextHeader = styled.div`
  padding-left: 20px;
  ${font.size(20)}
  ${font.bold}

  @media (max-width: 480px) {
    font-size: medium;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  flex: 1;
`;

export const RightContainer = styled.div`
  .ant-btn-link {
    color: ${color.black};
  }

  @media (max-width: 450px) {
    & Button {
      display: none;
    }
  }

  ${(props) =>
    props.theme === 'red' &&
    `
    & {
      color: ${color.white};
    }
  `}

  ${(props) =>
    props.theme === 'dark' &&
    `
    & {
      color: ${color.white};
    }
  `}
`;

export const StyledButton = styled(Button)`
  ${(props) =>
    props.theme === 'red' &&
    `
    & {
      color: ${color.white} !important;
    }
  `}

  ${(props) =>
    props.theme === 'dark' &&
    `
    & {
      color: ${color.white} !important;
    }
  `}
`;
