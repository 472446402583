/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Row, Col, Form, Input, Typography, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'shared/components';
import {
  MailOutlined,
  ArrowLeftOutlined,
  RedoOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import Logo from 'shared/assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NOTI_CHANGE_PASSWORD_MUTATION, {
  NotiChangePasswordData,
  NotiChangePasswordVars,
} from 'shared/graphql/mutation/notiChangePassword';
import useMessageError from 'shared/hooks/useMessageError';
import { StyledLogo, TextHeader, StyledCard } from './Styles';
import { FormValues } from './formValues.model';
import initialValue from './initialValue';
import validateSchema from './validateSchema';

const { Text } = Typography;

const ForgotPassword: React.FC = () => {
  const { getMessageError } = useMessageError();
  const [isSendEmailSuccess, setSendEmailSuccess] = useState<boolean>(false);
  const { handleSubmit, control, errors, watch } = useForm<FormValues>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });
  const watchEmail = watch('email');

  const [notiChangePassword, { loading }] = useMutation<
    NotiChangePasswordData,
    NotiChangePasswordVars
  >(NOTI_CHANGE_PASSWORD_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.notiChangePassword.email) {
        setSendEmailSuccess(true);
      }
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
  });
  const onSubmit = handleSubmit((values) => {
    notiChangePassword({
      variables: {
        email: values.email,
      },
    });
  });

  return (
    <Row>
      <Col
        xs={{ span: 24, offset: 0 }}
        md={{ span: 18, offset: 3 }}
        lg={{ span: 14, offset: 5 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 8, offset: 8 }}
      >
        <StyledCard>
          <StyledLogo>
            <Logo />
          </StyledLogo>
          <TextHeader>{process.env.REACT_APP_NAME}</TextHeader>

          {!isSendEmailSuccess && (
            <>
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginTop: 20 }}>
                  <Text strong>ลืมรหัสผ่าน</Text>
                </div>
                <div style={{ marginBottom: 20, fontSize: 12 }}>
                  <Text>กรอกอีเมลของคุณ และคุณจะได้รับการเปลี่ยนรหัสผ่านทางอีเมล</Text>
                </div>
              </div>
              <Form.Item validateStatus={errors.email && 'error'} style={{ marginBottom: 0 }}>
                <Controller
                  as={Input}
                  control={control}
                  prefix={<MailOutlined />}
                  placeholder="กรอกอีเมลของคุณ"
                  name="email"
                />
              </Form.Item>

              <Button
                type="success"
                block
                style={{ marginTop: 20 }}
                loading={loading}
                disabled={!watchEmail}
                onClick={onSubmit}
              >
                ยืนยันอีเมล
              </Button>

              <Row justify="center">
                <Col style={{ marginTop: 40 }}>
                  <Link to="/login">
                    <ArrowLeftOutlined style={{ verticalAlign: -3 }} /> กลับหน้าเข้าสู่ระบบ
                  </Link>
                </Col>
              </Row>
            </>
          )}

          {isSendEmailSuccess && (
            <>
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <div>
                  <CheckCircleFilled style={{ color: '#1F8828', fontSize: 24, marginBottom: 10 }} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Text strong>ส่งอีเมลสำเร็จ</Text>
                </div>
                <div style={{ marginBottom: 20, fontSize: 12 }}>
                  <Text>เราได้ส่งการเปลี่ยนรหัสผ่านใหม่ไปยังอีเมลของคุณ</Text>
                </div>
                <div style={{ marginBottom: 20, fontSize: 12 }}>
                  <Text type="secondary">
                    กรุณาตรวจสอบอีเมลของคุณ
                    <br />
                    เพื่อสร้างรหัสผ่านใหม่จากลิงค์ในอีเมล
                  </Text>
                </div>
              </div>

              <Button
                type="link"
                block
                style={{ marginTop: 20 }}
                onClick={() => setSendEmailSuccess(false)}
              >
                <RedoOutlined style={{ verticalAlign: -3 }} /> ส่งอีกครั้ง
              </Button>
            </>
          )}
        </StyledCard>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
