import gql from 'graphql-tag';

export interface UpdateUserAccountData {
  updateUserAccount: {
    _id: string;
    username: string;
    email: {
      email_account: string;
    };
    phone: {
      number: string;
    };
    active_status: boolean;
    permission: string[];
    last_update: string;
    create_date: string;
  };
}

export interface UpdateUserAccountVars {
  _id: string;
  active_status?: boolean;
  password?: string;
  email?: {
    email_account: string;
  };
  phone?: {
    number?: string;
    country_code?: string;
  };
  picture?: string;
  delete?: boolean;
  wait_to_read_noti?: number;
  profile_permission?: string | null;
  permission_account?: {
    orderList?: string[];
    bookingList?: string[];
    jobList?: string[];
    renewalList?: string[];
    vendorList?: string[];
    reportList?: string[];
    setting?: string[];
  };
}

export default gql`
  mutation UpdateUserAccount(
    $_id: ID!
    $active_status: Boolean
    $password: String
    $email: inputEmail
    $phone: inputPhone
    $picture: String
    $delete: Boolean
    $wait_to_read_noti: Int
    $profile_permission: ID
    $permission_account: inputPermissionAccount
  ) {
    updateUserAccount(
      _id: $_id
      updateUserAccount: {
        active_status: $active_status
        password: $password
        email: $email
        phone: $phone
        picture: $picture
        delete: $delete
        wait_to_read_noti: $wait_to_read_noti
        profile_permission: $profile_permission
        permission_account: $permission_account
      }
    ) {
      _id
      username
      email {
        email_account
      }
      phone {
        number
      }
      active_status
      permission
      last_update
      create_date
    }
  }
`;
