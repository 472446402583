import gql from 'graphql-tag';

export interface StockSiteGroupData {
  stockSiteGroup: {
    _id: string;
    stockSite: string;
  }[];
}

export default gql`
  query StockSiteGroup {
    stockSiteGroup {
      _id
      stockSite
    }
  }
`;
