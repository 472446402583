import gql from 'graphql-tag';

export interface CheckAuthChangePasswordData {
  checkAuthChangePassword: {
    status: number;
    message_error: string | null;
  };
}

export interface CheckAuthChangePasswordVars {
  token: string;
}

export default gql`
  query CheckAuthChangePassword($token: String!) {
    checkAuthChangePassword(token: $token) {
      status
      message_error
    }
  }
`;
