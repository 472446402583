import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin as SpinAntd } from 'antd';

const antIcon = <LoadingOutlined spin />;

const Spin = (props) => {
  const { children, ...otherProps } = props;
  return (
    <SpinAntd indicator={antIcon} {...otherProps}>
      {children}
    </SpinAntd>
  );
};

export default Spin;
