import gql from 'graphql-tag';

export interface SettingImprovementsList {
  _id: string;
  name: string;
  code: string;
  amount: number;
  status_edit: boolean;
  approve_all: boolean;
  create_date: string;
  last_update: string;
}

export interface SettingImprovementsListData {
  settingImprovements: {
    settingImprovements: SettingImprovementsList[];
    totalDocument: number;
    totalPage: number;
  };
}

export interface SettingImprovementsListVars {
  params?: {
    name?: string;
    code?: string;
  };
  page?: number;
  showData?: number;
  sort?: {
    order_by?: string;
    order_type?: '1' | '-1';
  };
}

export default gql`
  query SettingImprovements(
    $params: paramsSettingImprovement
    $sort: sort
    $page: Int
    $showData: Int
  ) {
    settingImprovements(params: $params, sort: $sort, page: $page, showData: $showData) {
      settingImprovements {
        _id
        name
        code
        amount
        status_edit
        approve_all
        create_date
        last_update
      }
      totalPage
      totalDocument
    }
  }
`;
