import styled from 'styled-components';
import { font, color } from 'shared/utils/styles';

type PinProps = {
  type: 'pickup' | 'delivery' | 'return' | 'pickupAndDelivery';
};

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 100%;
      top: 30px;
      left: 8px;
      border-right: 1px dashed ${color.border};
    }
  }
`;

export const DetailContainer = styled.div`
  flex: 1;
  padding-right: 20px;
  padding-bottom: 10px;
`;

export const Pin = styled.div<PinProps>`
  width: 20px;
  margin-right: 20px;
  ${font.size(18)};
  color: ${color.black};
  padding-top: 3px;

  ${(props) =>
    props.type === 'pickup' &&
    `
    color: ${color.black};
  `}

  ${(props) =>
    props.type === 'delivery' &&
    `
    color: ${color.primary};
  `}
`;
