import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import DRIVER_TRUCKS_QUERY, {
  DriverTrucksData,
  DriverTrucksVars,
  Truck,
} from 'shared/graphql/query/driverTrucks';
import { Select, message, Empty } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectDriverProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: Truck) => void;
  placeholder?: string;
  setValue: any;
};
//sheet 11
const { Option } = Select;

const SelectDriver: React.FC<SelectDriverProps> = (props) => {
  const { value, disabled, onChange, placeholder, setValue } = props;
  const [search, setSearch] = useState<string>('');
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<DriverTrucksData, DriverTrucksVars>(
    DRIVER_TRUCKS_QUERY,
    {
      variables: {
        params: {
          license_plate: search,
          active_status: true,
        },
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.driverTrucks) {
      setTrucks(data.driverTrucks.trucks);
    }
  }, [data?.driverTrucks, loading, error]);

  const options: ReactNode[] = trucks.map((item) => (
    <Option key={item._id} value={item._id}>{`${item.license_plate}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = trucks.find((item) => item._id === valueInput);
    if (onChange && findValue) {
      // if (findValue._id === 'ALL') {
      //   setValue('driverId', 'ALL');
      // }

      onChange(findValue);
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder || 'เลือก'}
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectDriver;
