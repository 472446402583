/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Typography, message } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, PageLoader, PageResultError } from 'shared/components';
import { LockOutlined, CheckCircleFilled } from '@ant-design/icons';
import Logo from 'shared/assets/images/logo.svg';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useMessageError from 'shared/hooks/useMessageError';
import UPDATE_CHANGE_PASSWORD_MUTATION, {
  UpdateChangePasswordData,
  UpdateChangePasswordVars,
} from 'shared/graphql/mutation/updateChangePassword';
import CHECK_AUTH_CHANGE_PASSWORD_QUERY, {
  CheckAuthChangePasswordData,
  CheckAuthChangePasswordVars,
} from 'shared/graphql/query/checkAuthChangePassword';
import { RouteComponentProps, Link } from 'react-router-dom';
import { StyledLogo, TextHeader, StyledCard } from './Styles';
import { FormValues } from './formValues.model';
import initialValue from './initialValue';
import validateSchema from './validateSchema';

const { Text } = Typography;

const CreatePassword: React.FC<RouteComponentProps> = (props) => {
  const params = new URLSearchParams(props.location.search);
  const token = params.get('token') || '';
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const { getMessageError } = useMessageError();

  const { handleSubmit, control, errors, reset } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const { data, loading, error } = useQuery<
    CheckAuthChangePasswordData,
    CheckAuthChangePasswordVars
  >(CHECK_AUTH_CHANGE_PASSWORD_QUERY, {
    variables: {
      token,
    },
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      message.error(getMessageError(err));
    },
  });

  useEffect(() => {
    if (!loading && !error && data?.checkAuthChangePassword) {
      const { message_error } = data.checkAuthChangePassword;
      if (!message_error) {
        reset({
          ...initialValue,
          token,
        });
      }
    }
  }, [loading, error, data?.checkAuthChangePassword]);

  const [updateChangePassword, { loading: updateChangePasswordLoading }] = useMutation<
    UpdateChangePasswordData,
    UpdateChangePasswordVars
  >(UPDATE_CHANGE_PASSWORD_MUTATION, {
    update: (_cache, { data: updateChangePasswordData }) => {
      if (updateChangePasswordData?.updateChangePassword._id) {
        setSuccess(true);
      }
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
  });

  const onSubmit = handleSubmit((values) => {
    updateChangePassword({
      variables: {
        token: values.token,
        password: values.password,
      },
    });
  });

  let content = <PageLoader />;
  if (data?.checkAuthChangePassword) {
    const { message_error } = data.checkAuthChangePassword;

    content = message_error ? (
      <PageResultError message={message_error || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'} />
    ) : (
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 14, offset: 5 }}
          xl={{ span: 10, offset: 7 }}
          xxl={{ span: 8, offset: 8 }}
        >
          <StyledCard>
            <StyledLogo>
              <Logo />
            </StyledLogo>
            <TextHeader>{process.env.REACT_APP_NAME}</TextHeader>

            {!isSuccess && (
              <>
                <div style={{ marginTop: 20, marginBottom: 10, textAlign: 'center' }}>
                  <Text strong>สร้างรหัสผ่านใหม่</Text>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <Text>กรอกรหัสผ่านใหม่ของคุณ</Text>
                  <br />
                  <Text style={{ fontSize: 12 }} type="secondary">
                    คุณสามารถใช้ตัวอักษร, ตัวเลข และสัญลักษณ์จำนวน 6-20 ตัวอักษร
                  </Text>
                </div>

                <Controller as={Input} control={control} name="token" type="hidden" />

                <Row>
                  <Col span={24}>
                    <Form.Item
                      validateStatus={errors.password && 'error'}
                      help={errors.password?.message}
                    >
                      <Controller
                        as={Input.Password}
                        control={control}
                        prefix={<LockOutlined />}
                        placeholder="รหัสผ่าน"
                        name="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      validateStatus={errors.passwordConfirmation && 'error'}
                      help={errors.passwordConfirmation?.message}
                    >
                      <Controller
                        as={Input.Password}
                        control={control}
                        prefix={<LockOutlined />}
                        placeholder="ยืนยันรหัสผ่านอีกครั้ง"
                        name="passwordConfirmation"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Button
                  type="success"
                  block
                  style={{ marginTop: 20 }}
                  loading={updateChangePasswordLoading}
                  onClick={onSubmit}
                >
                  ยืนยันรหัสผ่าน
                </Button>
              </>
            )}

            {isSuccess && (
              <>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <div>
                    <CheckCircleFilled
                      style={{ color: '#1F8828', fontSize: 24, marginBottom: 10 }}
                    />
                  </div>
                  <div style={{ textAlign: 'center', marginBottom: 20 }}>
                    <Text strong>สร้างรหัสผ่านใหม่สำเร็จ</Text>
                  </div>
                  <div style={{ marginBottom: 20, fontSize: 12 }}>
                    <Text>
                      สร้างรหัสผ่านใหม่ของคุณสำเร็จแล้ว
                      <br />
                      คุณสามารถเข้าสู่ระบบได้จากปุ่มด้านล่าง
                    </Text>
                  </div>
                </div>

                <Link to="/login">
                  <Button type="success" block style={{ marginTop: 20 }}>
                    เข้าสู่ระบบ
                  </Button>
                </Link>
              </>
            )}
          </StyledCard>
        </Col>
      </Row>
    );
  }

  if (error) {
    const textError =
      error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message;
    return <PageResultError message={textError} />;
  }

  return content;
};

export default CreatePassword;
