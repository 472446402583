import gql from 'graphql-tag';
import { RenewalStatus } from 'shared/model/renewalStatus.model';

export interface Commodity {
  _id: string;
  product_id: string;
  product_name: string;
  product_qty: string;
  product_unit: string;
  product_code: string;
}

export interface JobData {
  job: {
    _id: string;
    job_number: string;
    job_status: number;
    driver_status: number;
    pickup_date: string;
    last_update: string;
    complete_date: string;
    driver_accept_date: string;
    remark: string;
    total_docNo: string[];
    admin_assign: {
      username: string;
    };
    job_address: {
      _id: string;
      mode: 'pickup' | 'delivery' | 'return' | 'pickupAndDelivery';
      address: string;
      lat: string;
      lng: string;
      dock: string;
      remark: string;
      customer_name: string;
      contact_name: string;
      contact_tel: string;
      commodity: Commodity[];
      activity: {
        contact: {
          time_stamp: string;
        };
        proof_picture: {
          picture: {
            _id: string;
            picture_type: string;
            picture_path: string;
          }[];
          time_stamp: string;
        };
        proof_signature: {
          picture_path: string;
          time_stamp: string;
        };
        problem: {
          _id: string;
          product_id: string;
          product_code: string;
          product_name: string;
          product_unit: string;
          qty: string;
          problem_type: string;
          problem_detail: string;
          picture: {
            picture_type: string;
            picture_path: string;
          }[];
          lat: string;
          lng: string;
          time_stamp: string;
          renewal_status: RenewalStatus;
        }[];
      };
      tracking: {
        tracking_number: string;
        url: string;
      };
    }[];
    truck: {
      _id: string;
      license_plate: string;
      province: string;
    };
    driver: {
      _id: string;
      full_name: string;
      picture: string;
      phone: {
        country_code: string;
        number: string;
      };
    };
    invoice_status: number;
  };
}

export interface JobVars {
  _id: string;
}

export default gql`
  query Job($_id: ID!) {
    job(_id: $_id) {
      _id
      job_number
      job_status
      driver_status
      pickup_date
      last_update
      complete_date
      driver_accept_date
      remark
      total_docNo
      admin_assign {
        username
      }
      booking {
        booking_sys_number
        total_docNo
        booking_address {
          mode
          lat
          lng
          dock
          pick_up_point_name
          pick_up_address
          pin_map
          pick_up_contact_name
          pick_up_contact_tel
          arName
          shipToTel
          commodity {
            docNo
            deliveryDate
            arName
            remark
            rowOrder {
              product_id
              product_code
              product_name
              product_qty
              product_qtybalance
              product_unit
              product_itemtext
              product_netweight
              product_width
              product_length
              product_height
              product_sale_price
              product_installation
            }
          }
          remark
          total_netweight
        }
      }
      vendor {
        full_name
        phone {
          country_code
          number
        }
      }
      company {
        company_name
        branch_name

        phone {
          country_code
          number
        }
      }
      truck_type {
        _id
        truck_type_name
      }
      truck {
        _id
        license_plate
        province
      }
      job_address {
        _id
        mode
        address
        lat
        lng
        dock
        remark
        customer_name
        contact_name
        contact_tel
        commodity {
          _id
          product_id
          product_name
          product_qty
          product_unit
          product_code
        }
        activity {
          contact {
            time_stamp
          }
          proof_picture {
            picture {
              _id
              picture_type
              picture_path
            }
            time_stamp
          }
          proof_signature {
            picture_path
            time_stamp
          }
          problem {
            _id
            product_id
            product_code
            product_name
            product_unit
            qty
            problem_type
            problem_detail
            picture {
              picture_type
              picture_path
            }
            lat
            lng
            time_stamp
            renewal_status
          }
        }
        tracking {
          tracking_number
          url
        }
      }
      driver {
        _id
        full_name
        picture
        phone {
          country_code
          number
        }
      }
      worker {
        _id
        full_name
        phone {
          phone_type
          country_code
          number
        }
      }
      working_status
      invoice_status
    }
  }
`;
