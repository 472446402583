import React from 'react';
import PropTypes from 'prop-types';
import StyledIcon from './Styles';

const propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

const defaultProps = {
  size: undefined,
};

const icon = ({ type, name, ...iconProps }) => (
  <StyledIcon {...iconProps} className={`${type} fa-${name}`} />
);

icon.propTypes = propTypes;
icon.defaultProps = defaultProps;

export default icon;
