import styled from 'styled-components';
import { PageHeader } from 'antd';
import { color, font } from 'shared/utils/styles';

const StyledPageHeader = styled(PageHeader)`
  padding: 5px 20px;
  background-color: ${color.white};
  ${font.bold}

  & .ant-page-header-back-button {
    color: ${color.primary};
  }
`;

export default StyledPageHeader;
