import React, { useContext } from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { Menu } from 'antd';
import classNames from 'classnames';
import Icon from '@ant-design/icons';

import InboxInLight from 'shared/assets/icon/inbox-in-light.svg';
import DollyFlatbedLight from 'shared/assets/icon/dolly-flatbed-light.svg';
import MapMarkerAltLight from 'shared/assets/icon/map-marker-alt-light.svg';
import ClipboardList from './assets/clipboard-list.svg';
import BellLight from 'shared/assets/icon/bell-light.svg';
import { AuthContext } from 'shared/context/AuthContext';
import IconFileAlt from './assets/file-alt.svg';
import Truck from './assets/truck.svg';
import Cog from './assets/cog.svg';
import AddressCard from './assets/address-card.svg';
import HomeAlt from './assets/home-alt.svg';
import UserCog from './assets/user-cog.svg';
import UserTag from './assets/user-tag.svg';
import UserLight from './assets/user-light.svg';
import GasPump from './assets/gas.svg';
import StackLight from './assets/stack-light.svg';
import Money from './assets/money.svg';

const LinkMenu = (props) => {
  const { match, location } = props;
  const { isHasPermission, isCheckPermission, state } = useContext(AuthContext);

  const routes = [
    {
      name: 'รายการสินค้า',
      link: '/product',
      icon: ClipboardList,
      typeUser: ['admin'],
      isHasPermission: isHasPermission('orderList'),
    },
    {
      name: 'รายการใบงาน',
      link: '/booking',
      icon: ClipboardList,
      typeUser: ['agent', 'admin', 'superAdmin'],
      subMenu: [
        {
          name: 'ใบงาน/ติดต่อลูกค้า',
          link: '/booking/contact-bookings',
          icon: ClipboardList,
          typeUser: ['admin', 'superAdmin'],
        },
        {
          name: 'ใบงาน/ส่งงาน',
          link: '/booking/list',
          icon: ClipboardList,
          typeUser: ['agent', 'admin', 'superAdmin'],
        },
      ],
    },
    {
      name: 'รายการงานขนส่ง',
      link: '/job',
      icon: Truck,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
    {
      name: 'รายการใบแจ้งหนี้',
      link: '/invoice',
      icon: Money,
      typeUser: ['admin', 'agent'],
    },
    {
      name: 'ติดตามรถขนส่ง',
      link: '/tracking-transporter',
      icon: MapMarkerAltLight,
      typeUser: ['agent'],
    },
    {
      name: 'รายชื่อสาขา',
      link: '/branch',
      icon: UserTag,
      typeUser: ['superAdmin'],
    },
    {
      name: 'รายการเก็บงาน',
      link: '/renewal',
      icon: DollyFlatbedLight,
      typeUser: ['admin', 'superAdmin'],
    },
    {
      name: 'รายชื่อบริษัทขนส่ง',
      link: '/agent',
      icon: AddressCard,
      typeUser: ['admin', 'superAdmin'],
    },
    {
      name: 'รายงานสรุปผล',
      link: '/report',
      icon: IconFileAlt,
      typeUser: ['agent', 'admin', 'superAdmin'],
    },
    {
      name: 'ตั้งค่า',
      link: '/setting',
      icon: Cog,
      typeUser: ['agent', 'admin', 'superAdmin'],
      subMenu: [
        {
          name: 'รถขนส่ง',
          link: '/setting/truck',
          icon: Truck,
          typeUser: ['agent'],
        },
        {
          name: 'พนักงานขับรถ/เด็กติดรถ',
          link: '/setting/driver',
          icon: UserTag,
          typeUser: ['agent'],
        },
        {
          name: 'ข้อมูลคลัง',
          link: '/setting/warehouse',
          icon: InboxInLight,
          typeUser: ['admin'],
        },
        {
          name: 'ข้อมูลลูกค้า',
          link: '/setting/customer',
          icon: UserLight,
          typeUser: ['admin'],
        },
        {
          name: 'ข้อมูลบริษัท',
          link: '/setting/corporate',
          icon: HomeAlt,
          typeUser: ['admin'],
          isHasPermission: isCheckPermission('setting', 'COMPANY'),
        },
        {
          name: 'ข้อมูลบริษัท',
          link: '/setting/corporate',
          icon: HomeAlt,
          typeUser: ['agent'],
        },
        {
          name: 'ผู้ใช้งาน',
          link: '/setting/user',
          icon: UserCog,
          typeUser: ['superAdmin'],
        },
        {
          name: 'การรับข้อมูล DO',
          link: '/setting/planroute',
          icon: UserCog,
          typeUser: ['superAdmin'],
        },
        {
          name: 'ตั้งค่า Route Code',
          link: '/setting/routecode',
          icon: StackLight,
          typeUser: ['superAdmin'],
        },
        {
          name: 'การแจ้งเตือน',
          link: '/setting/notifications',
          icon: BellLight,
          typeUser: ['admin'],
          isHasPermission: isCheckPermission('setting', 'DETAIL_NOTI'),
        },
        {
          name: 'การแจ้งเตือน',
          link: '/setting/notifications',
          icon: BellLight,
          typeUser: ['agent'],
        },
        {
          name: 'ตั้งค่าน้ำมัน',
          link: '/setting/gas',
          icon: GasPump,
          typeUser: ['admin', 'superAdmin'],
        },

        {
          name: 'ตั้งค่าปรับปรุง',
          link: '/setting/category',
          icon: StackLight,
          typeUser: ['superAdmin'],
        },
      ],
    },
  ]
    .filter((route) => {
      return route.typeUser.includes(state.user.typeUser);
    })
    .filter((route) => route.isHasPermission || route.isHasPermission === undefined)
    .map((route) => ({
      ...route,
      subMenu:
        route.subMenu &&
        route.subMenu
          .filter((subMenuItem) => subMenuItem.typeUser.includes(state.user.typeUser))
          .filter(
            (subMenuItem) =>
              subMenuItem.isHasPermission || subMenuItem.isHasPermission === undefined,
          ),
    }));

  const checkLinkActiveClass = (path) => {
    return !!matchPath(location.pathname, `${path}`);
  };

  const menuList = routes.map((item) => {
    if (item.subMenu && item.subMenu.length >= 1) {
      return (
        <Menu.SubMenu key={`${item.link}`} icon={<Icon component={item.icon} />} title={item.name}>
          {item.subMenu.map((subMenu) => (
            <Menu.Item
              style={{ paddingLeft: '40px' }}
              key={subMenu.link}
              className={classNames({
                'ant-menu-item-selected': checkLinkActiveClass(subMenu.link),
              })}
            >
              <Link to={subMenu.link}>
                <Icon component={subMenu.icon} />
                <span>{subMenu.name}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item key={item.link}>
        <Link to={item.link}>
          <Icon component={item.icon} />
          <span>{item.name}</span>
        </Link>
      </Menu.Item>
    );
  });

  return (
    <Menu
      mode="inline"
      style={{
        borderRight: 0,
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      defaultSelectedKeys={[match.path]}
      defaultOpenKeys={[match.path]}
    >
      {menuList}
    </Menu>
  );
};

export default withRouter(LinkMenu);
