import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import DRIVERS_LIST_QUERY, { Driver, DriversData, DriversVars } from 'shared/graphql/query/drivers';
import { Select, message, Empty } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectDriverProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: Driver) => void;
  placeholder?: string;
  setValue: any;
};

const { Option } = Select;
//sheet 11
const SelectDriver: React.FC<SelectDriverProps> = (props) => {
  const { value, disabled, onChange, placeholder, setValue } = props;
  const [search, setSearch] = useState<string>('');
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<DriversData, DriversVars>(DRIVERS_LIST_QUERY, {
    variables: {
      params: {
        full_name: search,
        active_status: true,
      },
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    console.log('DATA', data);
    if (!loading && !error && data?.drivers) {
      setDrivers([{ _id: 'ทั้งหมด', full_name: 'ทั้งหมด' } as any, ...data.drivers.drivers]);
    }
  }, [data?.drivers, loading, error]);

  const options: ReactNode[] = drivers.map((item) => (
    <Option key={item._id} value={item._id}>{`${item.full_name}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = drivers.find((item) => item._id === valueInput);

    if (onChange && findValue) {
      if (findValue._id === 'ทั้งหมด') {
        setValue('truckId', 'ทั้งหมด');
      } else {
        setValue('truckId', '');
      }

      onChange(findValue);
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder || 'เลือก'}
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectDriver;
