import React from 'react';
import { message } from 'antd';
import { Button, TextModalHeader } from 'shared/components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/react-hooks';
import CREATE_CUSTOMER_MUTATION, {
  CreateCustomerData,
  CreateCustomerVars,
} from 'shared/graphql/mutation/createCustomer';
import useMessageError from 'shared/hooks/useMessageError';
import validateSchema from '../validateSchema';
import { FormValue } from '../formValue.model';
import Forms from '../Forms';
import initialValue from '../initialValue';
import { StyledModal } from './Styles';

type ModalCreateCustomerProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalCreateCustomer: React.FC<ModalCreateCustomerProps> = ({ visible, onCancel }) => {
  const { getMessageError } = useMessageError();
  const [createCustomer, { loading }] = useMutation<CreateCustomerData, CreateCustomerVars>(
    CREATE_CUSTOMER_MUTATION,
    {
      update: (_cache, { data }) => {
        if (data?.createCustomer._id) {
          message.success('เพิ่มลูกค้าแล้ว');
          onCancel();
        }
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      refetchQueries: ['CustomerList'],
    },
  );
  const methods = useForm<FormValue>({
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((values) => {
    createCustomer({
      variables: {
        ar_code: values.arCode,
        ar_name: values.arName,
        lat: values.lat,
        lng: values.lng,
        transport_address: values.transportAddress,
        pin_map: values.pinMap,
        shipto_tel: values.shiptoTel,
        sub_district: values.subDistrict,
        district: values.district,
        province: values.province,
        postcode: values.postcode,
        district_code: values.districtCode,
        province_code: values.provinceCode,
      },
    });
  });

  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="custom-modal"
      title={
        <TextModalHeader
          title="เพิ่มข้อมูลลูกค้า"
          subTitle="กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนการกดบันทึก"
        />
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          ปิด
        </Button>,
        <Button
          key="ok"
          disabled={loading}
          loading={loading}
          type="success"
          onClick={() => onSubmit()}
        >
          บันทึก
        </Button>,
      ]}
      destroyOnClose
    >
      <FormProvider {...methods}>
        <Forms />
      </FormProvider>
    </StyledModal>
  );
};

export default ModalCreateCustomer;
