import React from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import StyledPageLoader from './Styles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const pageLoader = () => {
  return (
    <StyledPageLoader>
      <Spin indicator={antIcon} />
    </StyledPageLoader>
  );
};

export default withRouter(pageLoader);
