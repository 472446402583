import gql from 'graphql-tag';
// list

export interface GeneratePDFRequisitionListsData {
  generatePDFRequisitionLists: {
    path: string[];
  };
}

export interface GeneratePDFRequisitionListsVars {
  soNo: string[];
}

export default gql`
  mutation generatePDFRequisitionLists($soNo: [String!]) {
    generatePDFRequisitionLists(soNo: $soNo) {
      path
      __typename
    }
  }
`;
