import gql from 'graphql-tag';

export interface NotiChangePasswordData {
  notiChangePassword: {
    email: string;
  };
}
export interface NotiChangePasswordVars {
  email: string;
}

export default gql`
  mutation NotiChangePassword($email: String!) {
    notiChangePassword(email: $email) {
      email
    }
  }
`;
