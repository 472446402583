import styled from 'styled-components';
import { Card } from 'antd';

const StyledCard = styled(Card)`
  .ant-card-head-wrapper {
    @media (max-width: 500px) {
      display: table-caption;

      .ant-card-extra {
        float: left;
      }

      h3.ant-typography,
      .ant-typography h3 {
        font-size: 18px;
      }
    }
  }
`;

export default StyledCard;
