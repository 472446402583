import gql from 'graphql-tag';

export type RouteCodeList = string[];

export interface QueryFilterRouteCodeData {
  queryFilterRouteCode: {
    routeCodeList: RouteCodeList;
  };
}

export default gql`
  query QueryFilterRouteCode {
    queryFilterRouteCode {
      routeCodeList
    }
  }
`;
