/* eslint-disable no-underscore-dangle */
import React, { useState, ReactNode, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { TableSearch, Button } from 'shared/components';
import { formatDateTime } from 'shared/utils/dateTime';
import {
  message,
  Typography,
  DatePicker,
  Row,
  Col,
  Input,
  Checkbox,
  Form,
  Select,
  Divider,
  Popover,
  Alert,
  Button as ButtonAntd,
} from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledModal } from './Styles';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import ORDER_DO_LIST_PDF_QUERY, {
  OrderDoListPdfList,
  OrderDoListPdfData,
  OrderDoListPdfVars,
} from 'shared/graphql/query/orderDoListPdf';
import GENER_RATE_PDF_REQUISITION_LISTS_MUTATION, {
  GeneratePDFRequisitionListsData,
  GeneratePDFRequisitionListsVars,
} from 'shared/graphql/mutation/generatePDFRequisitionLists';
import GENER_RATE_PDF_SUM_MUTATION, {
  GeneratePDFSumData,
  GeneratePDFSumVars,
} from 'shared/graphql/mutation/generatePDFSum';
import { RangePicker as RangePickerPdf } from 'shared/components';
import validateSchema from './validateSchema';
import initialValue from './initialValue';
import { FormValues } from './FormValues.model';
import useMessageError from 'shared/hooks/useMessageError';
import client from 'shared/config/client';
import s3 from 'shared/config/s3';
// import SelectQueryFilterMulti from './SelectQueryFilterMulti';

const { Text } = Typography;
const { RangePicker } = DatePicker;

type OrderDoListPdfDataTable = {
  key: string;
  tools: ReactNode;
  deliveryDate: ReactNode;
  queueType: ReactNode;
  routeCode: string;
  stockSite: string;
  hub: string;
  lastMile: string;
  soNo: string;
  docNo: string;
  arText: string;
  arName: string;
  saleName: string;
};

type ModalConfirmCancelBookingProps = {
  visible: boolean;
  onCancel: () => void;
  onComplete: () => void;
};

const ModalDownloadReport: React.FC<ModalConfirmCancelBookingProps> = (props) => {
  const { onCancel, visible, onComplete } = props;
  const history = useHistory();
  const { handleSubmit, watch, errors, control, reset, trigger, setValue } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    mode: 'onChange',
    defaultValues: initialValue,
  });

  const [deliveryDate, setDeliveryDate] = useState<string>('');
  const [soNo, setSoNO] = useState<string>('');
  const [stockSite, setStockSite] = useState<string>('');
  const [arName, setArName] = useState<string>('');
  const [docNo, setDocNo] = useState<string>('');
  const [saleName, setSaleName] = useState<string>('');
  const [lastMile, setLastMile] = useState<string>('');
  const [hub, setHub] = useState<string>('');
  const [pageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<'-1' | '1'>();
  const [selectedSoNO, setSelectedSoNO] = useState<string>('');
  const [downLoadType, setDownLoadType] = useState<string>('1');
  const [queueTypeIn, setQueueTypeIn] = useState<string[]>([]);
  const { getMessageError } = useMessageError();
  const [soSelected, setSoSelected] = useState<any[]>([]);
  //sheet 14
  const watchAllField = watch();
  useEffect(() => {
    if (visible) {
      const initial: FormValues = {
        reportName: 'generatePDFRequisitionLists',
        date: '',
      };

      reset(initial);
    }
  }, [visible]);
  const { loading: orderDoListLoading, data: orderDoListData, refetch } = useQuery<
    OrderDoListPdfData,
    OrderDoListPdfVars
  >(ORDER_DO_LIST_PDF_QUERY, {
    variables: {
      params: {
        deliveryDate: deliveryDate,
        stockSite: stockSite,
        hub: hub,
        lastMile: lastMile,
        soNo: soNo,
        docNo: docNo,
        arName: arName,
        saleName: saleName,
      },
      showData: pageSize,
      page: currentPage,
      order_by: orderBy,
      order_type: orderType,
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'cache-and-network',
  });

  const [generatePDFRequisitionLists, { loading: requisitionLoading }] = useMutation<
    GeneratePDFRequisitionListsData,
    GeneratePDFRequisitionListsVars
  >(GENER_RATE_PDF_REQUISITION_LISTS_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.generatePDFRequisitionLists.path) {
        onComplete();
        message.success({
          content: (
            <>
              Download แล้ว <a onClick={() => history.push('/report')}>ไปยังหน้ารายงานสรุปผล</a>
            </>
          ),
        });
      } else {
        message.error('ไม่มีข้อมูลรายงาน');
      }
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
  });

  const [generatePDFSum, { loading: sumLoading }] = useMutation<
    GeneratePDFSumData,
    GeneratePDFSumVars
  >(GENER_RATE_PDF_SUM_MUTATION, {
    update: (_cache, { data }) => {
      if (data?.generatePDFSum) {
        onComplete();
        message.success({
          content: (
            <>
              Download แล้ว <a onClick={() => history.push('/report')}>ไปยังหน้ารายงานสรุปผล</a>
            </>
          ),
        });
      } else {
        message.error('ไม่มีข้อมูลรายงาน');
      }
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
  });

  const selectedHandler = (event: any, item: any) => {
    const { checked, value } = event.target;

    if (checked) {
      setSoSelected((prevState) => [
        ...prevState,
        {
          soNo: item?.soNo,
        },
      ]);
    } else {
      const soSelectedFilter = soSelected.filter((item) => item.soNo !== value);
      setSoSelected([...soSelectedFilter]);
    }
  };

  const columns = [
    {
      title: 'เครื่องมือ',
      dataIndex: 'tools',
      align: 'center',
      fixed: 'left' as const,
      width: 80,
    },
    {
      title: 'วันที่นัดส่ง',
      dataIndex: 'deliveryDate',
      sorter: true,
      width: 200,
      search: (
        <RangePicker
          onChange={(values) => {
            const formatRangDate =
              values === null
                ? ''
                : `${values[0]!.format('YYYY-MM-DD')}:${values[1]!.format('YYYY-MM-DD')}`;

            setDeliveryDate(formatRangDate);
          }}
          format="YYYY/MM/DD"
          placeholder={['เริ่มต้น', 'สิ้นสุด']}
          ranges={{
            วันนี้: [moment(), moment()],
            สัปดาห์นี้: [moment().startOf('week'), moment().endOf('week')],
            เดือนนี้: [moment().startOf('month'), moment().endOf('month')],
          }}
        />
      ),
    },
    {
      title: 'คิวที่จอง',
      dataIndex: 'queueType',
      width: 200,
    },
    {
      title: 'Site',
      dataIndex: 'stockSite',
      width: 150,
      sorter: true,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setStockSite(event.target.value)}
              value={stockSite}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'Shipment Route',
      dataIndex: 'routeCode',
      sorter: true,
      width: 150,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'arName',
      width: 150,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setArName(event.target.value)}
              value={arName}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'SO NO.',
      dataIndex: 'soNo',
      sorter: true,
      width: 200,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setSoNO(event.target.value)}
              value={soNo}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'DO No.',
      dataIndex: 'docNo',
      width: 150,
      sorter: true,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setDocNo(event.target.value)}
              value={docNo}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'หมายเหตุ SO item',
      dataIndex: 'arText',
      width: 200,
    },
    {
      title: 'Hub',
      dataIndex: 'hub',
      sorter: true,
      width: 150,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setHub(event.target.value)}
              value={hub}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'Last Mile',
      dataIndex: 'lastMile',
      width: 150,
      sorter: true,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setLastMile(event.target.value)}
              value={lastMile}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'ชื่อพนักงานขาย',
      dataIndex: 'saleName',
      width: 170,
      sorter: true,
      search: (
        <Row align="middle">
          <Col>
            <Input
              allowClear
              placeholder="ค้นหา"
              onChange={(event) => setSaleName(event.target.value)}
              value={saleName}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: '',
    },
  ];
  const checkDisabled = (item: OrderDoListPdfList) => {
    console.log('watchAllField', watchAllField);
    return false;
    // if (watchAllField.soNo) {
    //   return !item.soNo.includes(watchAllField.soNo);
    // } else {
    //   return false;
    // }
  };
  const getDeliveryDate = (getDeliveryDate: string[]) => {
    if (!getDeliveryDate || getDeliveryDate.length === 0) {
      return '-';
    }

    const getDeliveryDateList = getDeliveryDate.map((item) => item);

    let text = null;
    if (getDeliveryDate.length === 1) {
      text = (
        <>
          <div>{formatDateTime(getDeliveryDateList[0])}</div>
        </>
      );
    } else if (getDeliveryDate.length > 1) {
      text = (
        <Popover
          placement="bottomLeft"
          title="วันที่นัดส่ง"
          content={
            <>
              {getDeliveryDateList.map((item, index) => {
                return (
                  <div key={index}>
                    <div>{formatDateTime(item)}</div>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                );
              })}
            </>
          }
          trigger="click"
        >
          <ButtonAntd type="link">{`${getDeliveryDateList.length} เวลา`}</ButtonAntd>
        </Popover>
      );
    }

    return text;
  };
  const getQueueType = (queueType: string[]) => {
    if (!queueType || queueType.length === 0) {
      return '-';
    }

    const queueTypeList = queueType.map((item) => item);

    let text = null;
    if (queueType.length === 1) {
      text = (
        <>
          <div>{queueTypeList}</div>
        </>
      );
    } else if (queueType.length > 1) {
      text = (
        <Popover
          placement="bottomLeft"
          title="คิวที่จอง"
          content={
            <>
              {queueTypeList.map((item, index) => {
                return (
                  <div key={index}>
                    <div>{item}</div>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                );
              })}
            </>
          }
          trigger="click"
        >
          <ButtonAntd type="link">{`${queueTypeList.length} คิว`}</ButtonAntd>
        </Popover>
      );
    }

    return text;
  };
  // sheet 15
  const onSubmit = handleSubmit(async (values) => {
    try {
      console.log('values', values);
      console.log('values.reportName', values.reportName);

      if (values.reportName === 'generatePDFRequisitionLists') {
        const { data } = await client.mutate({
          mutation: GENER_RATE_PDF_REQUISITION_LISTS_MUTATION,
          variables: {
            soNo: soSelected?.map((row) => {
              return row?.soNo;
            }),
          },
        });
        const url = `${s3}/${data?.generatePDFRequisitionLists?.path}`;
        window.open(url, '_blank');

        // window.open(`${data.path}`);
        // await Promise.all(
        //   soSelected.map(async (item) => {
        //     await generatePDFRequisitionLists({
        //       variables: {
        //         soNo: item?.soNo,
        //       },
        //     });
        //   }),
        // );
      }

      // if (values.reportName === 'generatePDFSum') {
      //   await Promise.all(
      //     soSelected.map(async (item) => {
      //       await generatePDFSum({
      //         variables: {
      //           soNo: item.soNo,
      //           date: values.date,
      //         },
      //       });
      //     }),
      //   );

      //   // generatePDFSum({
      //   //   variables: {
      //   //     soNo: values.soNo,
      //   //     date: values.date,
      //   //   },
      //   // });

      //   // return;
      // }
    } catch (error) {
      console.log('error', error);
    }
  });
  console.log('soSelected', soSelected);

  let dataSource: OrderDoListPdfDataTable[] = [];
  let totalDocument = 0;
  if (orderDoListData?.orderDoListPdf) {
    const { orderDoListPdf } = orderDoListData?.orderDoListPdf;
    dataSource = orderDoListPdf.map((item) => ({
      key: item.soNo,
      tools: (
        <Row align="middle" gutter={[16, 0]}>
          <Col>
            <Checkbox
              value={item?.soNo}
              onChange={(event) => selectedHandler(event, item)}
              checked={soSelected.map((row) => row.soNo).indexOf(item.soNo) !== -1}
            />

            {/* <Controller
              name={`reportList[${index}].soNo`}
              control={control}
              render={({ value, onChange }) => (
                <Checkbox
                  disabled={checkDisabled(item)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onChange(item.soNo);
                    } else {
                      onChange('');
                    }
                  }}
                  value={value}
                  checked={item.soNo ? value === item.soNo : false}
                />
              )}
            /> */}
          </Col>
        </Row>
      ),
      deliveryDate: getDeliveryDate(item.deliveryDate),
      queueType: getQueueType(item.queueType),
      stockSite: item.stockSite || '-',
      routeCode: item.routeCode || '-',
      arName: item.arName || '-',
      soNo: item.soNo || '-',
      docNo: item.docNo || '-',
      arText: item.arText || '-',
      hub: item.hub || '-',
      lastMile: item.lastMile || '-',
      saleName: item.saleName || '-',
    }));
  }
  return (
    <StyledModal
      title="Download Report"
      className="custom-modal"
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button
          // disabled={loading}
          key="cancelButton"
          icon={<CloseCircleOutlined />}
          onClick={onCancel}
        >
          ปิด
        </Button>,
        <Button
          key="okButton"
          icon={<CheckCircleOutlined />}
          type="success"
          // disabled={!selectedSoNO || !downLoadType}
          onClick={() => onSubmit()}
          loading={sumLoading || requisitionLoading}
        >
          Download
        </Button>,
      ]}
    >
      <div style={{ marginBottom: -10, marginTop: -10 }}>
        {Object.keys(errors).length > 0 && (
          <Alert
            message="กรุณากรอกข้อมูลให้ครบถ้วน"
            banner
            style={{ margin: '-24px -24px 15px' }}
          />
        )}

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Text strong>
              ประเภทรายงาน<Text type="danger">*</Text>
            </Text>
            <Form.Item
              validateStatus={errors.reportName && 'error'}
              help={errors.reportName?.message && errors.reportName.message}
            >
              <Controller
                control={control}
                name="reportName"
                render={({ value, onChange }) => (
                  <Select
                    allowClear
                    placeholder="เลือก"
                    value={value || undefined}
                    onChange={(valueChange) => onChange(valueChange)}
                    defaultValue={'generatePDFRequisitionLists'}
                  >
                    <Select.Option value="generatePDFRequisitionLists">ใบสรุปใบเบิก</Select.Option>
                    {/* <Select.Option value="generatePDFSum">billing</Select.Option> */}
                  </Select>
                )}
              />
            </Form.Item>
          </Col>
          {watchAllField.reportName === 'generatePDFSum' && (
            <Col span={8}>
              <Text strong>
                ช่วงวันที่เริ่มงาน<Text type="danger">*</Text>
              </Text>
              <Form.Item
                validateStatus={errors.date && 'error'}
                help={errors.date?.message && errors.date.message}
              >
                <Controller
                  control={control}
                  name="date"
                  render={({ value, onChange }) => (
                    <RangePickerPdf
                      value={value}
                      onChange={(valueChange) => onChange(valueChange || '')}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <TableSearch
          size="small"
          // loading={orderDoListLoading}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            current: currentPage,
            pageSize,
            total: totalDocument,
            onChange: (value) => setCurrentPage(value),
          }}
          onSort={(sort) => {
            setOrderBy(sort.orderBy);
            setOrderType(sort.orderType);
          }}
        />
      </div>
    </StyledModal>
  );
};

export default ModalDownloadReport;
