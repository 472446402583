import { useMutation } from '@apollo/react-hooks';
import UPDATE_USER_ACCOUNT_MUTATION, {
  UpdateUserAccountData,
  UpdateUserAccountVars,
} from 'shared/graphql/mutation/updateUserAccount';

export const useRemoveCountNotification = () => {
  const [updateUserAccount] = useMutation<UpdateUserAccountData, UpdateUserAccountVars>(
    UPDATE_USER_ACCOUNT_MUTATION,
  );

  const onRemoveCountNotification = (userAccountId: string) => {
    updateUserAccount({
      variables: {
        _id: userAccountId,
        wait_to_read_noti: 0,
      },
    });
  };

  return {
    onRemoveCountNotification,
  };
};
