import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import LAST_MILE_GROUP_QUERY, { LastMileGroupData } from 'shared/graphql/query/lastMileGroup';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import _ from 'lodash';

type SelectLastMileProps = {
  value?: string;
  disabled?: boolean;
  checkData?: {
    stockSite?: string | undefined;
    item: {
      stockSite?: string | undefined;
      lastMile?: string | undefined;
    }[];
  };
  onChange?: (values?: { lastMileId: string; lastMile: string }) => void;
};

const { Option } = Select;

const SelectLastMile: React.FC<SelectLastMileProps> = (props) => {
  const { value, disabled, onChange, checkData } = props;
  const [lastMileGroup, setLastMileGroup] = useState<
    {
      _id: string;
      lastMile: string;
    }[]
  >([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<LastMileGroupData>(LAST_MILE_GROUP_QUERY, {
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && !error && data?.lastMileGroup) {
      const sortData = _.sortBy(data.lastMileGroup, ['lastMile'], ['asc']);

      setLastMileGroup([...sortData]);
    }
  }, [data?.lastMileGroup, loading, error]);

  let ss: (string | undefined)[] = [];
  if (checkData?.stockSite) {
    ss = checkData?.item
      .filter((ele) => ele.stockSite == checkData?.stockSite)
      .map((ele) => ele.lastMile || ele.stockSite);
  }

  const filterLastmile = lastMileGroup.filter((item) => {
    return !ss.includes(item.lastMile);
  });

  const options: ReactNode[] = filterLastmile.map((item, index) => (
    <Option key={index} value={item._id}>{`${item.lastMile}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findLastMile = lastMileGroup.find((item) => item._id === valueInput);
    if (onChange && findLastMile) {
      const { _id, lastMile } = findLastMile;
      onChange({
        lastMileId: _id,
        lastMile,
      });
    }
  };

  return (
    <>
      <Select
        style={{ width: '100%' }}
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือก LastMile"
        onChange={(valueChange) => onSelected(valueChange)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default SelectLastMile;
