/* eslint-disable camelcase */
import { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, matchPath } from 'react-router-dom';
import { removeStoredAuthToken } from 'shared/utils/authToken';
import CURRENT_USER_QUERY from 'shared/graphql/query/currentUser';

const useCurrentUser = () => {
  const history = useHistory();

  const checkUrlWithoutLogin = () => {
    return (
      !!matchPath(history.location.pathname, `/tracking/detail/:id`) ||
      !!matchPath(history.location.pathname, `/forgot-password`) ||
      !!matchPath(history.location.pathname, `/reset-password`)
    );
  };

  const { loading, error, data, networkStatus } = useQuery(CURRENT_USER_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (checkUrlWithoutLogin()) {
        return;
      }

      const textError = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : err.message;
      if (textError === 'Unauthorized.') {
        removeStoredAuthToken();
        history.push('/login');
      }
    },
  });

  const getTypeUser = useCallback(() => {
    let typeUser = 'agent';

    if (data) {
      const { user_account_type, permission } = data.currentUser;
      if (user_account_type === 1 && permission.includes('SUPER')) {
        typeUser = 'superAdmin';
      } else if (user_account_type === 1) {
        typeUser = 'admin';
      }
    }

    return typeUser;
  }, [data]);

  const getUserAccountType = useCallback(() => {
    let userAccountType = 1;

    if (data) {
      const { user_account_type } = data.currentUser;
      userAccountType = user_account_type;
    }

    return userAccountType;
  }, [data]);

  const getPermission = useCallback(() => {
    if (data) {
      return data.currentUser.permission;
    }

    return [];
  }, [data]);

  return {
    loading,
    error,
    data,
    permission: getPermission(),
    typeUser: getTypeUser(),
    userAccountType: getUserAccountType(),
    networkStatus,
  };
};

export default useCurrentUser;
