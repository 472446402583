import React, { useContext } from 'react';
import { Route, Switch, matchPath } from 'react-router-dom';
import { AuthContext } from 'shared/context/AuthContext';
import Layout from 'shared/container/Layout';
import { PageError } from 'shared/components';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import LoadableComponent from 'shared/components/LoadableComponent';

const UserList = LoadableComponent(() => import('./Routes/UserSuperAdmin'));
const DriverList = LoadableComponent(() => import('./Routes/DriverList'));
const CorporateList = LoadableComponent(() => import('./Routes/CorporateList'));
const CorporateAgent = LoadableComponent(() => import('./Routes/CorporateAgent/EditAgent'));
const TruckList = LoadableComponent(() => import('./Routes/TruckList'));
const Customer = LoadableComponent(() => import('./Routes/Customer'));
const Warehouse = LoadableComponent(() => import('./Routes/Warehouse'));
const Notification = LoadableComponent(() => import('./Routes/Notification'));
const PlanRoute = LoadableComponent(() => import('./Routes/PlanRoute'));
const Gas = LoadableComponent(() => import('./Routes/Gas'));
const Category = LoadableComponent(() => import('./Routes/Category'));
const RouteCode = LoadableComponent(() => import('./Routes/RouteCode'));

const Setting = (props) => {
  const { match, location } = props;
  const { isCheckPermission } = useContext(AuthContext);
  const { typeUser, userAccountType } = useCurrentUser();

  const checkPath = (path) => {
    return !!matchPath(location.pathname, `${match.path}/${path}`);
  };

  const getTextHeader = () => {
    let text = '';
    if (checkPath('truck')) {
      text = 'ตั้งค่ารถขนส่ง';
    } else if (checkPath('user')) {
      text = 'ตั้งค่าผู้ใช้งาน';
    } else if (checkPath('driver')) {
      text = 'ตั้งค่าพนักงานขับรถ/เด็กติดรถ';
    } else if (checkPath('corporate')) {
      text = userAccountType === 1 ? 'ตั้งค่าข้อมูลบริษัท' : 'ตั้งค่าบริษัทขนส่ง';
    } else if (checkPath('customer')) {
      text = 'ตั้งค่าข้อมูลลูกค้า';
    } else if (checkPath('sms')) {
      text = 'ตั้งค่าการแจ้งเตือน';
    } else if (checkPath('warehouse')) {
      text = 'การตั้งค่าข้อมูลคลัง';
    } else if (checkPath('notifications')) {
      text = 'การแจ้งเตือน';
    } else if (checkPath('planroute')) {
      text = 'ตั้งค่าการรับข้อมูล DO';
    } else if (checkPath('routecode')) {
      text = 'ตั้งค่า Route Code';
    }

    return text;
  };

  const routes = [
    {
      path: `${match.path}/truck`,
      component: TruckList,
      typeUser: ['agent'],
    },
    {
      path: `${match.path}/driver`,
      component: DriverList,
      typeUser: ['agent'],
    },
    {
      path: `${match.path}/corporate`,
      component: CorporateAgent,
      typeUser: ['agent'],
    },
    {
      path: `${match.path}/user`,
      component: UserList,
      typeUser: ['superAdmin'],
    },
    {
      path: `${match.path}/corporate`,
      component: CorporateList,
      typeUser: ['admin'],
      isHasPermission: isCheckPermission('setting', 'COMPANY'),
    },
    {
      path: `${match.path}/customer`,
      component: Customer,
      typeUser: ['admin'],
    },
    {
      path: `${match.path}/warehouse`,
      component: Warehouse,
      typeUser: ['admin'],
    },
    {
      path: `${match.path}/gas`,
      component: Gas,
      typeUser: ['admin', 'superAdmin'],
    },
    {
      path: `${match.path}/category`,
      component: Category,
      typeUser: ['superAdmin'],
    },
    {
      path: `${match.path}/notifications`,
      component: Notification,
      typeUser: ['admin'],
      isHasPermission: isCheckPermission('setting', 'DETAIL_NOTI'),
    },
    {
      path: `${match.path}/notifications`,
      component: Notification,
      typeUser: ['agent'],
    },
    {
      path: `${match.path}/planroute`,
      component: PlanRoute,
      typeUser: ['superAdmin'],
    },
    {
      path: `${match.path}/routecode`,
      component: RouteCode,
      typeUser: ['superAdmin'],
    },
  ]
    .filter((route) => route.typeUser.includes(typeUser))
    .filter((route) => route.isHasPermission || route.isHasPermission === undefined);

  const renderRoute = routes.map((route) => (
    <Route key={route.path} path={route.path} component={route.component} />
  ));

  return (
    <Layout textHeader={getTextHeader()}>
      <Switch>
        {renderRoute}
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Setting;
