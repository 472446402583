import styled from 'styled-components';

const StyledLogo = styled.div`
  display: flex;
  width: 140px;
  height: 30px;
  align-items: center;

  ${(props) =>
    props.size === 'small' &&
    `
    width: 45px;
  `}

  & svg {
    width: 100%;
  }

  & img {
    width: 100%;
  }
`;

export default StyledLogo;
