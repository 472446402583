import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import COMPANIES_QUERY, { VendorsVars, VendorsData, Vendors } from 'shared/graphql/query/vendors';
import { Select, message, Empty } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectVendorsProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: Vendors) => void;
  placeholder?: string;
};

const { Option } = Select;

const SelectVendors: React.FC<SelectVendorsProps> = (props) => {
  const { value, disabled, onChange, placeholder } = props;
  const [search, setSearch] = useState<string>('');
  const [vendors, setVendors] = useState<Vendors[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<VendorsData, VendorsVars>(COMPANIES_QUERY, {
    variables: {
      full_name: search,
      active_status: true,
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.vendors) {
      setVendors([...data.vendors.vendors]);
    }
  }, [data?.vendors, loading, error]);

  const options: ReactNode[] = vendors.map((item) => (
    <Option key={item._id} value={item._id}>{`${item.full_name}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = vendors.find((item) => item._id === valueInput);
    if (onChange && findValue) {
      onChange(findValue);
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder || 'เลือก'}
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
        notFoundContent={<Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectVendors;
