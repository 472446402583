import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import SETTING_DISTRICT_LIST_QUERY, {
  SettingDistrictList,
  SettingDistrictListData,
  SettingDistrictListVars,
} from 'shared/graphql/query/settingDistrictList';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

type SelectSettingDistrictProps = {
  value?: string;
  disabled?: boolean;
  provinceCode?: string;
  provinceName?: string;
  onChange?: (values?: {
    districtId: string;
    districtCode: string;
    districtName: string;
    provinceCode: string;
  }) => void;
};

const { Option } = Select;

const SelectSettingProvince: React.FC<SelectSettingDistrictProps> = (props) => {
  const { value, disabled, onChange, provinceCode, provinceName } = props;
  const [settingDistrictList, setSettingDistrictList] = useState<SettingDistrictList[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<SettingDistrictListData, SettingDistrictListVars>(
    SETTING_DISTRICT_LIST_QUERY,
    {
      variables: {
        province_code: provinceCode,
        province_name: provinceName,
        showData: 1000,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.settingDistrictList) {
      const sortDistrict = _.sortBy(
        data.settingDistrictList.settingDistrictList,
        ['district_name'],
        ['asc'],
      );
      setSettingDistrictList([...sortDistrict]);
    }
  }, [data?.settingDistrictList, loading, error]);
  const options: ReactNode[] = settingDistrictList.map((item) => (
    <Option key={uuidv4()} value={item.district_name}>{`${item.district_name}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findDistrict = settingDistrictList.find((item) => item.district_name === valueInput);
    if (onChange && findDistrict) {
      const { _id, district_code, district_name, province_code } = findDistrict;
      onChange({
        districtId: _id,
        districtCode: district_code,
        districtName: district_name,
        provinceCode: province_code,
      });
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือกอำเภอ"
        onChange={(valueChange) => onSelected(valueChange)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default SelectSettingProvince;
