import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageError, HeaderNav } from 'shared/components';
import LoadableComponent from 'shared/components/LoadableComponent';

const TrackingDetail = LoadableComponent(() => import('./Routes/TrackingDetail'));

const Tracking = (props) => {
  const { match } = props;

  return (
    <>
      <HeaderNav />
      <Switch>
        <Route exact path={`${match.path}/detail/:jobAddressId`} component={TrackingDetail} />
        <Route component={PageError} />
      </Switch>
    </>
  );
};

export default Tracking;
