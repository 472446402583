import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #fafafa;
  }

  &:not(:last-child) {
    border-bottom: 0px;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${(props) =>
    props.active &&
    `
  background-color: #fafafa;

  `}
`;

export const IconContainer = styled.div`
  margin-right: 10px;
  color: #8c8c8c;
  align-items: flex-start;
`;

export const TextWrapper = styled.div`
  line-height: 1.2;
  flex: 1;
`;

export const SecondaryText = styled.span`
  color: #bfbfbf;
  font-size: 12px;
`;

export default Container;
