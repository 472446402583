import React from 'react';
import { Tag } from 'shared/components';
import { RenewalStatus } from 'shared/model/renewalStatus.model';

type TagRenewalStatusProps = {
  status: RenewalStatus;
};

const getTextColorStatus = (status: RenewalStatus) => {
  switch (status) {
    case 1:
      return { color: '#BFBFBF', textColor: '#212429', text: 'ยังไม่ทำรายการ', outline: false };
    case 2:
      return { color: '#212429', textColor: '#ffffff', text: 'รอยืนยันเก็บงาน', outline: false };
    case 3:
      return { color: '#FFE8E3', textColor: '#CF1322', text: 'ถูกปฎิเสธ', outline: false };
    case 4:
      return { color: '#D0E7F9', textColor: '#1487E2', text: 'กำลังเก็บงาน', outline: false };
    case 5:
      return { color: '#CF1322', textColor: '#ffffff', text: 'ยกเลิกเก็บงาน', outline: false };
    case 6:
      return { color: '#068828', textColor: '#ffffff', text: 'เก็บงานสำเร็จ', outline: false };
    default:
      return { text: status };
  }
};

const TagRenewalStatus: React.FC<TagRenewalStatusProps> = (props) => {
  const { status } = props;
  const data = getTextColorStatus(status);

  return (
    <Tag color={data.color} outline={data.outline} textColor={data.textColor}>
      {data.text}
    </Tag>
  );
};

export default TagRenewalStatus;
