import React from 'react';
import { Empty } from 'antd';
import { StyledTable } from './Styles';

const Table = (props) => {
  return (
    <StyledTable
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: <Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      size="small"
      {...props}
    />
  );
};

export default Table;
