import * as yup from 'yup';

const validateSchema = yup.object().shape({
  isEdit: yup.boolean().required(),
  customerId: yup.string().when('isEdit', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  arCode: yup.string().required('กรุณากรอกข้อมูล'),
  arName: yup.string().required('กรุณากรอกข้อมูล'),
  lat: yup.string().required('กรุณากรอกข้อมูล'),
  lng: yup.string().required('กรุณากรอกข้อมูล'),
  transportAddress: yup.string().required('กรุณากรอกข้อมูล'),
  pinMap: yup.string().required('กรุณากรอกข้อมูล'),
  shiptoTel: yup
    .string()
    .matches(/(^$|^[0]\d{8,9}$)/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  subDistrict: yup.string().required('กรุณากรอกข้อมูล'),
  district: yup.string().required('กรุณากรอกข้อมูล'),
  province: yup.string().required('กรุณากรอกข้อมูล'),
  provinceCode: yup.string().required('กรุณากรอกข้อมูล'),
  districtCode: yup.string().required('กรุณากรอกข้อมูล'),
  remark: yup.string().when('isEdit', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  postcode: yup
    .string()
    .matches(/(^$|^\d{5}$)/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
});

export default validateSchema;
