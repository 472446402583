import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_FILTER_S_TYPE_QUERY, {
  QueryFiltersTypeData,
  STypeList,
} from 'shared/graphql/query/queryFiltersType';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectMultiSTypeProps = {
  value?: string[];
  disabled?: boolean;
  onChange?: (values: string[]) => void;
};

const { Option } = Select;

const SelectMultiSType: React.FC<SelectMultiSTypeProps> = (props) => {
  const { value, disabled, onChange } = props;
  const [sTypeList, setSTypeList] = useState<STypeList>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<QueryFiltersTypeData>(QUERY_FILTER_S_TYPE_QUERY, {
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.queryFiltersType) {
      setSTypeList([...data.queryFiltersType.sTypeList]);
    }
  }, [data?.queryFiltersType, loading, error]);

  const options: ReactNode[] = sTypeList.map((item) => {
    return <Option key={item} value={item}>{`${item}`}</Option>;
  });

  return (
    <Select
      allowClear
      mode="multiple"
      maxTagCount="responsive"
      value={value}
      disabled={disabled}
      loading={loading}
      placeholder="ค้นหา"
      onChange={(valueChange) => {
        onChange && onChange(valueChange);
      }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: '100%' }}
    >
      {options}
    </Select>
  );
};

export default SelectMultiSType;
