import styled from 'styled-components';
import { Tag } from 'antd';
import { color } from 'shared/utils/styles';

const StyledTag = styled(Tag)`
  border-radius: 3px;
  color: ${(props) => props.textColor} !important;

  ${(props) =>
    props.outline &&
    `
      background-color: ${color.white} !important;
      color: ${props.color} !important;
      border: 1px solid ${props.color} !important;
  `}

  ${(props) =>
    !props.outline &&
    `
    color: ${props.textColor} !important;
  `}
`;

export default StyledTag;
