import React, { useState, useContext } from 'react';
import Icon from '@ant-design/icons';
import { Badge, Button, Col, Popover, Row, Typography, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import BellRegular from 'shared/assets/icon/bell-regular.svg';
import { Spin } from 'shared/components';
import NOTIFICATIONS_QUERY, {
  NotificationsVars,
  NotificationsData,
} from 'shared/graphql/query/notifications';
import { useQuery } from '@apollo/react-hooks';
import useMessageError from 'shared/hooks/useMessageError';
import { formatDateTime } from 'shared/utils/dateTime';
import NotificationItem from 'views/Setting/Routes/Notification/common/components/NotificationItem';
import BellLight from 'shared/assets/icon/bell-light.svg';
import { useReadNotification } from 'views/Setting/Routes/Notification/common/hooks/useReadNotification';
import { useRemoveCountNotification } from './useRemoveCountNotification';
import { AuthContext } from 'shared/context/AuthContext';

const { Text } = Typography;

type NotificationProps = {
  color: 'white' | 'black';
  active: boolean;
  count: number;
};

const Notification: React.FC<NotificationProps> = ({ color, active, count }) => {
  const history = useHistory();
  const [isActive, setActive] = useState<boolean>(active);
  const { getMessageError } = useMessageError();
  const { onRead } = useReadNotification();
  const { onRemoveCountNotification } = useRemoveCountNotification();
  const { state, isCheckPermission } = useContext(AuthContext);

  const { data } = useQuery<NotificationsData, NotificationsVars>(NOTIFICATIONS_QUERY, {
    variables: {
      showData: 10,
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'cache-and-network',
  });

  let content = (
    <div style={{ padding: 10, textAlign: 'center' }}>
      <Spin />
    </div>
  );

  if (data?.notifications) {
    const { notifications } = data.notifications;
    if (notifications.length === 0) {
      content = (
        <div
          style={{
            margin: '60px 0px',
            textAlign: 'center',
          }}
        >
          <Text type="secondary">
            <Icon component={BellLight} style={{ fontSize: 30, marginBottom: 10 }} />
          </Text>
          <div>
            <Text type="secondary" style={{ fontSize: 14 }}>
              ไม่มีรายการแจ้งเตือน
            </Text>
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          <SimpleBar style={{ marginBottom: 10, maxHeight: 500 }}>
            {notifications.map((item) => (
              <NotificationItem
                key={item._id}
                iconType={item.icon_type}
                title={item.title}
                description={item.description}
                dateTime={formatDateTime(item.create_date)}
                isActive={!item.is_read}
                onClick={() => {
                  if (!item.is_read) {
                    onRead(
                      {
                        id: item._id,
                        read: true,
                      },
                      () => {
                        if (item.redirect_url) {
                          history.push(`/${item.redirect_url}`);
                        }
                      },
                    );
                  } else {
                    if (item.redirect_url) {
                      history.push(`/${item.redirect_url}`);
                    }
                  }
                }}
              />
            ))}
          </SimpleBar>

          {isCheckPermission('setting', 'DETAIL_NOTI') && (
            <div style={{ margin: 10 }}>
              <Link to="/setting/notifications">
                <Button block>ดูทั้งหมด</Button>
              </Link>
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <Popover
      content={<div style={{ width: 400, margin: '-12px -16px' }}>{content}</div>}
      title={
        <Row style={{ padding: 8 }}>
          <Col span={24}>
            <Text strong>การแจ้งเตือน</Text>
          </Col>
          <Col span={24}>
            <Text type="secondary" style={{ fontWeight: 'normal' }}>
              แสดงการแจ้งเตือนข้อมูลต่างๆ ในระบบ
            </Text>
          </Col>
        </Row>
      }
      trigger="click"
      placement="bottomRight"
      onVisibleChange={(value) => {
        setActive(false);
        if (value && state.user?.id && isActive) {
          onRemoveCountNotification(state.user.id);
        }
      }}
    >
      <Badge
        style={{
          backgroundColor: color === 'white' ? '#FFFFFF' : '#CF1322',
          color: color === 'white' ? '#CF1322' : '#FFFFFF',
        }}
        offset={[-10, 10]}
        count={isActive ? count : 0}
      >
        <Button
          style={{ color: color === 'white' ? 'white' : undefined }}
          icon={<Icon component={BellRegular} />}
          type="text"
          shape="circle"
          size="large"
        />
      </Badge>
    </Popover>
  );
};

export default Notification;
