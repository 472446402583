import gql from 'graphql-tag';

export type RouteCode = string;

export interface BuildRouteCodeData {
  buildRouteCode: {
    routeCode: RouteCode[];
  };
}

export interface BuildRouteCodeVars {
  routeCode?: string;
}

export default gql`
  query BuildRouteCode($routeCode: String) {
    buildRouteCode(routeCode: $routeCode) {
      routeCode
    }
  }
`;
