import gql from 'graphql-tag';

export interface Route {
  routeCode: string;
  routeCodeId: string;
  cost: string;
}

export interface CostingRouteCodeData {
  costingRouteCode: {
    route: Route[];
  };
}

export interface CostingRouteCodeVars {
  typeId: number;
}

export default gql`
  query CostingRouteCode($typeId: Int!) {
    costingRouteCode(typeId: $typeId) {
      route {
        routeCode
        routeCodeId
        cost
      }
    }
  }
`;
