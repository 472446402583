import gql from 'graphql-tag';

export interface CustomerList {
  _id: string;
  ar_code: string;
  ar_name: string;
  remark: string;
  last_update: string;
  shipto_tel: string;
  transport_address: string;
  district: string;
  sub_district: string;
  province: string;
  postcode: string;
  pin_map: string;
  lat: string;
  lng: string;
}

export interface CustomerListData {
  customerList: {
    customerList: CustomerList[];
    totalPage: number;
    totalDocument: number;
  };
}

export interface CustomerListVars {
  order_by?: string;
  order_type?: '1' | '-1';
  page?: number;
  showData?: number;
  ar_code?: string;
  ar_name?: string;
}

export default gql`
  query CustomerList(
    $ar_code: String
    $ar_name: String
    $order_by: String
    $order_type: String
    $page: Int
    $showData: Int
  ) {
    customerList(
      params: { ar_code: $ar_code, ar_name: $ar_name }
      sort: { order_by: $order_by, order_type: $order_type }
      page: $page
      showData: $showData
    ) {
      customerList {
        _id
        ar_code
        ar_name
        remark
        last_update
        shipto_tel
        transport_address
        district
        sub_district
        province
        postcode
        pin_map
        lat
        lng
      }
      totalPage
      totalDocument
    }
  }
`;
