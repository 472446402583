import { useCallback } from 'react';

const useBranchSelected = () => {
  const localStorageName = 'selectedBranch';

  const set = useCallback((branchId) => {
    localStorage.setItem(localStorageName, branchId);
  }, []);

  const remove = useCallback(() => {
    localStorage.removeItem(localStorageName);
  }, []);

  return {
    getSelectedBranch: localStorage.getItem(localStorageName),
    setSelectedBranch: set,
    removeSelectedBranch: remove,
  };
};

export default useBranchSelected;
