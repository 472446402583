import * as yup from 'yup';

const validateSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(([a-zA-Z0-9]+([\_\.][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'รูปแบบไม่ถูกต้อง',
    )
    .required(),
});

export default validateSchema;
