import * as yup from 'yup';

const validateSchema = yup.object().shape({
  reportName: yup.string().required('กรุณาเลือกประเภทรายงาน'),
  // date: yup.string().required('กรุณาระบุช่วงวันที่'),
  // soNo: yup.string().required('เลือกsoNo'),
  date: yup.string().when('reportName', {
    is: (value) => value === 'generatePDFSum',
    then: yup.string().required('กรุณาระบุช่วงวันที่'),
  }),
});

export default validateSchema;
