import React, { ReactNode, useState, useEffect } from 'react';
import { Select, message } from 'antd';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import COSTING_ROUTE_CODE_QUERY, {
  CostingRouteCodeData,
  CostingRouteCodeVars,
  Route,
} from 'shared/graphql/query/costingRouteCode';
import useMessageError from 'shared/hooks/useMessageError';

type SelectCostingRouteCodeProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: Route) => void;
  typeId: number;
};

const { Option } = Select;

const SelectCostingRouteCode: React.FC<SelectCostingRouteCodeProps> = (props) => {
  const { value, disabled, onChange, typeId } = props;
  const [routes, setRoutes] = useState<Route[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<CostingRouteCodeData, CostingRouteCodeVars>(
    COSTING_ROUTE_CODE_QUERY,
    {
      variables: {
        typeId,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!loading && !error && data?.costingRouteCode) {
      const { route } = data.costingRouteCode;
      setRoutes(route);
    }
  }, [data?.costingRouteCode, loading, error]);

  const options: ReactNode[] = routes.map((item) => (
    <Option key={item.routeCodeId} value={item.routeCodeId}>{`${item.routeCode}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findRoute = routes.find((item) => item.routeCodeId === valueInput);
    if (onChange && findRoute) {
      onChange(findRoute);
    }
  };

  return (
    <Select
      allowClear
      style={{ width: '100%' }}
      value={value && options.length ? value : undefined}
      disabled={disabled}
      loading={loading}
      placeholder="เลือก Route code"
      onChange={(valueChange) => onSelected(valueChange)}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};

export default SelectCostingRouteCode;
