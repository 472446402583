import gql from 'graphql-tag';

export interface ShipToPartyGroupData {
  shipToPartyGroup: {
    _id: string;
    shipToParty: string;
  }[];
}

export default gql`
  query shipToPartyGroup {
    shipToPartyGroup {
      _id
      shipToParty
    }
  }
`;
