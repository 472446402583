import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from 'shared/container/Layout';
import { PageError } from 'shared/components';
import LoadableComponent from 'shared/components/LoadableComponent';

const ReportList = LoadableComponent(() => import('./Routes/ReportList'));

const Report = (props) => {
  const { match } = props;

  return (
    <Layout textHeader="รายงานสรุปผล">
      <Switch>
        <Route path={`${match.path}`} component={ReportList} />
        <Route component={PageError} />
      </Switch>
    </Layout>
  );
};

export default Report;
