import React, { useContext } from 'react';
import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import Icon from '@ant-design/icons';
import JOB_QUERY, { JobData, JobVars } from 'shared/graphql/query/job';
import TimesRegular from 'shared/assets/icon/times-regular.svg';
import TruckSolid from 'shared/assets/icon/truck-solid.svg';
import UserSolid from 'shared/assets/icon/user-solid.svg';
import FileAltSolid from 'shared/assets/icon/file-alt-solid.svg';
import MapPinSolid from 'shared/assets/icon/map-pin-solid.svg';
import { getMode } from 'shared/functions/getMode';
import DropPointItem from './DropPointItem';
import { StyledDrawer } from './Styles';
import { TrackingTransporterContext } from '../common/context/TrackingTransporterContext';
import { colors } from '../common/colors';

type ModalJobDetailProps = {
  visible: boolean;
  onCancel: () => void;
};

const { Text } = Typography;

const ModalJobDetail: React.FC<ModalJobDetailProps> = ({ visible, onCancel }) => {
  const { state, dispatch } = useContext(TrackingTransporterContext);

  const { data, error } = useQuery<JobData, JobVars>(JOB_QUERY, {
    variables: {
      _id: state.truck?.jobId || '',
    },
    fetchPolicy: 'network-only',
  });

  const truckColor: { [key: string]: 'blue' | 'orange' | 'gray' | 'green' } = {
    รถที่รอเริ่มงาน: 'blue',
    รถที่พักงาน: 'orange',
    รถที่ออฟไลน์: 'gray',
    รถที่กำลังดำเนินงาน: 'green',
  };

  let content = <Skeleton />;

  if (!state.truck?.jobId) {
    content = <div>กรุณาเลือกรายการรถขนส่ง</div>;
  }

  if (data?.job) {
    const { job_number, truck, driver, job_address } = data.job;

    const dropPointList = job_address.map((item, index) => ({
      id: item._id,
      type: item.mode,
      title: `จุดที่ ${index + 1} - ${getMode(item.mode)}`,
      address: item.address,
    }));

    const renderDropPoint = dropPointList.map((item) => (
      <DropPointItem type={item.type} borderBottom key={item.id}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <div>
              <Text strong>{item.title}</Text>
            </div>
            <div>
              <Text>{item.address}</Text>
            </div>
          </Col>
        </Row>
      </DropPointItem>
    ));

    const color = state.truck ? truckColor[state.truck.type] : 'black';
    const truckIconColor = colors[color].color;

    content = (
      <>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Row gutter={[12, 0]}>
              <Col>
                <Icon
                  component={TruckSolid}
                  style={{
                    fontSize: 20,
                    color: truckIconColor,
                    marginTop: 3,
                  }}
                />
              </Col>
              <Col>
                <div>
                  <Text strong>ทะเบียนรถ:</Text>
                </div>
                <div>{truck.license_plate || '-'}</div>
              </Col>
            </Row>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Row gutter={[12, 0]}>
              <Col>
                <Icon component={UserSolid} style={{ fontSize: 20, marginTop: 3 }} />
              </Col>
              <Col>
                <div>
                  <Text strong>พนักงานขับรถ:</Text>
                </div>
                <div>{driver.full_name || '-'}</div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[12, 0]}>
              <Col>
                <Icon component={FileAltSolid} style={{ fontSize: 20, marginTop: 3 }} />
              </Col>
              <Col>
                <div>
                  <Text strong>รหัสงานขนส่ง:</Text>
                </div>
                <div>{job_number || '-'}</div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col>
                <Icon
                  component={MapPinSolid}
                  style={{ color: '#CF1322', fontSize: 20, marginTop: 3 }}
                />
              </Col>
              <Col>
                <Button
                  disabled={!state.truck?.gps}
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => {
                    if (state.truck?.gps) {
                      dispatch({
                        type: 'FIT_BOUND',
                        payload: {
                          isFitBound: true,
                        },
                      });
                    }
                  }}
                >
                  คลิกเพื่อดูตำแหน่งบนแผนที่
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider style={{ margin: '10px 0px 20px' }} />

        <div style={{ position: 'relative' }}>{renderDropPoint}</div>
      </>
    );
  }

  if (error) {
    content = <div>{error.message}</div>;
  }

  return (
    <StyledDrawer
      closable
      placement="right"
      onClose={() => onCancel()}
      visible={visible}
      getContainer={false}
      zIndex={1001}
      width={400}
      mask={false}
      maskClosable={false}
      style={{ position: 'absolute' }}
      closeIcon={<Icon component={TimesRegular} />}
      className="custom-drawer"
      destroyOnClose
    >
      {content}
    </StyledDrawer>
  );
};

export default ModalJobDetail;
