import { FormValue } from 'views/Setting/Routes/Customer/Routes/CustomerDetailForm/formValue.model';

const initialValue: FormValue = {
  isEdit: false,
  isReadOnly: false,
  customerId: '',
  arCode: '',
  arName: '',
  lat: '',
  lng: '',
  transportAddress: '',
  pinMap: '',
  shiptoTel: '',
  subDistrict: '',
  district: '',
  province: '',
  postcode: '',
  provinceCode: '',
  districtCode: '',
  remark: undefined,
};

export default initialValue;
