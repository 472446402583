import gql from 'graphql-tag';

export interface NumberOfTransportData {
  numberOfTransport: {
    all: number;
    wait: number;
    pause: number;
    working: number;
    offline: number;
  };
}

export default gql`
  query NumberOfTransport {
    numberOfTransport {
      all
      wait
      pause
      working
      offline
    }
  }
`;
