import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Layout as LayoutAntd, Row, Col, message, Input, Button, Typography } from 'antd';
import TRACKING_TRANSPORT_QUERY, {
  TrackingTransportData,
  TrackingTransportVars,
} from 'shared/graphql/query/trackingTransport';
import Icon from '@ant-design/icons';
import NUMBER_OF_TRANSPORT_QUERY, {
  NumberOfTransportData,
} from 'shared/graphql/query/numberOfTransport';
import Layout from 'shared/container/Layout';
import { PageLoader } from 'shared/components';
import { color } from 'shared/utils/styles';
import TrackingTransporterProvider, {
  TrackingTransporterContext,
} from './common/context/TrackingTransporterContext';
import CardTruck from './common/components/CardTruck';
import ModalJobDetail from './ModalJobDetail';
import SimpleBar from 'simplebar-react';
import { convertDataToTrackingList } from './common/functions/convertDataToTrackingList';
import LoadableComponent from 'shared/components/LoadableComponent';
import useMessageError from 'shared/hooks/useMessageError';

const TruckList = LoadableComponent(() => import('./TruckList'));
const MapTracking = LoadableComponent(() => import('./MapTracking'));

const { Content, Sider } = LayoutAntd;
const { Text } = Typography;

const TrackingTransporterWrapper = () => {
  return (
    <TrackingTransporterProvider>
      <TrackingTransporter />
    </TrackingTransporterProvider>
  );
};

const TrackingTransporter: React.FC = () => {
  const { state, dispatch } = useContext(TrackingTransporterContext);
  const { getMessageError } = useMessageError();
  const [licensePlate, setLicensePlate] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<{
    truckType: string[];
    truckStatus: string[];
  }>({
    truckType: [],
    truckStatus: [],
  });

  const { data, refetch, loading, error } = useQuery<TrackingTransportData, TrackingTransportVars>(
    TRACKING_TRANSPORT_QUERY,
    {
      variables: {
        license_plate: licensePlate,
        truck_type_in: selectedFilter.truckType.length ? selectedFilter.truckType : undefined,
        job_status_in: selectedFilter.truckStatus.length ? selectedFilter.truckStatus : undefined,
      },
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 1000 * 60 * 5,
    },
  );

  useEffect(() => {
    if (data?.trackingTransport && !loading && !error && state.isOpenModalJob) {
      const trackingAll = convertDataToTrackingList(data.trackingTransport.trackingTransport);
      const findJobIdSelected = trackingAll.find((item) => item.jobId === state.truck?.jobId);
      if (findJobIdSelected?.jobId) {
        dispatch({
          type: 'SELECT_TRUCK',
          payload: {
            truck: {
              type: findJobIdSelected.type,
              jobId: findJobIdSelected.jobId,
              gps: findJobIdSelected.gps
                ? {
                    lat: findJobIdSelected.gps.latitude || '',
                    lng: findJobIdSelected.gps.longitude || '',
                  }
                : null,
            },
          },
        });
      } else {
        dispatch({
          type: 'CLOSE_MODAL',
        });
      }
    }
  }, [data?.trackingTransport, loading, error, state.isOpenModalJob]);

  const { data: numberOfTransportData } = useQuery<NumberOfTransportData>(
    NUMBER_OF_TRANSPORT_QUERY,
    {
      onError: (err) => {
        message.error(getMessageError(err));
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let content = <PageLoader />;

  if (data?.trackingTransport && numberOfTransportData?.numberOfTransport) {
    const { all, wait, working, pause, offline } = numberOfTransportData.numberOfTransport;
    const { trackingTransport } = data.trackingTransport;

    content = (
      <>
        <Row align="middle" gutter={[12, 12]} justify="start">
          <Col>
            <CardTruck
              color="black"
              content={`${all} คัน`}
              title="รถทั้งหมด"
              textPopOver="แสดงจำนวนรถทั้งหมดที่มีในระบบ"
            />
          </Col>
          <Col>
            <CardTruck
              color="green"
              content={`${working} คัน`}
              title="รถที่กำลังดำเนินงาน"
              textPopOver="แสดงจำนวนรถที่กำลังทำงาน จากตำแหน่งการทำงานปัจจุบันผ่าน GPS โทรศัพท์"
            />
          </Col>
          <Col>
            <CardTruck
              color="orange"
              content={`${pause} คัน`}
              title="รถที่พักงาน"
              textPopOver="แสดงจำนวนรถที่พักงาน จากตำแหน่งการทำงานล่าสุดผ่าน GPS โทรศัพท์"
            />
          </Col>
          <Col>
            <CardTruck
              color="blue"
              content={`${wait} คัน`}
              title="รถที่รอเริ่มงาน"
              textPopOver="แสดงจำนวนรถที่รอเริ่มงาน จากตำแหน่งการกดรับงานผ่าน GPS โทรศัพท์"
            />
          </Col>
          <Col>
            <CardTruck
              color="gray"
              content={`${offline} คัน`}
              title="รถที่ออฟไลน์"
              textPopOver="แสดงจำนวนรถที่ออฟไลน์ รถที่ไม่มีงาน/รถที่ยังไม่รับงาน/รถที่ไม่เปิดการเข้าถึงตำแหน่งผ่าน GPS โทรศัพท์"
            />
          </Col>
        </Row>

        <LayoutAntd
          style={{ height: 'calc(100vh - 60px - 120px)', backgroundColor: 'transparent' }}
        >
          <Sider
            width="250px"
            theme="light"
            style={{ marginRight: 12, border: `1px solid ${color.border}` }}
          >
            <SimpleBar style={{ maxHeight: 'calc(100vh - 60px - 120px)' }}>
              <TruckList data={trackingTransport} />
            </SimpleBar>
          </Sider>
          <Content style={{ backgroundColor: 'transparent', height: '100%' }}>
            <Row justify="space-between" gutter={[12, 12]}>
              <Col>
                <Row gutter={[12, 0]} align="middle">
                  <Col>
                    <Text>ค้นหาทะเบียนรถ</Text>
                  </Col>
                  <Col>
                    <Input
                      allowClear
                      placeholder="ค้นหาทะเบียนรถ"
                      value={licensePlate}
                      onChange={(event) => setLicensePlate(event.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button loading={loading} onClick={() => refetch()}>
                  รีเฟรชข้อมูล
                </Button>
              </Col>
            </Row>

            <div
              style={{
                height: `calc(100vh - 60px - 120px - 44px)`,
                position: 'relative',
                border: `1px solid ${color.border}`,
              }}
            >
              <ModalJobDetail
                visible={state.isOpenModalJob}
                onCancel={() =>
                  dispatch({
                    type: 'CLOSE_MODAL',
                  })
                }
              />
              <MapTracking trucks={trackingTransport} />
            </div>
          </Content>
        </LayoutAntd>
      </>
    );
  }

  return (
    <Layout textHeader="ติดตามรถขนส่ง">
      <Content
        style={{
          backgroundColor: 'white',
          margin: -10,
          padding: 16,
          height: 'calc(100vh - 60px)',
        }}
      >
        {content}
      </Content>
    </Layout>
  );
};

export default TrackingTransporterWrapper;
