import React, { useState, ReactNode, useEffect } from 'react';
import { Empty } from 'antd';
import { StyledHightTable, StyledTable } from './Styles';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type Column = {
  title: string;
  dataIndex?: string;
  sorter?: boolean;
  width?: number;
  search?: ReactNode;
  fixed?: 'left' | 'right';
};

type Pagination = {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger?: boolean;
  onChange: (value: number) => void;
  onShowSizeChange?: (current: number, pageSize: number) => void;
};

type OrderType = '1' | '-1' | undefined;

type TableSearchProps = {
  columns: Column[];
  onSort?: (value: { orderBy: string; orderType: OrderType }) => void;
  dataSource: any[];
  loading?: boolean;
  pagination: Pagination | false;
  size?: SizeType;
  height?: number;
};

const defaultProps = {
  loading: false,
};

const TableSearch: React.FC<TableSearchProps> = (props) => {
  const { columns, onSort, size, pagination, height, ...otherProps } = props;
  const [orderBy, setOrderBy] = useState<string>('');
  const [orderType, setOrderType] = useState<OrderType>();

  useEffect(() => {
    if (onSort) {
      onSort({
        orderBy,
        orderType,
      });
    }
  }, [onSort, orderBy, orderType]);

  const checkSort = (sortName: string): 'ascend' | 'descend' | undefined | null => {
    if (sortName === orderBy && orderType === '1') {
      return 'ascend';
    }

    if (sortName === orderBy && orderType === '-1') {
      return 'descend';
    }

    return undefined;
  };

  const renderColumns = columns.map((column) => ({
    ...column,
    title: (
      <div className="table-header">
        <div className="table-header__text">{column.title}</div>
        {column.search && (
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {column.search}
          </div>
        )}
      </div>
    ),
    dataIndex: column.dataIndex,
    sorter: column.sorter,
    sortOrder: column.dataIndex ? checkSort(column.dataIndex) : undefined,
  }));

  return (
    <StyledHightTable tableHeight={height}>
      <StyledTable
        size={size}
        columns={renderColumns}
        scroll={{ x: 'max-content' }}
        locale={{
          emptyText: <Empty description="ไม่มีข้อมูล" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          cancelSort: 'รีเซ็ต',
          triggerAsc: 'เรียงลำดับจากน้อยไปมาก',
          triggerDesc: 'เรียงลำดับจากมากไปมาก',
        }}
        onChange={(_pagination, _filters, sorter, _extra) => {
          const { order, field } = sorter as {
            order: 'ascend' | 'descend' | undefined;
            field: string;
          };

          const orderTypeValue = order === 'descend' ? '-1' : order === 'ascend' ? '1' : undefined;
          setOrderType(orderTypeValue);
          setOrderBy(orderTypeValue ? field : '');
        }}
        pagination={{
          showSizeChanger: false,
          pageSizeOptions: ['30', '50', '100'],
          ...pagination,
        }}
        {...otherProps}
      />
    </StyledHightTable>
  );
};

TableSearch.defaultProps = defaultProps;

export default TableSearch;
