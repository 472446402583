import styled from 'styled-components';
import { Marker, Popup } from 'react-leaflet';

export const StyledMarker = styled(Marker)`
  font-weight: bold;
`;

export const Pin = styled.div`
  position: relative;

  & span {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 21px;
    text-align: center;
  }

  & img {
    width: 100%;
  }
`;

export const StyledPopup = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    border-radius: 8px;
  }

  & .leaflet-popup-content {
    margin: 10px;
  }
`;

export default StyledMarker;
