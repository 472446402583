import gql from 'graphql-tag';

export interface GeneratePDFSumData {
  generatePDFSum: {
    path: string;
  };
}

export interface GeneratePDFSumVars {
  soNo: string;
  date: string;
}

export default gql`
  mutation generatePDFSum($soNo: String!, $date: String) {
    generatePDFSum(soNo: $soNo, date: $date) {
      path
    }
  }
`;
