import React, { ReactNode, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import COMPANIES_QUERY, {
  CompaniesData,
  CompaniesVars,
  Companies,
} from 'shared/graphql/query/companies';
import { Select, message } from 'antd';
import useMessageError from 'shared/hooks/useMessageError';

type SelectCompaniesProps = {
  value?: string;
  disabled?: boolean;
  onChange?: (values?: { companyId: string; companyName: string }) => void;
};

const { Option } = Select;

const SelectCompanies: React.FC<SelectCompaniesProps> = (props) => {
  const { value, disabled, onChange } = props;
  const [search, setSearch] = useState<string>('');
  const [companyList, setCompanyList] = useState<Companies[]>([]);
  const { getMessageError } = useMessageError();

  const { data, loading, error } = useQuery<CompaniesData, CompaniesVars>(COMPANIES_QUERY, {
    variables: {
      company_name: search,
      showData: 30,
    },
    onError: (err) => {
      message.error(getMessageError(err));
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && !error && data?.companies) {
      setCompanyList([...data.companies.companies]);
    }
  }, [data?.companies, loading, error]);

  const options: ReactNode[] = companyList.map((item) => (
    <Option key={item._id} value={item._id}>{`${item.company_name}`}</Option>
  ));

  const onSelected = (valueInput: string) => {
    if (onChange && valueInput === undefined) {
      onChange(undefined);
    }

    const findValue = companyList.find((item) => item._id === valueInput);
    if (onChange && findValue) {
      const { _id, company_name } = findValue;

      onChange({
        companyId: _id,
        companyName: company_name,
      });
    }
  };

  return (
    <>
      <Select
        allowClear
        value={value && options.length ? value : undefined}
        disabled={disabled}
        loading={loading}
        placeholder="เลือก"
        onChange={(valueChange) => {
          setSearch('');
          onSelected(valueChange);
        }}
        showSearch
        onSearch={(valueSearch) => setSearch(valueSearch)}
        optionFilterProp="children"
        filterOption={false}
      >
        {options}
      </Select>
    </>
  );
};

export default SelectCompanies;
