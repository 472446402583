import React, { ReactNode } from 'react';
import { Select } from 'antd';

type SelectMultiGroupNameProps = {
  value?: string[];
  disabled?: boolean;
  onChange?: (values: string[]) => void;
};

const { Option } = Select;

const SelectMultiGroupName: React.FC<SelectMultiGroupNameProps> = (props) => {
  const { value, disabled, onChange } = props;

  const options = [
    {
      label: 'SO (B-C)',
      value: '1',
    },
    {
      label: 'PO',
      value: '2',
    },
    {
      label: 'SO (B-B)',
      value: '3',
    },
    {
      label: 'Click and collect',
      value: '4',
    },
  ];

  const renderOptions: ReactNode[] = options.map((item) => {
    return <Option key={item.value} value={item.value}>{`${item.label}`}</Option>;
  });

  return (
    <Select
      allowClear
      mode="multiple"
      maxTagCount="responsive"
      value={value}
      disabled={disabled}
      placeholder="ค้นหา"
      onChange={(valueChange) => {
        onChange && onChange(valueChange);
      }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: '100%' }}
    >
      {renderOptions}
    </Select>
  );
};

export default SelectMultiGroupName;
