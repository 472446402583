import React, { useState, useEffect } from 'react';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import { TextButton } from './Styles';

const TextExpand = (props) => {
  const { children, more, less, lines, expand } = props;
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const handleTruncate = (valueTruncated) => {
    if (truncated !== valueTruncated) {
      setTruncated(valueTruncated);
    }
  };

  const toggleLines = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!expand) {
      setExpanded(false);
    }
  }, [expand]);

  return (
    <div>
      {expand && (
        <>
          <Truncate
            lines={!expanded && lines}
            ellipsis={
              <span>
                ...{' '}
                <TextButton type="primary" onClick={toggleLines}>
                  {more}
                </TextButton>
              </span>
            }
            onTruncate={handleTruncate}
          >
            {children}
          </Truncate>
          {!truncated && expanded && (
            <div>
              <TextButton type="primary" onClick={toggleLines}>
                {less}
              </TextButton>
            </div>
          )}
        </>
      )}

      {!expand && (
        <Truncate lines={lines} ellipsis={<span>...</span>}>
          {children}
        </Truncate>
      )}
    </div>
  );
};

TextExpand.defaultProps = {
  lines: 1,
  more: 'แสดง',
  less: 'ซ่อน',
  expand: true,
};

TextExpand.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  expand: PropTypes.bool,
};

export default TextExpand;
