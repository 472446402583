import * as yup from 'yup';

const validateSchema = yup.object().shape({
  isEdit: yup.boolean().required(),
  warehouseId: yup.string().when('isEdit', {
    is: (value) => value === true,
    then: yup.string().required(),
  }),
  stockSite: yup.string().required('กรุณากรอกข้อมูล'),
  hub: yup.string().required('กรุณากรอกข้อมูล'),
  lastMile: yup.string().required('กรุณากรอกข้อมูล'),
  pickUpPointName: yup.string().required('กรุณากรอกข้อมูล'),
  pickUpContactName: yup.string().required('กรุณากรอกข้อมูล'),
  pickUpContactTel: yup
    .string()
    .matches(/(^$|^[0]\d{8,9}$)/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  pickUpAddress: yup.string().required('กรุณากรอกข้อมูล'),
  lat: yup.string().required('กรุณากรอกข้อมูล'),
  lng: yup.string().required('กรุณากรอกข้อมูล'),
});

export default validateSchema;
