import gql from 'graphql-tag';

export interface LastMileGroupData {
  lastMileGroup: {
    _id: string;
    lastMile: string;
  }[];
}

export default gql`
  query LastMileGroup {
    lastMileGroup {
      _id
      lastMile
    }
  }
`;
