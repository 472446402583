import React from 'react';
import { Tag } from 'shared/components';
import { ContactBookingsStatus } from 'shared/model/contactBookingsStatus.model';

type TagStatusProps = {
  status: ContactBookingsStatus;
};

const getTextColorStatus = (status: ContactBookingsStatus) => {
  switch (status) {
    case 1:
      return { color: '#BFBFBF', textColor: '#212429', text: 'รอติดต่อลูกค้า', outline: false };
    case 2:
      return { color: '#068828', textColor: '#ffffff', text: 'ติดต่อลูกค้าแล้ว', outline: false };
    case 3:
      return { color: '#CF1322', textColor: '#ffffff', text: 'ถูกยกเลิก', outline: false };
    default:
      return { text: status };
  }
};

const TagStatus: React.FC<TagStatusProps> = (props) => {
  const { status } = props;
  const data = getTextColorStatus(status);

  return (
    <Tag color={data.color} outline={data.outline} textColor={data.textColor}>
      {data.text}
    </Tag>
  );
};

export default TagStatus;
